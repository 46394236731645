import React, { useState, useEffect } from 'react'
import { useSpinner } from "../../Core/Context/SpinnerContext";
import { useLocation } from "react-router-dom";
import * as BoardApi from '../../Apis/BoardApi';
import BoardNoticeListItem from './BaordNoticeListItem/BoardNoticeListItem';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BoardNoticeList = props => {

  const queryParams = useQuery();
  const { setSpinner } = useSpinner();
  const [pageNo, setpageNo] = useState(queryParams.get("pageNo") ?? "1");
  const [boardData, setBoardData] = useState();
  const pageSize = 3;

  const getList = async (_pageNo) => {
    try {
      setSpinner(true);

      const boardResponse = await BoardApi.getBoardNoticeList("", "", "", _pageNo, pageSize);
      console.log(boardResponse);
      setBoardData(boardResponse);
      props.history.push(`/notice?pageNo=${_pageNo}`);
    }
    catch (err) {
      console.log('err', err);
    }
    setSpinner(false);
  };

  useEffect(() => {
    if (pageNo) {
      getList(pageNo);

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo])

  return (
    <main id="content" role="main" className="bg-light">
      <div className="container  content-space-3 content-space-t-lg-4 content-space-b-lg-3">
        <div className="w-lg-75-disable mx-auto">
          <div className="text-center mb-5 mb-md-7">
            <h1 className="h2">공지사항</h1>
          </div>

          <div className="card card-lg">
            <div className="card-body container-min-400">
              {boardData &&
                <BoardNoticeListItem
                  items={boardData}
                  pageNoChanged={(pageNo) => setpageNo(pageNo)}
                />
              }

            </div>
          </div>
        </div>
      </div>
    </main >
  )
}



export default BoardNoticeList
