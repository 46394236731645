import React, { memo } from "react";
import LoadingOverlay from "react-loading-overlay";
import { css } from '@emotion/css'
//import backdrop from "../Backdrop/Backdrop";

const Spinner = ({ active }) => {
  // const DarkBackgroundSmall = css`
  // border-radius: 30px;
  // position: fixed; /* Stay in place */
  // z-index: 9999; /* Sit on top */
  // left:50%;
  // top: 50%;
  // margin: -70px 0 0 -70px;
  // width: 140px; /* Full width */
  // height: 140px; /* Full height */
  // overflow: auto; /* Enable scroll if needed */
  // background-color: rgb(0, 0, 0); /* Fallback color */
  // background-color: rgba(0, 0, 0, 0.01); /* Black w/ opacity */
  // display: block; /* show */  `;

  const DarkBackground = css`
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.1); /* Black w/ opacity */
  display: block; /* show */  `;


  return (active &&
    <LoadingOverlay
      className={DarkBackground}
      active={true}
      spinner={true}
      text="Loading..."
    />
  );
};

//export default Spinner;
export default memo(Spinner);