import React, { useState, useEffect } from 'react'
import { useSpinner } from "../../Core/Context/SpinnerContext";
import { useNotification } from "../../Core/Context/NotificationContext";
import * as BoardApi from '../../Apis/BoardApi';
import { useHistory } from "react-router-dom";
const BoardNotice = props => {

  const boardId = props.match.params.id;
  const { setSpinner } = useSpinner();
  const { setNotifiications } = useNotification();
  const history = useHistory();

  const [boardItem, setBoardItem] = useState();

  useEffect(() => {

    const getBoardInfo = async () => {
      try {
        setSpinner(true);

        const orderResponse = await BoardApi.getBoardNoticeArticle(boardId);
        if (orderResponse) {
          setBoardItem(orderResponse);
          console.log('getBoardInfo.orderResponse', orderResponse);
        }
        else {
          setNotifiications("warning", "게시물 정보를 찾을수 없습니다.", "", 3000, null);
          history.push('/notice');
        }


      }
      catch (err) {
        console.log('Error:', err);
        if (err.status === 404) {
          setNotifiications("warning", "게시물 정보를 찾을수 없습니다.", "", 3000, null);
          history.push('/notice');
        }
      }

      setSpinner(false);
    }

    getBoardInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <main id="content" role="main" className="bg-light">
      <div className="container  content-space-3 content-space-t-lg-4 content-space-b-lg-3">
        <div className="w-lg-75-disable mx-auto">
          <div className="text-center mb-5 mb-md-7">
            <h1 className="h2">공지사항</h1>
          </div>
          <div className="card card-lg">
            <div className="card-body">
              <h3 className="card-title h3">{boardItem?.Title}</h3>
              <div className="row align-items-sm-center mb-5">
                <div className="col-sm-7 mb-4 mb-sm-0">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 mt-2">
                      <h5 className="mb-0">
                        <div className="text-dark" >{boardItem?.WriterName}</div>
                      </h5>
                      <span className="d-block small mt-1">{boardItem?.CreateDate}</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="my-3" />
              <div className="text-dark container-min-200">
                <div className="board-contents ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: boardItem?.Contents
                  }}>

                </div>
              </div>
            </div>
          </div>
          <div className="col-sm  text-sm-start mt-2">
            <button type="button" className="btn btn-ghost-secondary" onClick={e => history.goBack()}>
              <i className="bi-chevron-left small ms-1"></i> 이전으로
            </button>
          </div>
        </div>
      </div>
    </main >
  )
}



export default BoardNotice
