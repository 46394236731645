import axiosBase from "./Axios/AxiosCommon";


export const getBoardNoticeList = (
  startDate,
  endDate,
  searchStr,
  pageNo = "",
  pageSize = "") => {


  let params = {};

  if (startDate !== "") {
    params = { ...params, startDate: startDate };
  }
  if (endDate !== "") {
    params = { ...params, endDate: endDate };
  }
  if (searchStr !== "") {
    params = { ...params, searchStr: searchStr };
  }

  if (pageNo !== "") {
    params = { ...params, pageNo: pageNo };
  }

  if (pageSize !== "") {
    params = { ...params, pageSize: pageSize };
  }

  return axiosBase
    .get("/customer/board/notice", { params: params, })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const getBoardNoticeArticle = (boardId) => {

  return axiosBase
    .get("/customer/board/notice/" + boardId)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}



export const getBoardQnaList = (
  startDate,
  endDate,
  searchStr,
  pageNo = "",
  pageSize = "") => {


  let params = {};

  if (startDate !== "") {
    params = { ...params, startDate: startDate };
  }
  if (endDate !== "") {
    params = { ...params, endDate: endDate };
  }
  if (searchStr !== "") {
    params = { ...params, searchStr: searchStr };
  }

  if (pageNo !== "") {
    params = { ...params, pageNo: pageNo };
  }

  if (pageSize !== "") {
    params = { ...params, pageSize: pageSize };
  }

  return axiosBase
    .get("/customer/board/qna", { params: params, })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}



export const getBoardQnaArticle = (boardId) => {

  return axiosBase
    .get("/customer/board/qna/" + boardId)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}



export const createQnaArticle = (article) => {

  return axiosBase
    .post("/customer/board/qna", article)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}



export const updateBoardQnaArticle = (boardId, article) => {

  return axiosBase
    .put("/customer/board/qna/" + boardId, article)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}


export const deleteBoardQnaArticle = (boardId) => {

  return axiosBase
    .delete("/customer/board/qna/" + boardId)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}



export const getPopupArticle = () => {

  return axiosBase
    .get("/customer/board/popup")
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}



export const createCustomerEnquiry = (enquiry) => {

  return axiosBase
    .post("/customer/board/enquiry", enquiry)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}
