import React, { useState, useEffect } from 'react'
import { NavLink } from "react-router-dom";
import * as OrderApi from '../../../Apis/OrderApi';
import { useSpinner } from "../../../Core/Context/SpinnerContext";

const Tracking = props => {

  const orderNumber = props.match.params.id;
  const { setSpinner } = useSpinner();

  const [orderDetail, setOrderDetail] = useState();

  useEffect(() => {
    const getOrder = async () => {
      try {
        setSpinner(true);
        const orderResponse = await OrderApi.searchOrder(orderNumber);

        setOrderDetail(orderResponse);
        setSpinner(false);
      }
      catch (err) {
        setSpinner(false);
        setOrderDetail({ OrderId: "0" });
        console.log('err:', err);
      }
    }

    getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDeliveryStatusImage = (item) => {
    console.log('getDeliveryStatusImage.item', item)
    if (!item || item.OrderId === "0") {
      return <img className="img-fluid" src="/assets/img/illustrations/sorry.png" alt="sorry" style={{ width: "10rem" }} />;
    }
    else {
      if (item.DeliveryStatusCode === "10") {
        return < img style={{ width: "100px" }} src={`/assets/img/delivery/delivery-10.png`} alt="delivery" />
      }
    }

    return < img style={{ width: "650px" }} src={`/assets/img/delivery/deliverycircle-${item?.DeliveryStatusCode}.jpg`} alt="delivery" />;
  }

  return (
    <main id="content" role="main">
      {orderDetail &&
        <div className="container content-space-2 content-space-lg-3">
          <div className="w-md-75 w-lg-70 text-center mx-md-auto">
            <div className="mb-5">
              {orderDetail && getDeliveryStatusImage(orderDetail)}
            </div>
            <div className="mb-5 text-center">
              {orderDetail?.OrderId !== "0" ?
                <h1 className="h2">주문번호 <span className="text-primary text-highlight-warning">{orderNumber}</span>는 현재 {orderDetail?.DeliveryStatusName} 입니다</h1>
                : <h1 className="h2">주문번호 <span className="text-primary text-highlight-warning">{orderNumber}</span>와 일치하는 주문정보를 찾을수 없습니다</h1>
              }
            </div>
          </div>
        </div>
      }

      <div className="container content-space-b-lg-2">
        <div className="w-lg-75 mx-lg-auto">
          <div className="card card-sm overflow-hidden">
            <div className="card-body">
              <div className="row justify-content-md-start align-items-md-center text-center text-md-start">
                <div className="col-md offset-md-3 mb-3 mb-md-0">
                  <h4 className="card-title">마이페이지에서 상세한 주문내용을 확인해 보세요!</h4>
                </div>
                <div className="col-md-auto">
                  <NavLink exact to="/orderlist" className="btn btn-primary btn-transition">
                    마이페이지
                  </NavLink>
                </div>
              </div>

              <figure className="w-25 d-none d-md-block position-absolute top-0 start-0 mt-n2">
                <svg viewBox="0 0 451 902" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.125" d="M0 82C203.8 82 369 247.2 369 451C369 654.8 203.8 820 0 820" stroke="url(#paint2_linear)" strokeWidth="164" strokeMiterlimit="10" />
                  <defs>
                    <linearGradient id="paint2_linear" x1="323.205" y1="785.242" x2="-97.6164" y2="56.3589" gradientUnits="userSpaceOnUse">
                      <stop offset="0" stopColor="white" stopOpacity="0" />
                      <stop offset="1" stopColor="#377dff" />
                    </linearGradient>
                  </defs>
                </svg>
              </figure>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container content-space-b-2">
        <div className="text-center py-6 bubble-background">
          <div className="mb-5">
            <h2>Find the right learning path for you</h2>
            <p>Answer a few questions and match your goals to our programs.</p>
          </div>

          <a className="btn btn-primary btn-transition" href="#">Explore by category</a>
        </div>
      </div> */}
    </main>
  )
}


export default Tracking
