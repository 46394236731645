import React, { useState, useEffect } from 'react'
import { Editor } from 'primereact/editor';
import { useForm } from "react-hook-form";
import AwaitButton from '../../Core/UI/AwaitButton/AwaitButton';
import * as BoardApi from '../../Apis/BoardApi';
import { useAuth } from "../../Core/Context/AuthContext";
import { useNotification } from "../../Core/Context/NotificationContext";
import { useSpinner } from "../../Core/Context/SpinnerContext";
import { useHistory } from "react-router-dom";

const BoardQnaEdit = props => {

  const boardId = props.match.params.id;

  const { setSpinner } = useSpinner();
  const { setNotifiications } = useNotification();
  const { AuthToken } = useAuth();
  const history = useHistory();

  const [contents, setContents] = useState("");
  const [isSecret, setIsSecret] = useState(true);
  const [isLoading, setIsLoading] = useState(false);


  const { register, handleSubmit, setValue, formState: { errors, isSubmitted } } = useForm();

  const onSubmit = async (data) => {
    console.log('onSubmit.data', data);

    try {
      setIsLoading(true);

      const article = {
        "WriterName": AuthToken.name,
        "Title": data.txtTitle,
        "Contents": contents,
        "IsActive": true,
        "IsSecret": isSecret
      }

      if (contents.length > 4194304) {
        setNotifiications("warning", "4mb 까지 저장할수 있습니다", "컨텐츠 크기를 확인해 주세요", 3000, null);
        return;
      }

      if (boardId) {
        await BoardApi.updateBoardQnaArticle(boardId, article);
      }
      else {
        await BoardApi.createQnaArticle(article);
      }

      setIsLoading(false);
      props.history.push("/qna");
    }
    catch (err) {
      console.log('err', err);
    }
    setIsLoading(false);

  }

  useEffect(() => {

    const getBoardInfo = async () => {
      try {
        setSpinner(true);

        const orderResponse = await BoardApi.getBoardQnaArticle(boardId);
        if (orderResponse) {
          console.log('orderResponse', orderResponse);
          if (orderResponse.IsSecret === "True" && AuthToken?.user_id !== orderResponse.UserId) {
            setNotifiications("warning", "비밀글은 작성자만 열람할수 있습니다.", "", 3000, null);
            history.goBack();
          }

          setValue('txtTitle', orderResponse.Title);
          setContents(orderResponse.Contents);
          setIsSecret(orderResponse.IsSecret === "True");
        }
        else {
          setNotifiications("warning", "게시물 정보를 찾을수 없습니다.", "", 3000, null);
          history.push('/qna');
        }

      }
      catch (err) {
        console.log('Error:', err);
        if (err.status === 404) {
          setNotifiications("warning", "게시물 정보를 찾을수 없습니다.", "", 3000, null);
          history.push('/qna');
        }
      }

      setSpinner(false);
    }

    const init = setTimeout(() => {
      if (boardId) {
        getBoardInfo();
      }

    }, 500);

    return () => {
      if (init) {
        clearTimeout(init);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardId]);


  return (
    <main id="content" role="main" className="bg-light">
      <div className="container  content-space-3 content-space-t-lg-4 content-space-b-lg-3">
        <div className="w-lg-75-disable mx-auto">
          <div className="text-center mb-5 mb-md-7">
            <h1 className="h2">Q&amp;A</h1>
          </div>

          <div className="card card-lg">

            <div className="card-body">

              <form
                className={` ${isSubmitted ? "was-validated" : ""} `}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="mb-4">
                  <label className="form-label" htmlFor="signupSimpleLoginEmail">제목
                    <span className="text-danger ms-1">*</span>

                  </label>
                  <div className={`input-group input-group-merge ${errors.txtTitle ? "is-invalid" : "is-valid"} `} >
                    <input
                      type="text"
                      className="form-control form-control-lg "
                      placeholder=""
                      {...register("txtTitle", { required: true })}
                    />
                  </div>
                </div>

                <Editor style={{ height: '320px' }} value={contents} onTextChange={(e) => setContents(e.htmlValue)} />

                <div className="row mt-2">
                  <div className="col mb-3 mb-md-0">
                    <div className="form-check mb-3">
                      <input type="checkbox" id="formCheck12" className="form-check-input" checked={isSecret} onChange={(e) => setIsSecret(e.target.checked)} />
                      <label className="form-check-label" htmlFor="formCheck12">비밀글</label>
                    </div>
                  </div>

                  <div className="col-auto align-self-md-end mb-2">
                    <div className="d-grid gap-2">
                      <AwaitButton type="submit" className="btn btn-dark btn-sm" loading={isLoading}>
                        <i className="bi bi-check2 me-1" ></i>저장
                      </AwaitButton>



                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>
          <div className="col-sm  text-sm-start mt-2">
            <button type="button" className="btn btn-ghost-secondary" onClick={e => history.goBack()}>
              <i className="bi-chevron-left small ms-1"></i> 이전으로
            </button>
          </div>
        </div>
      </div>
    </main >
  )
}


export default BoardQnaEdit
