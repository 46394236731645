import React, { memo } from 'react'
import { useFormContext, Controller } from "react-hook-form";


const ControlledInput = ({
  name,
  defaultValue = "",
  errorMessage = "",
  type = 'text',
  rules = { required: true },
  handleChange,
  ...props }) => {

  const { control, formState: { errors } } = useFormContext();

  const errorText = errors[name]?.type;
  const isError = errors[name] ? true : false;

  return (
    <>
      <div className={`input-group input-group-merge is-${isError ? "in" : ""}valid `}>
        <Controller
          defaultValue={defaultValue || ''}
          name={name}
          control={control}
          rules={rules}
          render={({ field: { onChange, value } }) => (
            <input type={type} {...props}
              className="form-control form-control-lg"
              value={value}
              onChange={e => { handleChange(e.target.value); onChange(e); }}
            />
          )}
        />
      </div>
      {errorText && errorMessage !== "" && <span className="invalid-feedback">{errorMessage}</span>}
    </>
  )
}

export default memo(ControlledInput);
