import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { useNotification } from "../Context/NotificationContext";

function SecureRoute({ component: Component, ...rest }) {

  // const { setNotifiications } = useNotification();

  const existingTokens = JSON.parse(localStorage.getItem("kauth"));

  const chkAuthToken = () => {
    if (existingTokens?.expires_in > Date.now() / 1000) {
      return true;
    }
    //setNotifiications("warning", "먼저 로그인해 주시기 바랍니다.", "", 3000, null);

    return false;
  };


  return (
    <Route
      {...rest}
      render={props =>
        chkAuthToken() ? <Component {...props} /> : <Redirect to={{ pathname: "/login", state: { referer: window.location.pathname } }} />
      }
    />
  );
}

export default SecureRoute;