import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';


const UserGuide = props => {



  return (
    <main id="content" role="main" >
      <div className="bg-img-start" style={{ backgroundImage: "url(/assets/svg/components/card-11.svg)" }}>
        <div className="container content-space-t-2 content-space-b-1">
          <div className="text-center mx-md-auto " >
            <h2 className="mt-10">이용안내</h2>

          </div>
        </div>
      </div>

      <div className="container content-space-t-1 content-space-b-1">

        <div className="text-center">
          <div className="js-nav-scroller  hs-nav-scroller-horizontal">
            <ul className="nav nav-segment nav-fill mb-7" id="propertyOverviewNavTab" role="tablist">
              <li className="nav-item" role="presentation">
                <a className="nav-link active" href="#propertyOverviewNavOne" id="propertyOverviewNavOne-tab"
                  data-bs-toggle="tab" data-bs-target="#propertyOverviewNavOne" role="tab" aria-controls="propertyOverviewNavOne"
                  aria-selected="true" style={{ minWidth: "7rem" }}>배송대행</a>
              </li>

              <li className="nav-item" role="presentation">
                <a className="nav-link" href="#propertyOverviewNavTwo" id="propertyOverviewNavTwo-tab"
                  data-bs-toggle="tab" data-bs-target="#propertyOverviewNavTwo" role="tab" aria-controls="propertyOverviewNavTwo"
                  aria-selected="false" style={{ minWidth: "7rem" }}>구매대행</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade show active" id="propertyOverviewNavOne" role="tabpanel" aria-labelledby="propertyOverviewNavOne-tab">
            <div className="container content-space-t-1 content-space-t-lg-2">
              <div className="row justify-content-lg-between align-items-lg-center">
                <div className="col-12">
                  <div className="mb-5">
                    <h2>한국 <i className="bi bi-arrow-right-short"></i> 뉴질랜드로 배송대행</h2>
                  </div>

                  <ul className="step step-dashed mb-7">
                    <li className="step-item">
                      <div className="step-content-wrapper">
                        <span className="step-icon step-icon-xs step-icon-soft-primary">1</span>
                        <div className="step-content">
                          <h4 className="step-title">상품을 K택배 한국 배송대행지로 발송해 주세요.</h4>
                          <ul className="list-checked list-checked-primary mb-5">
                            <li className="list-checked-item">방문접수도 가능합니다.</li>
                            <li className="list-checked-item">주소 : <span className="text-highlight-warning">서울시 강서구 양천로 400-12 더리브골드타워 1002 수취인: 고객님성함(K택배)</span></li>
                            <li className="list-checked-item">수취인 : <span className="text-highlight-warning">고객님성함(K택배)</span></li>
                            <li className="list-checked-item">상품 발송시 <a className="link" href="/warehouse">배송대행지 안내</a> 페이지에서 고객님만의 배송대행지 주소를 확인해 주세요!</li>
                          </ul>
                        </div>
                      </div>
                    </li>

                    <li className="step-item mb-7">
                      <div className="step-content-wrapper">
                        <span className="step-icon step-icon-xs step-icon-soft-primary">2</span>
                        <div className="step-content">
                          <h4 className="step-title">배송대행 신청서를 작성해 주세요</h4>
                          {/* <p className="mb-0">We actively contribute to open-source projects—giving back to the community through development, patches, and sponsorships.</p> */}
                          <ul className="list-checked list-checked-primary mb-5">
                            <li className="list-checked-item">홈페이지 로그인을 하시고 <a className="link" href="/createorder">배송대행</a> 신청서를 작성해 주세요</li>
                            <li className="list-checked-item">상품을 받으실 뉴질랜드 주소를 영문으로 정확히 입력해 주세요</li>
                            <li className="list-checked-item">보내실 상품 정보를 정확히 입력해 주세요</li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li className="step-item mb-7">
                      <div className="step-content-wrapper">
                        <span className="step-icon step-icon-xs step-icon-soft-primary">3</span>
                        <div className="step-content">
                          <h4 className="step-title">마이페이지에서 상품의 배송상태를 확인하실수 있습니다</h4>
                          <p className="mb-0"><a className="link" href="/orderlist">주문확인</a> 페이지에서 상품의 입고 상황부터 세관 진행 상황까지 상세한 정보를 확인하실수 있습니다.</p>
                        </div>
                      </div>
                    </li>
                    <li className="step-item mb-7">
                      <div className="step-content-wrapper">
                        <span className="step-icon step-icon-xs step-icon-soft-primary">4</span>
                        <div className="step-content">
                          <h4 className="step-title">인보이스를 확인해 주세요</h4>
                          <p className="mb-0">상품이 뉴질랜드에 도착하고 통관이 완료되면 고객님의 이메일로 인보이스를 발송해 드립니다.</p>
                          <p className="mb-0">지정된 계좌로 배송비를 지불하시면 신청서에 기입하신 주소로 배송이 시작 되거나, 뉴질랜드 사무실로 픽업하실수 있습니다.</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div id='kor' className="container content-space-t-1 content-space-t-lg-2">
              <div className="row justify-content-lg-between align-items-lg-center">
                <div className="col-12">
                  <div className="mb-5">
                    <h2 >뉴질랜드 <i className="bi bi-arrow-right-short"></i> 한국으로 배송대행</h2>
                  </div>

                  <ul className="step step-dashed mb-7">
                    <li className="step-item">
                      <div className="step-content-wrapper">
                        <span className="step-icon step-icon-xs step-icon-soft-primary">1</span>
                        <div className="step-content">
                          <h4 className="step-title">상품을 K택배 오클랜드 배송대행지로 발송해 주세요.</h4>
                          <ul className="list-checked list-checked-primary mb-5">
                            <li className="list-checked-item">픽업을 원하시면 고객센터로 문의해 주시기 바랍니다(추가비용 발생)</li>
                            <li className="list-checked-item">주소 : <span className="text-highlight-warning">99 Killybegs Drive, Pinehill, Auckland, New Zealand</span></li>
                            <li className="list-checked-item">수취인 : <span className="text-highlight-warning">고객님영문성함(K-Aircargo)</span></li>
                            <li className="list-checked-item">상품 발송시 <a className="link" href="/warehouse">배송대행지 안내</a> 페이지에서 고객님만의 배송대행지 주소를 확인해 주세요!</li>
                          </ul>
                        </div>
                      </div>
                    </li>

                    <li className="step-item mb-7">
                      <div className="step-content-wrapper">
                        <span className="step-icon step-icon-xs step-icon-soft-primary">2</span>
                        <div className="step-content">
                          <h4 className="step-title">배송대행 신청서를 작성해 주세요</h4>
                          <ul className="list-checked list-checked-primary mb-5">
                            <li className="list-checked-item">홈페이지 로그인을 하시고 <a className="link" href="/createorder">배송대행</a> 신청서를 작성해 주세요</li>
                            <li className="list-checked-item">상품을 받으실 한국 주소를 정확히 입력해 주세요</li>
                            <li className="list-checked-item">
                              <div>한국 세관에서는 통관을 위해 한국에서 받는분 주민번호또는 개인통관부호가 필요합니다.<br />
                                식품이나 건강식품만 보내실 경우에는 주민번호만으로 통관이 가능하지만, 식품이 아닌 다른 물품을 같이 보내면 반드시 개인통관부호가 있어야 합니다.<br />
                                개인통관부호를 발급 받으신 후 택배 신청하실때 입력해 주세요
                              </div>
                              <div className='mt-1'>
                                <a href="https://www.customs.go.kr/download/_down/PCC_FAQ.pdf" target="_blank" rel="noreferrer">
                                  <i className="bi bi-exclamation-circle me-1 text-danger"></i>개인통관부호를 발급안내
                                </a>
                              </div>
                            </li>
                            <li className="list-checked-item">보내실 상품 정보를 정확히 입력해 주세요</li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li className="step-item mb-7">
                      <div className="step-content-wrapper">
                        <span className="step-icon step-icon-xs step-icon-soft-primary">3</span>
                        <div className="step-content">
                          <h4 className="step-title">마이페이지에서 상품의 배송상태를 확인하실수 있습니다</h4>
                          <p className="mb-0"><a className="link" href="/orderlist">주문확인</a> 페이지에서 상품의 입고 상황부터 세관 진행 상황까지 상세한 정보를 확인하실수 있습니다.</p>
                        </div>
                      </div>
                    </li>
                    <li className="step-item mb-7">
                      <div className="step-content-wrapper">
                        <span className="step-icon step-icon-xs step-icon-soft-primary">4</span>
                        <div className="step-content">
                          <h4 className="step-title">인보이스를 확인해 주세요</h4>
                          <p className="mb-0">상품이 한국에 도착하고 통관이 완료되면 고객님의 이메일로 인보이스를 발송해 드립니다.</p>
                          <p className="mb-0">지정된 계좌로 배송비를 지불하시면 신청서에 기입하신 주소로 배송이 시작 됩니다.</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="tab-pane fade" id="propertyOverviewNavTwo" role="tabpanel" aria-labelledby="propertyOverviewNavTwo-tab">
            <div className="container content-space-t-1 content-space-t-lg-2">
              <div className="row justify-content-lg-between align-items-lg-center">
                <div className="col-12">
                  <div className="mb-5">
                    <h2 >구매대행</h2>
                  </div>

                  <ul className="step step-dashed mb-7">
                    <li className="step-item">
                      <div className="step-content-wrapper">
                        <span className="step-icon step-icon-xs step-icon-soft-primary">1</span>
                        <div className="step-content">
                          <h4 className="step-title">구매대행 신청서를 작성해 주세요.</h4>
                          <ul className="list-checked list-checked-primary mb-5">
                            <li className="list-checked-item">홈페이지 로그인을 하시고 <a className="link" href="/createpurchaseorder">구매대행</a> 신청서를 작성해 주세요</li>
                            <li className="list-checked-item">배송 국가를 선택해주세요. (현재는 한국 <i className="bi bi-arrow-right-short"></i> 뉴질랜드 서비스만 제공하고 있습니다. 뉴질랜드 <i className="bi bi-arrow-right-short"></i> 한국 서비스는 추후 업데이트 예정입니다.)</li>
                            <li className="list-checked-item">한국 <i className="bi bi-arrow-right-short"></i> 뉴질랜드 배송의 경우 배송 방법을 선택해주세요. (해상, 항공)</li>
                            <li className="list-checked-item">상품을 받으실 주소를 정확히 입력해 주세요</li>
                            <li className="list-checked-item">구매대행을 원하시는 상품의 상품명, 가격, 수량, 옵션과 상품 구매가 가능한 URL을 정확히 입력해주세요.</li>
                            <li className="list-checked-item">
                              <div>뉴질랜드 <i className="bi bi-arrow-right-short"></i> 한국 배송의 경우, 한국 세관에서는 통관을 위해 한국에서 받는분 주민번호또는 개인통관부호가 필요합니다.<br />
                                식품이나 건강식품만 보내실 경우에는 주민번호만으로 통관이 가능하지만, 식품이 아닌 다른 물품을 같이 보내면 반드시 개인통관부호가 있어야 합니다.<br />
                                개인통관부호를 발급 받으신 후 택배 신청하실때 입력해 주세요
                              </div>
                              <div className='mt-1'>
                                <a href="https://www.customs.go.kr/download/_down/PCC_FAQ.pdf" target="_blank" rel="noreferrer">
                                  <i className="bi bi-exclamation-circle me-1 text-danger"></i>개인통관부호를 발급안내
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li className="step-item mb-7">
                      <div className="step-content-wrapper">
                        <span className="step-icon step-icon-xs step-icon-soft-primary">2</span>
                        <div className="step-content">
                          <h4 className="step-title">마이페이지에서 상품의 배송상태를 확인하실수 있습니다</h4>
                          <p className="mb-0"><a className="link" href="/purchaseorderlist">주문확인</a> 페이지에서 상품의 입고 상황부터 세관 진행 상황까지 상세한 정보를 확인하실수 있습니다.</p>
                        </div>
                      </div>
                    </li>
                    <li className="step-item mb-7">
                      <div className="step-content-wrapper">
                        <span className="step-icon step-icon-xs step-icon-soft-primary">3</span>
                        <div className="step-content">
                          <h4 className="step-title">인보이스를 확인해 주세요</h4>
                          <ul className="list-checked list-checked-primary mb-5">
                            <li className="list-checked-item">상품 주문 후 배대지에 입고되면 상품 비용에 대한 1차 인보이스를 발송해드립니다.</li>
                            <li className="list-checked-item">상품이 한국/뉴질랜드에 도착하고 통관이 완료되면 고객님의 이메일로 운송 비용에 대한 Final 인보이스를 발송해 드립니다.</li>
                            <li className="list-checked-item">지정된 계좌로 배송비를 지불하시면 신청서에 기입하신 주소로 배송이 시작 됩니다.</li>
                          </ul>
                         
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className="container content-space-1 content-space-lg-2">
        <div className="row justify-content-lg-between align-items-lg-center">
          <div className="col-12">
            <div className="mb-5">
              <h2>통관업무 대행</h2>
            </div>
            <ul className="step step-dashed mb-7">
              <li className="step-item">
                <div className="step-content-wrapper">
                  <span className="step-icon step-icon-xs step-icon-soft-primary"></span>
                  <div className="step-content">
                    <h4 className="step-title">각종 통관에 관련된 업무를 상담해 드립니다</h4>
                    <p className="mb-0">관세사가 직접 통관하며</p>
                    <p className="mb-0">그외의 통관업무도 상담해 드립니다</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </main>
  )
}



export default UserGuide
