import React from 'react'


const Faq = props => {


  return (
    <main id="content" role="main" >
      <div className="bg-img-start" style={{ backgroundImage: "url(/assets/svg/components/card-11.svg)" }}>
        <div className="container content-space-t-2 content-space-b-2">
          <div className="text-center mx-md-auto " >
            <h2 className="mt-10">FAQ</h2>
            <p>Search our FAQ for answers to anything you might ask.</p>
          </div>
        </div>
      </div>

      <div className="container  content-space-b-lg-3">
        <div className="w-lg-75 mx-lg-auto mt-6">
          <div className="d-grid gap-10">
            <div className="d-grid gap-3 ">
              <div className="accordion accordion-flush accordion-lg" id="accordionFAQBasics">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingBasicsOne">
                    <div className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseBasicsOne" aria-expanded="false" aria-controls="collapseBasicsOne">
                      Door to Door 인가요?
                    </div>
                  </div>
                  <div id="collapseBasicsOne" className="accordion-collapse collapse" aria-labelledby="headingBasicsOne" data-bs-parent="#accordionFAQBasics" >
                    <div className="accordion-body">
                      <p>한국에서 뉴질랜드로 보낼 때는 한국 K-택배 배대지에서 뉴질랜드 현지 K-택배 사무실 까지 입니다. 즉, 고객님께서 물건은 한국 배대지 까지 보내 주시고 뉴질랜드 도착후 통관 완료되면 고객님께 통보되고 직접 픽업하셔야 합니다.
                        <br />뉴질랜드 도착 후 수신처 까지 개별 배달을 원하시면 오클랜드 지역* 기준 배달비 $6 입니다 (10KG 이상 오클랜드 지역* 무료배송). 그외 지역은 지역과 무게에 따라 차이가 있음으로 개별 안내 해 드립니다.
                      </p>
                      <p>뉴질랜드에서 한국으로 보낼 때는 현지 K-택배 사무실에 보내 주시면 한국 최종 목적지 까지 배달해 드립니다. 만일, 오클랜드내에서* 픽업을 원하시면 10KG 이상 무료픽업, 또는 10KG 미만 $6 입니다.  </p>

                    </div>
                  </div>
                </div>



                <div className="accordion-item">
                  <div className="accordion-header" id="headingBasicsThree">
                    <div className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseBasicsThree" aria-expanded="false" aria-controls="collapseBasicsThree">
                      물건을 어디로 보내야 하나요?
                    </div>
                  </div>
                  <div id="collapseBasicsThree" className="accordion-collapse collapse" aria-labelledby="headingBasicsThree" data-bs-parent="#accordionFAQBasics">
                    <div className="accordion-body">
                      <p>한국에서 뉴질랜드로 보낼 때는 K-택배 한국 배대지로 보내면 됩니다.</p>
                      <p>*한국 배대지: 서울시 강서구 양천로 400-12 더리브골드타워 1002 / 고객님 성함(K택배) (010-9392-8097)</p>
                      <p>뉴질랜드에서 한국으로 보낼 때는 K-택배 뉴질랜드 배대지로 보내면 됩니다.</p>
                      <p>*뉴질랜드 배대지: 99 Killybegs Drive, Pinehill, Auckland, New Zealand / Jaeho Lee (021-883 671)</p>

                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-header" id="headingBasicsFour">
                    <div className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseBasicsFour" aria-expanded="false" aria-controls="collapseBasicsFour">
                      한국에서 보낸 물건의 GST는 얼마나 나오나요?
                    </div>
                  </div>
                  <div id="collapseBasicsFour" className="accordion-collapse collapse" aria-labelledby="headingBasicsFour" data-bs-parent="#accordionFAQBasics">
                    <div className="accordion-body">
                      <p>한국에서 보내 오시는 내용물 총가격이 NZD1,000 미만의 경우 GST 또는 관세는 부과되지 않습니다! </p>
                      <p>만약 NZD 1,000 이상일 경우 정식 통관을 거쳐서 관세 (관세 대상 품목인 경우), GST, Government Transaction Fee, Customs Clearance Fee가 부과 되는 것을 참고 해 주세요.</p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-header" id="headingBasicsFive">
                    <div className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseBasicsFive" aria-expanded="false" aria-controls="collapseBasicsFive">
                      배송 요일이 정해져 있나요?
                    </div>
                  </div>
                  <div id="collapseBasicsFive" className="accordion-collapse collapse" aria-labelledby="headingBasicsFive" data-bs-parent="#accordionFAQBasics">
                    <div className="accordion-body">
                      <p>현재 항공 스케줄은 한국 시간 금요일 12시 마감 하여 토요일에 출발합니다. 통관 후 화요일~수요일 부터 배송 시작 됩니다. 더 자세한 내용은 항공 스케줄 페이지에서 확인 하실 수 있습니다.
                      </p>
                      <p className="mb-0"><a className="link" href="/flight">항공 스케줄 확인하기</a> </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-header" id="headingBasicsSix">
                    <div className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#collapseBasicsSix" aria-expanded="false" aria-controls="collapseBasicsSix">
                      결제 및 물품 수령은 어떻게 해야 하는지요?
                    </div>
                  </div>
                  <div id="collapseBasicsSix" className="accordion-collapse collapse" aria-labelledby="headingBasicsSix" data-bs-parent="#accordionFAQBasics">
                    <div className="accordion-body">
                      <p>결제는 이메일로 보내 드리는 인보이스를 확인하여 배송 전 입금 하시면 완료 됩니다. 한국에서 결제를 원하시는 분들은 인보이스 발행 하는 날의 네이버 환율 매매기준율 적용 합니다. </p>
                      <p>한국 도착 물건은 최종지 까지 배송되지만, 뉴질랜드 배송 물품은 도착 후 연락드리면 직접 픽업 또는 배송 해 드립니다. 배송비 안내는 이용요금 페이지 확인 해주세요. </p>

                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingBasics7">
                    <div className="accordion-button collapsed" role="button" data-bs-toggle="collapse" data-bs-target="#headingBasics7" aria-expanded="false" aria-controls="headingBasics7">
                      김치나 꿀 보낼 수 있나요?
                    </div>
                  </div>
                  <div id="headingBasics7" className="accordion-collapse collapse" aria-labelledby="headingBasics7" data-bs-parent="#accordionFAQBasics">
                    <div className="accordion-body">
                      <p>
                        김치는 보내실때 발효되어 터질 위험이 있어서 포장을 확실하게 해야 합니다.
                        만약 운송도중 터질 경우, 주변 화물도 파손 가능성이 있으니 신경써서 포장해 주세요.
                        K-택배는 김치가 발효되거나 터지는 상황이 발생시 책임 지지 않습니다.
                      </p>
                      <p>
                        현재는 꿀을 보내 실 수 없습니다.
                        저희 K-택배는 고객님들이 꿀을 보낼 수 있도록 정부에서 요구하는 절차를 밟고 있으니 조금만 기다려 주십시오!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex pe-lg-5" data-aos="fade-up">
              <div className="flex-shrink-0">
                <span className="svg-icon text-primary">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z" fill="#035A4B"></path>
                    <path opacity="0.3" d="M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z" fill="#035A4B"></path>
                  </svg>

                </span>
              </div>
              <div className="flex-grow-1 ms-4">
                <h4>K-택배 오클랜드 지역 기준</h4>
                <p>북쪽은 알바니 까지, 남쪽은 마누카우 시티센터 까지, 서쪽은 매시 까지, 동쪽은 하윅 까지 입니다</p>
              </div>
            </div>
          </div>
        </div >
      </div >
    </main >
  )
}

export default Faq
