import React from 'react'

const OrderCompleted = props => {
  return (
    <div className="container content-space-2 content-space-lg-3">
      <div className="w-md-75 w-lg-70 text-center mx-md-auto">
        <div className="mb-5">
          <img className="img-fluid" src="/assets/img/illustrations/hi-five.png" alt="SVG" style={{ width: "15rem" }} />
        </div>

        <div className="mb-5">
          <h1 className="h2">Your order is completed!</h1>
          <p>Thank you for your order! Your order is being processed and will be completed within 3-6 hours. You will receive an email confirmation when your order is completed.</p>
        </div>

        <a className="btn btn-primary btn-transition rounded-pill px-6" href="../demo-shop/index.html">Continue shopping</a>
      </div>
    </div>
  )
}


export default OrderCompleted
