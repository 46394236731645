import React from 'react'


const Flight = props => {
  return (
    <main id="content" role="main">
      <div className="bg-img-start" style={{ backgroundImage: "url(/assets/svg/components/card-11.svg)" }}>
        <div className="container content-space-t-2 content-space-b-1">
          <div className="text-center mx-md-auto " >
            <h2 className="mt-10">항공 스케줄</h2>
            <p>K택배 항공 운항 정보를 안내해 드립니다</p>
          </div>
        </div>
      </div>

      <div className="container content-space-2 content-space-lg-3">
        <div className="row justify-content-lg-between align-items-lg-center">
          <div className="col-12">
            <div className="mb-5">
              <h2>뉴질랜드 <i className="bi bi-arrow-right-short"></i> 한국으로 배송대행</h2>
            </div>
          </div>
        </div>
        <div className="mt-2 text-danger">
          <div><i className="bi bi-exclamation-triangle me-1"></i>Covid-19로 인해 현재 항공 스케줄이 안정적이지 않고 일정이 딜레이 되고 있습니다.</div>

          <div><i className="bi bi-exclamation-triangle me-1"></i>스케줄 확인이 필요하신 경우 반드시 고객센터로 문의해 주시기 바랍니다</div>
        </div>
        <div className="card">
          <div className="card-body">
            <img className="img-fluid" src="/assets/img/flight/flight-nzkr.jpg" alt="flight-NZ" />
          </div>
        </div>
        <div className="mt-2">
          <ul className="list-checked list-checked-primary mb-5">
            <li className="list-checked-item">매주 수요일 오후1시에 상품의 입고가 마감 됩니다</li>
            <li className="list-checked-item">통관 후 화요일 부터 국내 배송 진행됩니다</li>
            <li className="list-checked-item">항공 스케줄은 기상상황, 항공사의 사정에 의해 사전 예고 없이 변경될수 있습니다</li>
          </ul>
        </div>
      </div>
      <div className="container content-space-2 content-space-lg-3">
        <div className="row justify-content-lg-between align-items-lg-center">
          <div className="col-12">
            <div className="mb-5">
              <h2>한국 <i className="bi bi-arrow-right-short"></i> 뉴질랜드로 배송대행</h2>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <img className="img-fluid" src="/assets/img/flight/flight-krnz.jpg" alt="flight-NZ" />
          </div>
        </div>
        <div className="mt-2">
          <ul className="list-checked list-checked-primary mb-5">
            <li className="list-checked-item">매주 금요일 12시에 상품의 입고가 마감 됩니다</li>
            <li className="list-checked-item">매주 화요일 통관 완료된 상품의 뉴질랜드내 배송이 시작 됩니다</li>
            <li className="list-checked-item">항공 스케줄은 기상상황, 항공사의 사정에 의해 사전 예고 없이 변경될수 있습니다</li>
          </ul>
        </div>
      </div>

    </main>

  )
}


export default Flight
