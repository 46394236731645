import React from 'react'


const CustomDuty = props => {
  return (
    <div>
      통관안내
    </div>
  )
}



export default CustomDuty
