import React, { useState } from 'react'
import AwaitButton from '../../../Core/UI/AwaitButton/AwaitButton';
import * as OrderApi from '../../../Apis/OrderApi';
import { useForm } from "react-hook-form";
import { useNotification } from "../../../Core/Context/NotificationContext";

const DeliveryFee = props => {

  const { register, handleSubmit, formState: { errors, isSubmitted } } = useForm();
  const { setNotifiications } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [chargeWeight, setChargeWeight] = useState("");
  const [chargeAmount, setChargeAmount] = useState("");

  const getQuote = async (data) => {

    console.log('data', data);
    try {
      setIsLoading(true);
      setChargeWeight("");
      setChargeAmount("");

      const weightInfo = {
        "DeliveryLocationCode": data.ddlLocation,
        "packageWeight": data.txtWeight,
        "packageWidth": data.txtWidth,
        "packageHeight": data.txtHeight,
        "packageDepth": data.txtDepth
      };

      const quoteResponse = await OrderApi.getQuote(weightInfo);

      console.log('quoteResponse', quoteResponse);

      if (Number(quoteResponse.VolumeQuoteAmount) > Number(quoteResponse.PackageQuoteAmount)) {
        setChargeWeight(`${Number(quoteResponse.volumeWeight).toFixed(1)}kg(부피무게)`);
        setChargeAmount(`$${Number(quoteResponse.VolumeQuoteAmount).toFixed(2)}`);

      }
      else {
        setChargeWeight(`${Number(quoteResponse.packageWeight).toFixed(1)}kg`);
        setChargeAmount(`$${Number(quoteResponse.PackageQuoteAmount).toFixed(2)}`);
      }


      setIsLoading(false);
    }
    catch (err) {
      setIsLoading(false);
      console.log(err);
      if (err.data?.Contents) {
        const errMessage = err.data?.Contents.map((item) => item.Message);
        setNotifiications("warning", errMessage, "입력한 정보를 확인해 주세요", 3000, null);
      }
    }

  }


  return (
    <main id="content" role="main">
      <div className="bg-img-start" style={{ backgroundImage: "url(/assets/svg/components/card-11.svg)" }}>
        <div className="container content-space-t-2 content-space-b-1">
          <div className="text-center mx-md-auto " >
            <h2 className="mt-10">K택배 이용요금</h2>
          </div>
        </div>
      </div>

      <div className="container content-space-t-1 content-space-b-1">

        <div className="text-center">
          <div className="js-nav-scroller  hs-nav-scroller-horizontal">
            <ul className="nav nav-segment nav-fill mb-7" id="propertyOverviewNavTab" role="tablist">
              <li className="nav-item" role="presentation">
                <a className="nav-link active" href="#propertyOverviewNavOne" id="propertyOverviewNavOne-tab"
                  data-bs-toggle="tab" data-bs-target="#propertyOverviewNavOne" role="tab" aria-controls="propertyOverviewNavOne"
                  aria-selected="true" style={{ minWidth: "7rem" }}>한국<i className="bi bi-arrow-right-short"></i> 뉴질랜드</a>
              </li>

              <li className="nav-item" role="presentation">
                <a className="nav-link" href="#propertyOverviewNavTwo" id="propertyOverviewNavTwo-tab"
                  data-bs-toggle="tab" data-bs-target="#propertyOverviewNavTwo" role="tab" aria-controls="propertyOverviewNavTwo"
                  aria-selected="false" style={{ minWidth: "7rem" }}> 뉴질랜드 <i className="bi bi-arrow-right-short"></i> 한국</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade show active" id="propertyOverviewNavOne" role="tabpanel" aria-labelledby="propertyOverviewNavOne-tab">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td className="table-light td-width-200">운송요금</td>
                  <td className="table-light ">
                    KG 당 $16 (1KG 미만 기본 요금 $16)
                  </td>
                </tr>
                <tr>
                  <td className="table-light">픽업</td>
                  <td className="table-light">픽업서비스는 제공하지 않습니다.</td>
                </tr>
                <tr>
                  <td className="table-light">GST</td>
                  <td className="table-light">
                    <ul className="list-checked list-checked-bg-success list-checked-sm mb-0">
                      <li className="list-checked-item text-dark fw-semi-bold ">
                        <span className='text-highlight-warning'>총 상품 금액 NZD1,000 미만인 경우 GST 또는 관세는 부과되지 않습니다</span></li>
                      <li className="list-checked-item text-dark">총 상품금액 NZD1,000 이상인 경우 정식 통관을 진행 해야 하며 관세 (관세 대상 품목인 경우), GST, Government Transaction Fee, Customs Clearance Fee가 부과 됩니다. </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="table-light">MPI 비용</td>
                  <td className="table-light">
                    <div>기본 $30 (GST 포함) 부터, 세관 처리 상황에 따라 추가 비용 발생 할 수 있습니다.</div>
                    <div className="small mt-1"><a href="/packageinfo" target="_blank" rel="noreferrer">
                      <i className="bi bi-exclamation-circle me-1 text-danger"></i>MPI 비용에 대해 더 자세히 알아 보세요</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="table-light">국내배송</td>
                  <td className="table-light">
                    <ul className="list-checked list-checked-bg-success list-checked-sm mb-0">
                      <li className="list-checked-item text-dark  ">
                        오클랜드 지역<sup className='fw-semi-bold'>*</sup>; 10KG 미만 $6, 10KG이상 무료배송</li>
                      <li className="list-checked-item text-dark">
                        다른 지역; 지역과 무게에 따라 차이가 있음으로 개별 안내 해 드립니다.  </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="table-light">마일리지</td>
                  <td className="table-light">
                    <ul className="list-checked list-checked-bg-success list-checked-sm mb-0">
                      <li className="list-checked-item text-dark  ">
                        1KG 당 마일리지 100포인트가 적립 됩니다.
                        마일리지 포인트는 배송대행 신청 하실때 마일리지 사용을 선택해 이용 하실 수 있습니다.</li>
                      <li className="list-checked-item text-dark">
                        마일리지 포인트 2000 = 1KG 무료! </li>
                    </ul>
                  </td>
                </tr>

              </tbody>
            </table>

          </div>
          <div className="tab-pane fade" id="propertyOverviewNavTwo" role="tabpanel" aria-labelledby="propertyOverviewNavTwo-tab">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td className="table-light td-width-200">운송요금</td>
                  <td className="table-light">
                    <ul className="list-checked list-checked-bg-success list-checked-sm mb-0">
                      <li className="list-checked-item text-dark">10KG 미만: KG당 $13</li>
                      <li className="list-checked-item text-dark">10KG 이상: KG당 $10</li>
                    </ul>
                    <div className="small mt-2">
                      <i className="bi bi-exclamation-circle me-1 text-danger"></i>
                      10KG 미만의 경우 총 운송료가 $100 이상인 경우 $100로 받습니다.
                      <br />예: 8.5KG x $13 = <del>$110.5</del> (이 경우 운송요금은 $100 입니다)
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="table-light">픽업</td>
                  <td className="table-light">방문당 $6이며 10KG 이상인 경우 무료픽업 해 드립니다. (픽업은 오클랜드 지역<sup className='fw-semi-bold'>*</sup>만 가능합니다)</td>
                </tr>
                <tr>
                  <td className="table-light">VAT</td>
                  <td className="table-light">
                    <ul className="list-checked list-checked-bg-success list-checked-sm mb-0">
                      <li className="list-checked-item text-dark">총 상품금액 USD 150 미만인 경우 = Free</li>
                      <li className="list-checked-item text-dark">총 상품금액 USD 150 이상인 경우 = 총상품금액의 20%</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="table-light">세관/검역비</td>
                  <td className="table-light">한국 도착 후 한국 세관에서 검역 실행시 검역비와 Handling Fee가 부과 됩니다. </td>
                </tr>
                <tr>
                  <td className="table-light">국내배송</td>
                  <td className="table-light">
                    <ul className="list-checked list-checked-bg-success list-checked-sm mb-0">
                      <li className="list-checked-item text-dark">기본 배송 비용은 무료입니다. </li>
                      <li className="list-checked-item text-dark"> 도서, 산간지방은 추가 비용이 발생합니다. </li>
                    </ul>
                  </td>
                </tr>

              </tbody>
            </table>

          </div>

        </div>
        <div className="d-flex pe-lg-5 mt-5" data-aos="fade-up">
          <div className="flex-shrink-0">
            <span className="svg-icon text-primary">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z" fill="#035A4B"></path>
                <path opacity="0.3" d="M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z" fill="#035A4B"></path>
              </svg>

            </span>
          </div>
          <div className="flex-grow-1 ms-4">
            <h4>K-택배 오클랜드 지역 기준</h4>
            <p>북쪽은 알바니 까지, 남쪽은 마누카우 시티센터 까지, 서쪽은 매시 까지, 동쪽은 하윅 까지 입니다</p>
          </div>
        </div>

      </div>

      <div className="container content-space-b-1">
        <div className="card card-bordered">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6">
                <h4 className="card-title mb-4">예상 배송비용 계산기</h4>
              </div>
            </div>
            <div className="row mb-2 mb-lg-0">
              <div className="col-lg-7 mb-2 mb-lg-0">
                <form
                  className={`row ${isSubmitted ? "was-validated" : ""} `}
                  onSubmit={handleSubmit(getQuote)}
                >
                  <div className="col-sm-6 mb-3">
                    <label htmlFor="amortizationPaymentCalculator" className="form-label">배송국가</label>

                    <select className="form-select"
                      {...register("ddlLocation")}
                    >
                      <option value="2">한국</option>
                      <option value="1">뉴질랜드</option>
                    </select>
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label htmlFor="properyPricePaymentCalculator" className="form-label">상품 무게</label>

                    <div className={`input-group input-group-merge ${errors.txtWeight ? "is-invalid" : "is-valid"} `} >
                      <span className="input-group-prepend input-group-text">kg</span>
                      <input type="text" className="form-control"
                        {...register("txtWeight", { required: true })} />
                    </div>
                  </div>

                  <div className="col-sm-4 mb-3">
                    <label htmlFor="depositPaymentCalculator" className="form-label">가로</label>

                    <div className={`input-group input-group-merge ${errors.txtWidth ? "is-invalid" : "is-valid"} `} >
                      <span className="input-group-prepend input-group-text">cm</span>
                      <input type="text" className="form-control"
                        {...register("txtWidth", { required: true })} />
                    </div>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <label htmlFor="depositPaymentCalculator" className="form-label">세로</label>

                    <div className={`input-group input-group-merge ${errors.txtHeight ? "is-invalid" : "is-valid"} `} >
                      <span className="input-group-prepend input-group-text">cm</span>
                      <input type="text" className="form-control"
                        {...register("txtHeight", { required: true })} />
                    </div>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <label htmlFor="depositPaymentCalculator" className="form-label">높이</label>

                    <div className={`input-group input-group-merge ${errors.txtDepth ? "is-invalid" : "is-valid"} `} >
                      <span className="input-group-prepend input-group-text">cm</span>
                      <input type="text" className="form-control"
                        {...register("txtDepth", { required: true })} />
                    </div>
                  </div>

                  <div className="d-grid content-space-b-1">
                    <AwaitButton type="submit" className="btn btn-primary" loading={isLoading} >
                      계산하기
                    </AwaitButton>
                  </div>
                  <ul className="list-unstyled list-py-1 mb-0">
                    <li className="d-flex align-items-center">
                      <i className="bi-circle text-primary me-2"></i>
                      <span className="text-dark fw-semi-bold">상품무게</span>
                      <div className="ms-auto">
                        <span>{chargeWeight}</span>
                      </div>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi-circle text-info me-2"></i>
                      <span className="text-dark fw-semi-bold">예상 배송비</span>
                      <div className="ms-auto">
                        <span className="h1">{chargeAmount}</span>
                      </div>
                    </li>
                  </ul>
                </form>
              </div>
              <div className="col-lg-5">
                <div className="col-12">
                  <div>
                    <img className="img-fit" src="/assets/img/others/weight-info.jpg" alt="weight" />
                  </div>
                  <div className="col-12 ms-auto">
                    <p className="card-text small ">
                      <i className="bi bi-exclamation-circle me-1 "></i> 예상 비용은 참고용으로만 사용하시기 바랍니다.
                      <br /><i className="bi bi-exclamation-circle me-1 "></i>예상 비용에는 수수료, GST등이 포함되어 있지 않습니다.
                      <br /><i className="bi bi-exclamation-circle me-1 "></i>부피무게가 실제 무게보다 더 클 경우 요금이 계산되는 기준은 부피무게가 됩니다.
                      부피무게를 줄이기 위해 최대한 꼼꼼히 작게 포장해 주세요.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </main >
  )
}


export default DeliveryFee
