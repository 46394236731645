import React from 'react'


const Warehouse = props => {
  return (
    <main id="content" role="main" >
      <div className="bg-img-start" style={{ backgroundImage: "url(/assets/svg/components/card-11.svg)" }}>
        <div className="container content-space-t-2 content-space-b-1">
          <div className="text-center mx-md-auto " >
            <h2 className="mt-10">배송대행지 주소 안내</h2>
            <p>K택배를 이용하여 항공택배를 보내고 받으시는 방법을 안내해 드립니다</p>
            <p>배송대행부터 통관업무 대행까지 해외배송에 관한 모든 업무를 도와 드리겠습니다</p>

          </div>
        </div>
      </div>

      <div className="container content-space-1 content-space-lg-2">
        <div className="row justify-content-lg-between align-items-lg-center">
          <div className="col-sm-6 mb-5 mb-lg-0">
            <div className="card card-bordered">
              <div className="card-body">
                <h4 className="card-title">한국 배송대행지</h4>
                <p>한국 <i className="bi bi-arrow-right-short"></i> 뉴질랜드로 배송대행</p>
                <dl className="row">
                  <dt className="col-3">주소</dt>
                  <dd className="col-9"><div className="dark-font">서울시 강서구 양천로 400-12 더리브골드타워 1002 (07573)</div> </dd>
                  <dt className="col-3">연락처</dt>
                  <dd className="col-9"><div className="dark-font">010-9392-8097</div></dd>
                  <dt className="col-3">수신자</dt>
                  <dd className="col-9"><div className="dark-font">고객님 성함(K택배)</div><div>
                    예: 홍길동(K택배)</div></dd>


                </dl>
              </div>
            </div>
          </div>

          <div className="col-sm-6 ">
            <div className="card card-bordered">
              <div className="card-body">
                <h4 className="card-title">뉴질랜드 배송대행지</h4>
                <p>뉴질랜드 <i className="bi bi-arrow-right-short"></i> 한국으로 배송대행</p>
                <dl className="row">
                  <dt className="col-3">주소</dt>
                  <dd className="col-9"><div className="dark-font">99 Killybegs Drive, Pinehill, Auckland, New Zealand</div></dd>
                  <dt className="col-3">연락처</dt>
                  <dd className="col-9"><div className="dark-font">021 883 671</div></dd>
                  <dt className="col-3">수신자</dt>
                  <dd className="col-9"><div className="dark-font">Jaeho Lee(고객님 영문명)</div><div>
                    예: Jaeho Lee(John Doe)</div></dd>
                </dl>
              </div>
            </div>
          </div>



        </div>
      </div>
    </main>
  )
}



export default Warehouse
