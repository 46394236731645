import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { NavLink } from "react-router-dom";
import * as moment from "moment";

const BoardNoticeListItem = props => {

  const { pageSizeChanged, pageNoChanged } = props;

  const items = props.items.Data;
  const paging = props.items.Paging;

  const onPage = (event) => {
    //console.log(event);
    const { page, rows } = event;
    if (Number(paging.PageSize) !== rows) {
      pageSizeChanged(rows);
    }
    else if (Number(paging.PageSize) !== page + 1) {
      pageNoChanged(page + 1);
    }
  };

  const setResponsiveContents = (title, value) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{title}</span>
        {value}
      </React.Fragment>
    );
  }

  const setTimeStamp = (time) => {
    return moment(time, 'DD/MM/YYYY HH:mm:ss').format("DD/MM/YYYY");
  };

  const setTitle = (item) => {
    return (
      <NavLink className="input-link-dark" to={`/notice/${item.BoardNoticeId}`}>
        {item.Title}
      </NavLink>
    );
  }

  return (
    <div className="datatable-responsive-k">
      <DataTable
        value={items}
        className="p-datatable-responsive-k p-datatable-sm"
        paginator
        paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        rows={Number(paging.PageSize)}
        totalRecords={Number(paging.TotalNumberOfRecords)}
        lazy onPage={onPage} first={Number(paging.PageSize) * (Number(paging.PageNumber) - 1)}
      >
        <Column className="p-datatable-title" field="Title" header="제목" body={r => setResponsiveContents('제목', setTitle(r))}  ></Column>
        <Column field="WriterName" header="작성자" body={r => setResponsiveContents('작성자', r.WriterName)}  ></Column>
        <Column field="CreateDate" header="작성일" body={r => setResponsiveContents('작성일', setTimeStamp(r.CreateDate))}  ></Column>
      </DataTable>
    </div>
  )
}


export default BoardNoticeListItem
