import { useState, useEffect, createContext, useContext } from 'react';


export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [userToken, setUserToken] = useState();

  useEffect(() => {
    setUserToken(JSON.parse(localStorage.getItem("kauth")))
  }, [])


  const setAuthToken = data => {
    setUserToken(data);
    localStorage.setItem("kauth", JSON.stringify(data));
  };


  const memoedValue = {
    AuthToken: (userToken?.expires_in > Date.now() / 1000) ? userToken : null,
    setAuthToken
  };

  return (
    <AuthContext.Provider value={memoedValue}>
      {children}
    </AuthContext.Provider>
  );
}


export function useAuth() {
  return useContext(AuthContext);
}