import React, { memo } from "react";

const Breadcrumb = ({ Current, Parent, Small, HideMenu }) => {
  return (
    <>
      <div
        className="navbar-dark bg-dark"
        style={{
          backgroundImage: "url(/assets/svg/components/wave-pattern-light.svg)",
        }}
      >
        <div
          className={`container content-space-3 ${
            Small ? "" : "content-space-b-lg-3"
          } `}
        >
          <div className="row align-items-center">
            <div className="col">
              <div className="d-none d-lg-block">
                <h1 className="h2 text-white">{Current}</h1>
              </div>

              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                  <li className="breadcrumb-item">{Parent}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {Current}
                  </li>
                </ol>
              </nav>
            </div>
            {!HideMenu && (
              <div className="col-auto">
                <button
                  className="navbar-toggler d-lg-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sidebarNav"
                  aria-controls="sidebarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </span>
                  <span className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Breadcrumb);
