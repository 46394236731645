import React, { memo } from "react";
import { useRecoilState } from "recoil";
import { createOrderState } from "../../../Store/OrderStore";
import ControlledInput from "../../../Core/UI/ReactHookForm/ControlledInput";
import ControlledSelect from "../../../Core/UI/ReactHookForm/ControlledSelect";
import * as GlobalConstant from "../../../Core/Constants/GlobalConstant";
import { useFormContext } from "react-hook-form";
import { trim } from "lodash";

const OrderItemSnippet = (props) => {
  const { setValue } = useFormContext();
  const { item, index } = props;
  const [orderInfo, setOrderInfo] = useRecoilState(createOrderState);

  const handleChange = (field, value) => {

    let newOrderItems = [...orderInfo.OrderItems];
    newOrderItems[index] = { ...newOrderItems[index], [field]: trim(value) };

    setOrderInfo((p) => {
      return { ...p, OrderItems: newOrderItems };
    });
  };

  const handleAmountChange = (field, value, min, max) => {
    //react hook form등 관련 변수들 state를 모두 맞춰야 한다...
    // if (Number(value) < min && Number(value) >= max) {
    //   return;
    // }

    let newOrderItems = [...orderInfo.OrderItems];
    newOrderItems[index] = { ...newOrderItems[index], [field]: value };

    setOrderInfo((p) => {
      return { ...p, OrderItems: newOrderItems };
    });
  };

  const itemTypeChange = (event) => {
    let newOrderItems = [...orderInfo.OrderItems];
    newOrderItems[index] = {
      ...newOrderItems[index],
      ItemTypeName: event.target.options[event.target.selectedIndex].text,
      ItemTypeCode: event.target.value,
    };

    setOrderInfo((p) => {
      return { ...p, OrderItems: newOrderItems };
    });
  };

  const removeItem = () => {
    if (item.OrderItemId) {
      const newDeletedItems = [
        ...orderInfo.DeletedOrderitems,
        item.OrderItemId,
      ];
      setOrderInfo((p) => {
        return { ...p, DeletedOrderitems: newDeletedItems };
      });
    }

    const newOrderItems = [...orderInfo.OrderItems];

    newOrderItems.splice(index, 1);

    setOrderInfo((p) => {
      return { ...p, OrderItems: newOrderItems };
    });
  };

  const setDeliveryCompany = () => {
    console.log("setDeliveryCompany");
    setValue(`ddlOrderItemDeliveryCompany${index}`, "직접접수");
    setValue(`txtDeliveryTracking${index}`, "직접접수");

    let newOrderItems = [...orderInfo.OrderItems];
    newOrderItems[index] = {
      ...newOrderItems[index],
      DeliveryCompany: "직접접수",
      TrackingNumber: "직접접수",
    };

    setOrderInfo((p) => {
      return { ...p, OrderItems: newOrderItems };
    });
  };

  return (
    <div className="card card-sm shadow-sm mt-4">
      <div className="card-body">
        {
          index > 0 && item.IsEntered !== "True" && (
            <button
              type="button"
              className="btn btn-ghost-danger mt-1 btn-sm"
              onClick={() => removeItem()}
            >
              <i className="bi-dash-circle me-1"></i> 상품삭제
            </button>
          )

          // <div className="js-create-field form-link mb-4 input-link-red"
          //   onClick={() => removeItem()}
          // >
          //   <i className="bi-dash-circle me-1"></i> 상품삭제
          // </div>
        }
        {item.IsEntered === "True" && (
          <div className="js-create-field form-link mb-4 input-link-red text-info">
            <i className="bi bi-exclamation-circle"></i> 입고완료
            <span className="form-label-secondary ms-2 text-info">
              (입고된 상품은 변경이 불가능 합니다.)
            </span>
          </div>
        )}
        <div className="row g-3">
          <div className="col-md-4">
            <label htmlFor={`ddlOrderItemType${index}`} className="form-label">
              상품타입 <span className="text-danger ms-1">*</span>
            </label>
            <ControlledSelect
              name={`ddlOrderItemType${index}`}
              defaultValue={item.ItemTypeCode}
              options={orderInfo?.OrderItemType.map((i) => {
                return (
                  <option key={i.Code} value={i.Code}>
                    {i.Name}
                  </option>
                );
              })}
              handleChange={(e) => itemTypeChange(e)}
              disabled={item.IsEntered === "True" ? true : false}
            />
          </div>
          <div className="col-md-8">
            <div className="d-flex justify-content-between">
              <label
                htmlFor={`txtOrderItemName${index}`}
                className="form-label"
              >
                상품명 <span className="text-danger ms-1">*</span>
              </label>
              <span className="small">
                <i className="bi bi-exclamation-circle me-1 text-danger"></i>
                고가의 상품이 누락되지 않도록 주의 해주세요
              </span>
            </div>
            <ControlledInput
              name={`txtOrderItemName${index}`}
              defaultValue={item.ItemName}
              handleChange={(e) => handleChange("ItemName", e)}
              disabled={item.IsEntered === "True" ? true : false}
              placeholder="모든 상품명을 기입해 주세요"
            />
          </div>

          <div className="col-md-6">
            <label
              htmlFor={`txtOrderItemAmount${index}`}
              className="form-label"
            >
              상품가격
              <span className="text-danger ms-1">*</span> (
              {orderInfo?.AmountUnit})
            </label>
            <ControlledInput
              name={`txtOrderItemAmount${index}`}
              defaultValue={item.ItemAmount}
              type="number"
              placeholder={orderInfo?.AmountUnit}
              handleChange={(e) =>
                handleAmountChange("ItemAmount", e, 0, 100000000)
              }
              disabled={item.IsEntered === "True" ? true : false}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor={`txtItemQuantity${index}`} className="form-label">
              상품수량{" "}
            </label>
            <ControlledInput
              name={`txtItemQuantity${index}`}
              defaultValue={item.ItemQuantity}
              type="number"
              handleChange={(e) => handleChange("ItemQuantity", e)}
              rules={{
                required: true
                , validate: {
                  positiveNumber: (value) => parseFloat(value) > 0
                }
              }}
              disabled={item.IsEntered === "True" ? true : false}
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-between">
              <label
                htmlFor={`ddlOrderItemDeliveryCompany${index}`}
                className="form-label"
              >
                택배사
              </label>
            </div>

            {!(orderInfo.DeliveryLocationCode === "2") && (
              <ControlledSelect
                name={`ddlOrderItemDeliveryCompany${index}`}
                defaultValue={item.DeliveryCompany}
                options={GlobalConstant.DeliveryCompanyList.map((i) => {
                  return (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  );
                })}
                handleChange={(e) =>
                  handleChange("DeliveryCompany", e.target.value)
                }
                rules={{ required: false }}
                disabled={item.IsEntered === "True" ? true : false}
              />
            )}
            {orderInfo.DeliveryLocationCode === "2" && (
              <ControlledInput
                name={`ddlOrderItemDeliveryCompany${index}`}
                defaultValue={item.DeliveryCompany}
                handleChange={(e) => handleChange("DeliveryCompany", e)}
                rules={{ required: false }}
                disabled={item.IsEntered === "True" ? true : false}
              />
            )}
          </div>
          <div className="col-md-6">
            <label
              htmlFor={`txtDeliveryTracking${index}`}
              className="form-label"
            >
              택배사 송장번호{" "}
            </label>
            <ControlledInput
              name={`txtDeliveryTracking${index}`}
              defaultValue={item.TrackingNumber}
              handleChange={(e) => handleChange("TrackingNumber", e)}
              rules={{ required: false }}
              disabled={item.IsEntered === "True" ? true : false}
            />
          </div>
          <div className="mt-1">
            <div></div>
            <div className="">
              <div className="small ms-2 input-link">
                <i className="bi bi-exclamation-circle me-1 text-danger"></i>
                송장번호가 아직 발급 되지 않은 상태이면 발급 후 꼭 입력해
                주세요.
              </div>
              <div className="small ms-2 input-link">
                <i className="bi bi-exclamation-circle me-1 text-danger"></i>
                송장번호 없는 상품은 창고에 도착해도 화주가 확인 되지 않아 보낼
                수 없습니다.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(OrderItemSnippet);
