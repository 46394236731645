import React, { useEffect } from 'react'
import OrderLocation from './Items/OrderLocation';
import OrderReceiver from './Items/OrderReceiver';
import OrderItems from './Items/OrderItems';
import OrderCompleted from './Items/OrderCompleted';
import * as OrderApi from '../../Apis/OrderApi';
import * as AccountApi from '../../Apis/AccountApi';
import { useRecoilState } from 'recoil';
import { createOrderState } from '../../Store/OrderStore';
import { useSpinner } from "../../Core/Context/SpinnerContext";
import Breadcrumb from '../Common/Breadcrumb/Breadcrumb';
import { useHistory } from "react-router-dom";
import { useNotification } from "../../Core/Context/NotificationContext";
import OrderLocationNZ from './Items/OrderLocationNZ';

const createOrderModel =
{
  AmountUnit: "",
  CurrentStep: 1,
  UserId: 0,
  DeliveryLocationCode: "0",
  IsReceiverOwn: true,
  ReceiverName: "",
  ReceiverEmail: "",
  ReceiverPhone: "",
  ReceiverMobile: "",
  ReceiverAddress1: "",
  ReceiverAddress2: "",
  ReceiverZipcode: "",
  PickupTypeCode: "2",
  PersonalCustomCode: "",
  ReceiverMessage: "",
  IsCombineItem: false,
  IsUseMileage: false,
  IsRepackage: false,
  IsPurchaseOrder: false,
  OrderItems: [
    {
      OrderId: "0",
      OrderItemId: "0",
      ItemTypeCode: "",
      ItemTypeName: "",
      ItemName: "",
      ItemQuantity: "1",
      ItemUrl: "",
      ItemAmount: "",
      DeliveryCompany: "",
      TrackingNumber: ""
    }
  ],
  PurchaseOrderItems: [
    {
      OrderId: "0",
      PurchaseOrderItemId: "0",
      ItemTypeCode: "",
      ItemTypeName: "",
      ItemName: "",
      ItemQuantity: "1",
      ItemUrl: "",
      ItemAmount: "",
      DeliveryCompany: "",
      TrackingNumber: "",
      OrderNote: ""
    }
  ],
  OrderItemType: [],
  UserInfo: [],
  DeletedOrderitems: [],

};


const CreateOrder = () => {
  const history = useHistory();
  const [orderInfo, setOrderInfo] = useRecoilState(createOrderState);
  const { setSpinner } = useSpinner();
  const { setNotifiications } = useNotification();


  const getCustomerInfo = async () => {
    setSpinner(true);

    try {
      const customerInfo = await AccountApi.getCustomerInfo();

      if (customerInfo) {
        setOrderInfo(p => {
          return {
            ...p,
            UserInfo: customerInfo
          }
        });
      }
    }
    catch (err) {
      console.log('err', err);
    }

    setSpinner(false);
  }

  const getOrderItemType = async () => {

    setSpinner(true);

    try {
      const orderItemType = await OrderApi.getOrderItemType();

      if (orderItemType) {
        setOrderInfo(p => {
          return {
            ...p,
            OrderItemType: orderItemType
          }
        });
      }
    }
    catch (err) {
      console.log('err', err);
    }

    setSpinner(false);
  }

  const setOrder = async () => {
    setSpinner(true);
    try {
      await OrderApi.createOrder(orderInfo);
      setOrderInfo(null);
      setSpinner(false);
      history.push("/orderlist");
    }
    catch (err) {
      setSpinner(false);
      console.log('err', err);

      if (err.data?.Contents) {
        const errMessage = err.data?.Contents.map((item) => item.Message);
        setNotifiications("error", errMessage, "입력한 정보를 확인해 주세요", 3000, null);
      }
    }
    // window.scroll({ top: 0, behavior: 'smooth' })
    // setOrderInfo(p => {
    //   return { ...p, CurrentStep: 4 }
    // });
  }

  useEffect(() => {
    setOrderInfo(createOrderModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
    getOrderItemType();
    getCustomerInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <main id="content" role="main" className="bg-light">
      <Breadcrumb
        Current={"Create Orders"}
        Parent={"My Page"}
        Small
        HideMenu
      />
      <div className="container container-min content-space-1 content-space-lg-2">
      {console.log(orderInfo)}
        {orderInfo?.CurrentStep === 1 && <OrderLocation />}
        {orderInfo?.CurrentStep === 1.5 && <OrderLocationNZ />}
        {orderInfo?.CurrentStep === 2 && <OrderReceiver />}
        {orderInfo?.CurrentStep === 3 && <OrderItems setOrder={setOrder} />}
        {orderInfo?.CurrentStep === 4 && <OrderCompleted />}
      </div>
    </main>
  )
}



export default CreateOrder
