import React from 'react'
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";

const ConfirmEmail = ({ requestPin }) => {

  const { register, handleSubmit, setValue, formState: { errors, isSubmitted } } = useForm();

  const onSubmit = async (data) => {
    setValue("txtEmail", "");
    requestPin(data);
  };

  return (
    <div className="flex-grow-1 mx-auto" style={{ maxWidth: "28rem" }}>
      <div className="text-center mb-5 mb-md-7">
        <h1 className="h2">Forgot password?</h1>
        <p>회원가입시 등록한 이메일을 입력해 주시기 바랍니다.</p>
      </div>

      <form
        className={` ${isSubmitted ? "was-validated" : ""} `}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <label className="form-label" htmlFor="signupSimpleResetPasswordEmail" >이메일</label>

            <NavLink exact to="/login" className="link input-link-blue">
              <i className="bi-chevron-left small ms-1"></i> Back to Log in
            </NavLink>


          </div>

          <div className={`input-group input-group-merge ${errors.txtEmail ? "is-invalid" : "is-valid"} `} >
            <input
              type="text"
              className="form-control form-control-lg "
              {...register("txtEmail",
                {
                  required: true,
                  pattern: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                })
              }
            />
          </div>
          {errors.txtEmail && <span className="invalid-feedback">이메일을 입력해 주세요.</span>}


        </div>

        <div className="d-grid mb-3">
          <button type="submit" className="btn btn-primary btn-lg">Submit</button>
        </div>
      </form>
    </div>
  )
}



export default ConfirmEmail
