import React, { useState, useEffect } from "react";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { BrowserRouter as Router, Redirect } from "react-router-dom";


const getToken = () => {
  const existingTokens = JSON.parse(localStorage.getItem("kauth"));

  if (existingTokens?.expires_in > Date.now() / 1000) {
    return "Bearer " + existingTokens.access_token;
  }

  return "";
};

const withErrorHander = (WrappedComponent, axios) => (props) => {
  const [error, setError] = useState(null);

  const reqInterceptor = axios.interceptors.request.use((req) => {

    if (!req.headers.Authorization) {
      req.headers.Authorization = getToken();
    }

    return req;
  });
  const resInterceptor = axios.interceptors.response.use(
    (res) => res,
    (err) => {
      //setError(err);
      console.log(
        "==================Error:",
        err.response
      );

      if (err.response?.status === 401) {
        setError(true);
        NotificationManager.error(
          "please login again",
          "login expire",
          3000,
          () => {
            window.location = "/login";
          }
        );

        setTimeout(() => {
          window.location = '/login';
        }, 3000);

        //return Promise.reject(err);

      }
      // else if (err.response.status === 400) {

      //   NotificationManager.error(
      //     "로그인 정보를 찾을수 없습니다.",
      //     "Something's wrong",
      //     3000
      //   );

      // } else if (err.response.status === 500) {

      //   NotificationManager.error(
      //     err.response.data.Errors ? err.response.data.Errors[0].Error : err.response.statusText,
      //     "Something's wrong",
      //     3000
      //   );

      // }

      throw err.response;

    }
  );

  useEffect(() => {
    return () => {
      axios.interceptors.request.eject(reqInterceptor);
      axios.interceptors.response.eject(resInterceptor);
    };
  }, [reqInterceptor, resInterceptor]);


  return (
    <>
      {error && <Router><Redirect to={{ pathname: "/login", state: { referer: window.location.pathname } }} /></Router>}
      <WrappedComponent {...props} />
      <NotificationContainer />
    </>
  );
};

export default withErrorHander;
