import axiosBase from "./Axios/AxiosCommon";
import qs from "query-string";
//import useAxios from 'axios-hooks'

export const Login = (userLoginId, password) => {

  // const params = qs.stringify({
  //   grant_type: "password",
  //   scope: "Customer",
  //   username: userLoginId,
  //   password: password
  // });

  // const [{ data, loading, error }, caller] = useAxios({
  //   url: '/token',
  //   method: 'POST',
  //   params: params,
  // },
  //   { manual: true },
  // );

  // return { caller, data, loading, error };

  return axiosBase
    .post(
      "/token",
      qs.stringify({
        grant_type: "password",
        scope: "Customer",
        username: userLoginId,
        password: password,
      }),
      // {
      //   headers: {
      //     "Content-Type": "x-www-form-urlencoded",
      //     accept: "application/json",
      //   },
      // }
    )
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
};



export const getCustomerInfo = () => {
  return axiosBase
    .get("/customer/account/detail")
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}


export const createCustomer = (customer) => {
  // {
  //   "IsChangePassword": true,
  //   "UserPassword": "string",
  //   "UserName": "string",
  //   "EmailAddress": "string",
  //   "IsActive": true,
  //   "PhoneNumber": "string",
  //   "MobileNumber": "string",
  //   "ZipCode": "string",
  //   "UserAddress1": "string",
  //   "UserAddress2": "string",
  //   "PersonalCustomCode": "string"
  // }
  return axiosBase
    .post("/customer/account", customer)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
}


export const updateCustomerPassword = (password) => {
  return axiosBase
    .post("/customer/account/updatepassword", password)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
}


export const updateCustomer = (customer) => {
  console.log('updateCustomer', customer);
  return axiosBase
    .put("/customer/account/detail", customer)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const requestPasswordResetCode = (email) => {
  return axiosBase
    .get("/customer/account/resetpasswordcode?email=" + email)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
}



export const responsePasswordResetCode = (pin) => {
  return axiosBase
    .post("/customer/account/resetpasswordcode", pin)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
}



export const resetPassword = (newPassword) => {
  return axiosBase
    .post("/customer/account/resetpassword", newPassword)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      throw error;
    });
}

