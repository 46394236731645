import React from 'react'

const OrderOptions = props => {

  const { orderInfo, handleChange } = props;

  return (
    <>
      <div className="border-bottom pb-4 mb-4">
        <div className="form-check ">
          <input type="checkbox"
            checked={orderInfo?.IsCombineItem === true}
            className="form-check-input " id="signupHeroFormPrivacyCheck" name="signupFormPrivacyCheck"
            onChange={e => handleChange("IsCombineItem", e.target.checked)}
          />
          <label className="form-check-label small text-dark" htmlFor="signupHeroFormPrivacyCheck">합배송 신청 $5
            <span className="d-block text-muted small">2개 이상의 송장번호로 택배를 주문 하실 경우 합배송 요청을 해 같이 받으실 수 있습니다.</span>
          </label>

        </div>
      </div>
      <div className="border-bottom pb-4 mb-4">
        <div className="form-check ">
          <input type="checkbox"
            checked={orderInfo?.IsRepackage === true}
            className="form-check-input " id="chkRepackage" name="chkRepackage"
            onChange={e => handleChange("IsRepackage", e.target.checked)}
          />
          <label className="form-check-label small text-dark" htmlFor="chkRepackage">무료 재포장 서비스
            <span className="d-block text-muted small">박스 성형 또는 재포장을 하여 부피무게를 무료로 줄여 드립니다.  </span>
          </label>

        </div>
      </div>

      <div className="border-bottom pb-4">
        <div className="form-check ">
          {orderInfo?.UserInfo && Number(orderInfo.UserInfo.Mileage) >= 2000 ?
            <input type="checkbox"
              checked={orderInfo?.IsUseMileage === true}
              className="form-check-input " id="isUseMileage" name="isUseMileage"
              onChange={e => handleChange("IsUseMileage", e.target.checked)}
            />
            :
            <input type="checkbox"
              checked={orderInfo?.IsUseMileage === true}
              className="form-check-input " id="isUseMileage" name="isUseMileage"
              onChange={e => { }}
            />
          }
          <label className="form-check-label small text-dark" htmlFor="isUseMileage">마일리지 결제 (2,000 마일부터 사용가능)
            <span className="d-block text-muted small">고객님의 마일리지 : {orderInfo.UserInfo.Mileage} 마일 </span>
            <span className="d-block text-muted small">2,000 마일당 1kg의 배송비를 결제하실수 있습니다 </span>
          </label>
        </div>
      </div>
    </>
  )
}


export default OrderOptions;
