import React, { useEffect, useRef, useState, memo } from 'react';


const AwaitButton = ({
  className,
  type = "button",
  onClick = () => { },
  loading = false,
  disabled = false,
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const unmounted = useRef(false);


  useEffect(() => {
    return () => {
      unmounted.current = true;
    }
  }, []);

  const onClickFunc = e => {
    if (isLoading) return;

    const clickResult = onClick(e);

    if (clickResult instanceof Promise) {
      setIsLoading(true);

      clickResult.finally(() => {
        if (!unmounted.current) setIsLoading(false);
      });
    }
  };

  return (
    <button
      className={className}
      type={type}
      onClick={onClickFunc}
      disabled={(disabled || isLoading || loading)}
    >
      {(disabled || isLoading || loading) &&
        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
      }
      {children}
    </button>
  )
}

export default memo(AwaitButton);