import { useState, createContext, useContext } from 'react';
import Spinner from "../UI/Spinner/Spinner";

export const SpinnerContext = createContext();

export const SpinnerContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);


  const setSpinner = data => {
    setIsLoading(data);
  };

  // const memoedValue = useMemo(() => ({
  //   setSpinner
  // }),
  //   []
  // );
  return (
    <SpinnerContext.Provider value={{ setSpinner }}>

      {children}
      <Spinner active={isLoading} />

    </SpinnerContext.Provider>
  );
};
export function useSpinner() {
  return useContext(SpinnerContext);
}
