import React from 'react'

const AboutUs = props => {
  return (
    <main id="content" role="main" >
      <div className="bg-img-start" style={{ backgroundImage: "url(/assets/svg/components/card-11.svg)" }}>
        <div className="container content-space-t-2 content-space-b-2">
          <div className="text-center mx-md-auto " >
            <h2 className="mt-10">About us</h2>
            <p>K-Aircargo를 방문해 주셔서 감사합니다.</p>
          </div>
        </div>
      </div>

      <div className="container  content-space-b-lg-3">
        <div className="container content-space-t-1 content-space-b-1">
          <div className="text-center">
            <div className="js-nav-scroller  hs-nav-scroller-horizontal">
              <ul className="nav nav-segment  mb-7" id="propertyOverviewNavTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <a className="nav-link active" href="#propertyOverviewNavOne" id="propertyOverviewNavOne-tab" data-bs-toggle="tab" data-bs-target="#propertyOverviewNavOne" role="tab" aria-controls="propertyOverviewNavOne" aria-selected="true" style={{ minWidth: "10rem" }} >한국어</a>
                </li>

                <li className="nav-item" role="presentation">
                  <a className="nav-link" href="#propertyOverviewNavTwo" id="propertyOverviewNavTwo-tab" data-bs-toggle="tab" data-bs-target="#propertyOverviewNavTwo" role="tab" aria-controls="propertyOverviewNavTwo" aria-selected="false" style={{ minWidth: "10rem" }} > English</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="propertyOverviewNavOne" role="tabpanel" aria-labelledby="propertyOverviewNavOne-tab">
              <p>안녕하세요. 저희 <span className="text-dark h6">K-Aircargo</span>는 뉴질랜드와 한국간에 항공 물류의 원활한 서비스를 제공하고자
                2021년 첫 발을 내딛었습니다. 한국의 우수한 파트너와 협업을 통해 개인 및 상업용 상품의
                배송에 다양한 특화 서비스를 제공하여 고객의 Needs에 기여하고자 합니다.
              </p>

              <p>라스트마일 서비스 (‘상품이 고객의 손안에 전달되는 그 마지막 순간까지’)의 중요성이 물류업계의
                핫 이슈가 되고 있어 다양한 배송 방법의 요구 증가는 물류 서비스의 역할과 영역에 커다란 도전이 될 것입니다.
              </p>
              <p>저희 <span className="text-dark h6">K-Aircargo</span>는 이러한 현실의 변화에 고객에게 차별화된 경험을 제공하고 기존의 물류센터의 역할에 대한 개념을 넘어 풑필먼트 서비스 제공을 하기 위한 발전을 멈추지 않을 것입니다.
                이러한 목표를 실현하기 위해 저희 모든 임직원은 회사의 비전을 공유하며 힘쓰고 있습니다.
              </p>
              <p>
                <span className="text-dark h6">K-Aircargo</span>는 최고의 경쟁력 확보와 지속적 성장을 이루고자 끊임없는 혁신을 실천해 나갈 것입니다.  아울러, 고객사 및 개인고객을 모두 만족시키는 물류기업으로 성장할 <span className="text-dark h6">K-Aircargo</span> 에
                많은 관심과 성원을 부탁드립니다.
                감사합니다.
              </p>
              <p style={{ textAlign: "right" }}>
                2021년
                <br />K-Aircargo 임직원 일동
              </p>


            </div>
            <div className="tab-pane fade" id="propertyOverviewNavTwo" role="tabpanel" aria-labelledby="propertyOverviewNavTwo-tab">
              <p>Hello. <span className="text-dark h6">K-Aircargo</span> took the first step in 2021 to provide a smooth service of air logistics between New Zealand and South Korea.
              </p>
              <p>We want to contribute to customer needs by providing a variety of specialized services for the delivery of personal and commercial products through collaboration with excellent partners in South Korea.
              </p>
              <p>As the importance of last-mile service ('Til the last moment the product is delivered to the customer's hand') is becoming a hot issue in the logistics industry, the increasing demand for various delivery methods will present a great challenge to the role and area of logistics services.
              </p>
              <p><span className="text-dark h6">K-Aircargo</span> will not stop developing to provide customers with a differentiated experience in the face of this change of reality and to provide fulfillment service beyond the concept of the existing logistics center.
                To realize these goals, all of our executives and staff share the company's vision and work hard.
              </p>
              <p><span className="text-dark h6">K-Aircargo</span> will continue to innovate to secure the best competitiveness and achieve sustainable growth.
                In addition, we ask for your interest and support for <span className="text-dark h6">K-Aircargo</span>, which will grow into a logistics company that satisfies both customers and individual customers.
                Thank you.
              </p>

              <p style={{ textAlign: "right" }}>All of K-Aircargo</p>

            </div>
          </div>

          <div className="row gx-3">
            <div className="col mb-3">
              <div className="bg-img-start" style={{ backgroundImage: "url(/assets/img/aboutus/1.jpg)", height: "15rem" }}></div>
            </div>

            <div className="col-3 d-none d-md-block mb-3">
              <div className="bg-img-start" style={{ backgroundImage: "url(/assets/img/aboutus/2.jpg)", height: "15rem" }}></div>
            </div>

            <div className="col mb-3">
              <div className="bg-img-start" style={{ backgroundImage: "url(/assets/img/aboutus/3.jpg)", height: "15rem" }}></div>
            </div>

            <div className="w-100"></div>

            <div className="col mb-3 mb-md-0">
              <div className="bg-img-start" style={{ backgroundImage: "url(/assets/img/aboutus/4.jpg)", height: "15rem" }}></div>
            </div>

            <div className="col-4 d-none d-md-block mb-3 mb-md-0">
              <div className="bg-img-start" style={{ backgroundImage: "url(/assets/img/aboutus/5.jpg)", height: "15rem" }}></div>
            </div>

            <div className="col">
              <div className="bg-img-start" style={{ backgroundImage: "url(/assets/img/aboutus/6.jpg)", height: "15rem" }}></div>
            </div>
          </div>
        </div>


      </div>
    </main>
  )
}

export default AboutUs
