import React from 'react'
import * as GlobalConstant from '../../../../Core/Constants/GlobalConstant';

const OrderReceiver = ({ item }) => {
  const setPickup = () => {

    if (item.PickupTypeCode === "1") {
      return GlobalConstant.pickupType[1];
    }
    else {
      return GlobalConstant.pickupType[2];
    }
  }

  return (
    <div className="card-body">
      <div className="row align-items-center flex-grow-1 mb-5">
        <div className="col">
          <h5 className="card-header-title">수령인 정보</h5>
        </div>

        <div className="col-auto">

        </div>

      </div>
      {/* 수령인정보 */}
      <div className="row justify-content-md-between">
        <div className="col-md-10">
          <dl className="row">
            <dt className="col-1 min-width-120"><span className="card-subtitle text-body">수령방법:</span></dt>
            <dd className="col-7">
              <span className="h6">{setPickup()}</span>
              {item.LocalTrackingNumber && item.LocalTrackingNumber !== "" &&
                <span className="col-12 ms-4 link input-link-blue"><i className="bi bi-truck"></i> {item.LocalTrackingNumber}</span>
              }
            </dd>
          </dl>
        </div>
        <div className="col-md-6">
          <dl className="row">
            <dt className="col-3 min-width-120"><span className="card-subtitle text-body">이름:</span></dt>
            <dd className="col-7"><h6>{item.ReceiverName}</h6></dd>
            <dt className="col-3 min-width-120"><span className="card-subtitle text-body">전화번호:</span></dt>
            <dd className="col-7"><h6>{item.ReceiverPhone}</h6></dd>
          </dl>
        </div>
        <div className="col-md-6">
          <dl className="row">
            <dt className="col-3 min-width-120"><span className="card-subtitle text-body">이메일:</span></dt>
            <dd className="col-7"><h6>{item.ReceiverEmail}</h6></dd>
            <dt className="col-3 min-width-120"><span className="card-subtitle text-body">휴대폰:</span></dt>
            <dd className="col-7"><h6>{item.ReceiverMobile}</h6></dd>
          </dl>
        </div>
        <div className="col-md-10">
          <dl className="row">
            <dt className="col-1 min-width-120"><span className="card-subtitle text-body">주소:</span></dt>
            <dd className="col-7"><h6>{item.ReceiverAddress1} {item.ReceiverAddress2} ({item.ReceiverZipcode})</h6></dd>
          </dl>
        </div>
        {item.DeliveryLocationCode === "2" &&
          <div className="col-md-10">
            <dl className="row">
              <dt className="col-1 min-width-120"><span className="card-subtitle text-body">개인통관부호:</span></dt>
              <dd className="col-7"><h6>{item.PersonalCustomCode}</h6></dd>
            </dl>
          </div>
        }
      </div>


    </div >
  )
}


export default OrderReceiver
