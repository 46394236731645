import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNotification } from "../../../Core/Context/NotificationContext";
import AwaitButton from "../../../Core/UI/AwaitButton/AwaitButton";
import * as BoardApi from "../../../Apis/BoardApi";

const Contactus = (props) => {
  const { setNotifiications } = useNotification();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitted },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [contents, setContents] = useState("");
  const [isError, setIsError] = useState(false);

  const onSubmit = async (data, e) => {
    console.log("onSubmit", data);

    if (contents === "") {
      setIsError(true);
      return;
    }

    setIsLoading(true);

    const enquiry = {
      Name: data.txtName ?? "",
      Email: data.txtEmail ?? "",
      Phone: data.txtPhone ?? "",
      Contents: contents,
    };

    try {
      await BoardApi.createCustomerEnquiry(enquiry);

      setNotifiications(
        "success",
        "문의가 접수 되었습니다.",
        "Success",
        3000,
        null
      );

      setContents("");
      setIsError(false);
      reset();
    } catch (err) {
      setIsLoading(false);
      console.log("err=====", err);

      if (err.data?.Contents) {
        const errMessage = err.data?.Contents.map((item) => item.Message);
        setNotifiications(
          "warning",
          errMessage,
          "입력한 정보를 확인해 주세요",
          3000,
          null
        );
      }
    }

    setIsLoading(false);
  };

  return (
    <main id="content" role="main" className="bg-light">
      <div className="container content-space-t-3 content-space-t-lg-4 content-space-b-2">
        <div className="text-center mb-5 mb-md-7">
          <h2>Get in touch</h2>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-9 mb-lg-0">
            <div className="overflow-hidden">
              <iframe
                title="contact"
                width="100%"
                height="450"
                style={{ border: 1 }}
                allowFullScreen=""
                loading="lazy"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3197.572625806202!2d174.7256958766908!3d-36.73282407303952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d3bcbb2be4a4f%3A0xc7e0b189fabf32cb!2s99%20Killybegs%20Drive%2C%20Pinehill%2C%20Auckland%200632!5e0!3m2!1sko!2snz!4v1707447959014!5m2!1sko!2snz"
              ></iframe>
            </div>

            <div className="row mt-4">
              <div className="col-sm-6">
                <h5 className="mb-1">Call us:</h5>
                <p>+64 021 883 671</p>
              </div>

              <div className="col-sm-6">
                <h5 className="mb-1">Email us:</h5>
                <p>support@kaircargo.com</p>
              </div>

              <div className="col-sm-6">
                <h5 className="mb-1">Address:</h5>
                <p>99 Killybegs Drive, Pinehill, Auckland</p>
              </div>
              <div className="col-sm-6">
                <h5 className="mb-1">Work hours:</h5>
                <p>9am to 5pm Mon~Fri</p>
              </div>
              <div className="col-sm-6">
                <a
                  href="https://pf.kakao.com/_xmIExbb"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <div className="svg-icon svg-icon-sm text-primary">
                        <img
                          src="/assets/img/sns/ico_kakao.png"
                          alt="kakao"
                          style={{ width: "50px" }}
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <span className="small me-1">카카오톡 상담하기</span>
                      <div className="link small">ID: K택배</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="ps-lg-5">
              <div className="card">
                <div className="card-header border-bottom text-center">
                  <h3 className="card-header-title">General inquiries</h3>
                </div>

                <div className="card-body">
                  <form
                    className={` ${isSubmitted ? "was-validated" : ""} `}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="row gx-3">
                      <div className="col-12">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="hireUsFormFirstNameEg3"
                          >
                            이름 <span className="text-danger ms-1">*</span>
                          </label>
                          <div
                            className={`input-group input-group-merge ${
                              errors.txtName ? "is-invalid" : "is-valid"
                            } `}
                          >
                            <input
                              type="text"
                              className="form-control form-control-lg "
                              placeholder=""
                              {...register("txtName", { required: true })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row gx-3">
                      <div className="col-sm-6">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="hireUsFormWorkEmailEg3"
                          >
                            이메일 <span className="text-danger ms-1">*</span>
                          </label>
                          <div
                            className={`input-group input-group-merge ${
                              errors.txtEmail ? "is-invalid" : "is-valid"
                            } `}
                          >
                            <input
                              type="email"
                              className="form-control form-control-lg "
                              aria-invalid={errors.txtEmail ? "true" : "false"}
                              {...register("txtEmail", {
                                required: true,
                                pattern:
                                  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              })}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="hireUsFormPhoneEg3"
                          >
                            연락처{" "}
                          </label>
                          <div className="input-group input-group-merge is-valid">
                            <input
                              type="text"
                              className="form-control form-control-lg "
                              placeholder=""
                              {...register("txtPhone")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="form-label" htmlFor="hireUsFormDetails">
                        문의 내용 <span className="text-danger ms-1">*</span>
                      </label>
                      <div
                        className={`input-group input-group-merge ${
                          isError ? "is-invalid" : "is-valid"
                        } `}
                      >
                        <textarea
                          onChange={(e) => setContents(e.target.value)}
                          value={contents}
                          className="form-control form-control-lg"
                          rows="4"
                        ></textarea>
                      </div>
                    </div>

                    <div className="d-grid">
                      <AwaitButton
                        type="submit"
                        className="btn btn-primary btn-lg"
                        loading={isLoading}
                      >
                        Submit
                      </AwaitButton>
                    </div>

                    <div className="text-center">
                      <p className="form-text">
                        영업일 기준 1~2일내로 회신해 드리겠습니다
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Contactus;
