import React from 'react'


const OrderSummary = ({ item }) => {

  const setPaymentAmount = () => {
    if (item.TotalChargeAmount > 0) {
      return ("$" + item.TotalChargeAmount);
    }
  }



  return (
    <div className="card-body">
      <div className="row">
        <div className="col-md mb-4 mb-md-0">
          <div className="mb-4">
            <span className="card-subtitle">주문번호:</span>
            <h5>{item.OrderNumber}</h5>
          </div>
          <div>
            <div className="">
              <span className="card-subtitle px-3"><img className="img-60" src={`/assets/img/delivery/delivery-${item.DeliveryStatusCode}.png`} alt="item.DeliveryStatusName" /></span>
              <h3 className="font-blue ">{item.DeliveryStatusName}</h3>
            </div>
          </div>

        </div>
        <div className="col-md mb-4 mb-md-0">
          <div className="mb-4">
            <span className="card-subtitle">주문날짜:</span>
            <h5>{item.OrderDate}</h5>
          </div>
          <div>
            <span className="card-subtitle">결제금액</span>
            <h3 className="text-primary">{setPaymentAmount()}</h3>
          </div>

        </div>
        {/* <div className="col-md-auto">
          <div className="d-grid d-md-flex gap-3">
            <a className="btn btn-white btn-sm" href="#">Cancel subscription</a>

          </div>
        </div> */}
      </div>
    </div>
  )
};



export default OrderSummary
