import React from "react";
import * as GlobalConstant from "../../../../Core/Constants/GlobalConstant";
import * as KFunctions from "../../../../Core/Constants/KFunctions";
import { useHistory } from "react-router-dom";

const PurchaseOrder = ({ index, item }) => {

  const history = useHistory();

  const setPickup = () => {
    if (item.PickupTypeCode === "1") {
      return GlobalConstant.pickupType[1];
    } else {
      return GlobalConstant.pickupType[2];
    }
  };

  const setPaymentAmount = () => {
    if (item.TotalChargeAmount > 0) {
      return "$" + item.TotalChargeAmount;
    }
  };

  const getItemAmount = () => {
    return KFunctions.getCurrency(
      item.DeliveryLocationCode,
      item.TotalItemAmount
    );
  };

  return (
    <li className="card card-bordered shadow-none mb-3">
      <div className={`card-body light-background`}>
        <div className="row">
          <div className="col-6 col-md mb-3 mb-md-0">
            <small className="card-subtitle mb-0">주문번호</small>
            <small className="text-dark fw-semi-bold">{item.OrderNumber}</small>
          </div>
          <div className="col-6 col-md mb-3 mb-md-0">
            <small className="card-subtitle mb-0">배송지</small>
            <small className="text-dark fw-semi-bold">
              {item.DeliveryLocationCode === "2" ? "대한민국" : "뉴질랜드"}
            </small>
          </div>
          <div className="col-6 col-md">
            <small className="card-subtitle mb-0">주문일자</small>
            {/* <small className="text-dark fw-semi-bold">{moment(item.OrderDate).format("yyyy-MM-DD")}</small> */}
            <small className="text-dark fw-semi-bold">{item.OrderDate}</small>
          </div>
          <div className="col-6 col-md">
            <small className="card-subtitle mb-0">수령방법</small>
            <small className="text-dark fw-semi-bold">{setPickup()}</small>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-9">
            <div className="row">
              <div className="col-6 col-md mb-3 mb-md-0">
                <small className="card-subtitle mb-0">
                  <img
                    className="img-60"
                    src={`/assets/img/delivery/delivery-${item.DeliveryStatusCode}.png`}
                    alt="item.DeliveryStatusName"
                  />
                </small>
                <small className="text-dark fw-semi-bold">
                  <h6 className="font-blue mt-1">{item.DeliveryStatusName}</h6>
                </small>
              </div>
              <div className="col-6 col-md mb-3 mb-md-0">
                <small className="card-subtitle mb-0">결제금액</small>
                <small className="text-dark fw-semi-bold">
                  <div className="text-primary">{setPaymentAmount()}</div>
                </small>
              </div>
              <div className="col-6 col-md">
                <small className="card-subtitle mb-0">상품가격</small>
                <small className="text-dark fw-semi-bold">
                  <div className="row">
                    <div className="col-12">{getItemAmount()}</div>
                    {/* <div className="col-sm-6">({item.TotalItemCount} items)</div> */}
                  </div>
                </small>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-grid gap-2">
              <button
                type="button"
                className="btn btn-outline-info btn-sm"
                onClick={() => history.push("/purchaseorder/" + item.OrderId)}
              >
                <i className="bi-basket small me-2"></i>주문상세
              </button>

              {item.LocalTrackingNumber && item.LocalTrackingNumber !== "" && (
                <div>
                  <a className="btn btn-white btn-sm" href="/">
                    <i className="bi-truck small me-2"></i> Track order
                  </a>
                  <small className="text-dark fw-semi-bold">
                    <div className="row">
                      <div className="col-12">
                        Tracking No : {item.LocalTrackingNumber}
                      </div>
                    </div>
                  </small>
                </div>
              )}
              <small className="text-dark fw-semi-bold">
                <div className="row">
                  <div className="col-12">
                    {item.IsCombineItem === "True" && (
                      <span className="badge bg-soft-dark text-dark me-1">
                        합배송
                      </span>
                    )}
                    {item.IsRepackage === "True" && (
                      <span className="badge bg-soft-dark text-dark me-1">
                        재포장
                      </span>
                    )}
                    {item.IsUseMileage === "True" && (
                      <span className="badge bg-soft-dark text-dark me-1">
                        마일리지 결제
                      </span>
                    )}
                  </div>
                </div>
              </small>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default PurchaseOrder;
