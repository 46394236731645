import React from 'react'


const Policy = props => {
  return (
    <main id="content" role="main">
      <div className="container content-space-3 content-space-lg-4">
        <div className="card card-lg">
          <div className="card-header bg-dark py-sm-10">
            <h1 className="card-title h2 text-white">개인정보 처리방침</h1>
            <p className="card-text text-white">최종 수정일: October 31, 2021</p>
          </div>

          <div className="card-body">
            <div className="mb-7">
              <h4>제1조.  총칙</h4>

              <p>1. 본 『개인정보처리방침』에서 ‘개인정보’라 함은 생존하는 개인에 관한 정보로서 이름, 전화번호 등에 의하여 개인을 식별할 수 있는 정보(해당 정보만으로는 특정 개인을 알아볼 수 없어도 다른 정보와 쉽게 결합하여 알아볼 수 있는 것 포함)를 말합니다.</p>
              <p>2. K-택배은 고객님의 개인정보를 매우 중요시하며, 「개인정보보호법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 행정안전부의 「개인정보의 안전성 확보조치 기준」, 방송통신위원회의 「개인정보의 기술적·관리적 보호조치 기준」 고시를 준수하고 있습니다.</p>
              <p>3. K-택배은 『개인정보 처리방침』을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.</p>
              <p>4. K-택배은 『개인정보 처리방침』을 홈페이지 첫 화면에 공개함으로써 고객님께서 언제나 용이하게 볼 수 있도록 조치하고 있습니다.</p>

            </div>

            <div className="mb-7">
              <div className="h4 mb-3">제2조.  개인정보의 처리 목적 및 항목</div>

              <div className="">1. 최소한의 수집 원칙 및 수집 방법</div>
              <div className="ms-3 mb-3">
                <div className="mb-3">가. K-택배은 배송/배송대행 등 서비스 제공을 위해 필요한 최소한의 정보만을 수집하며, 홈페이지, 전화, 이메일, 서면 양식, 위탁사로부터의 수령 등을 통해 법령이 허용한 범위 내에서 해당 목적을 위해서만 개인정보를 수집하고 있습니다.</div>
                <div className="mb-3">나. 기기 정보와 같은 생성 정보는 PC웹, 모바일 웹/앱 이용 과정에서 자동으로 생성되어 수집될 수 있습니다.</div>
              </div>
              <div className="">2. 개인정보 이용 동의</div>
              <div className="ms-3 mb-3">
                <div className="mb-3">가. K-택배은 개인정보 수집 및 이용에 대한 동의 절차를 마련하고 있으며, 고객이 ‘동의’ 항목에 체크하거나 버튼을 클릭하면 개인정보 수집 및 이용에 동의한 것으로 봅니다. 보내는 사람은 받는 사람의 개인정보를 제공하는 것에 동의할 권한이 있는 것으로 봅니다.</div>
                <div className="mb-3">나. 고객님은 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있으며, 동의를 거부할 경우 별도의 불이익은 없습니다. 단, 필수 동의 사항에 동의를 거부할 경우, 서비스 이용이 불가능하거나, 서비스 이용 목적에 따른 서비스 제공에 제한이 따를 수 있습니다.</div>
              </div>
              <div className="ms-3 mb-3">
                <div>다. 아래의 경우에는 예외로 합니다.</div>
                <div className="ms-3 mb-3">
                  <div>1) 고객님이 사전에 동의하였거나, 서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적/기술적인 사유로 통상의 동의를 받는 것이 현저히 곤란한 경우</div>
                  <div>2) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</div>
                  <div>3) 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우</div>
                  <div>4) 사업의 매각, 분할, 인수합병, 웹사이트의 소유권 이전 등을 통해 개인정보가 이전되는 경우 단, 서비스 제공자의 권리와 의무가 승계 또는 이전되는 경우에는 사전에 상세하게 고지하고, 개인정보 수집/이용 등에 대한 철회권을 부여합니다.</div>
                </div>
              </div>

              <div className="">3. 개인정보 처리 목적 및 항목</div>
              <div>수집하는 개인정보 항목과 수집 및 이용 목적은 다음과 같습니다.</div>
              <div>개인정보 항목 : 이름, 전화번호, 이메일, 주소</div>
              <div>수집및 이용 목적 : 배송대행 주문/수신항목 확인용</div>

            </div>

            <div className="mb-7">
              <div className="h4 mb-3">제3조. 개인정보의 보유 및 이용기간, 파기 방법</div>

              <div className="">1. 개인정보 보유 및 이용기간</div>
              <div className="mb-3">개인정보 수집 및 이용 목적이 달성된 후에는 예외 없이 해당 정보를 지체 없이 파기합니다. 다만, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다. 또한 이용자로부터 유예기간 요청이 있는 경우 해당 기간 동안 보존합니다. 이러한 경우들은 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존하며, 이외 다른 목적으로 이용하지 않습니다.</div>

              <div className="">2. 개인정보 파기 방법</div>
              <div className="mb-3">K-택배은 수집한 개인정보의 이용 목적이 달성되었거나 보관 및 이용 기간이 종료된 후에 해당 정보를 지체 없이 파기합니다. 파기 절차 및 방법은 다음과 같습니다.</div>
              <div className="ms-3 mb-3">
                <div className="mb-3">가. 파기절차고객님의 개인정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다. 별도 DB로 옮겨진 개인정보는 법률 및 처리 목적에 고시된 내용 이외의 다른 목적으로 이용되지 않습니다.</div>
                <div className="">나. 파기방법</div>
                <div className="">1) 종이에 출력된 개인정보 : 분쇄기로 분쇄하거나 소각 또는 파쇄 차량을 이용한 전문 파쇄</div>
                <div className="mb-3">2) 전자적 파일 형태로 저장된 개인정보 : 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제</div>
                <div className="">다. 특정 기간 서비스 미이용자 및 ‘e프라이버시 클린서비스’ 정보 파기</div>
                <div className="">1) 1년 이상 서비스 미이용자의 개인정보를 파기하고 있으며, 미이용자의 개인정보 파기 시점 도래 1개월 전에 이메일 등을 통해 관련 내용을 공지하고 있습니다.</div>
                <div className="mb-3">2) ‘e프라이버시 클린서비스’를 통해 기관으로부터 회원탈퇴를 요청 받은 경우 개인정보를 삭제하고 있습니다.</div>
              </div>
            </div>
            <div className="mb-7">
              <h4>제4조.  개인정보의 제3자 제공</h4>

              <p>K-택배은 고객님의 개인정보를 원칙적으로 목적 외 이용 및 외부에 제공하지 않습니다. 그러나, 보다 나은 서비스 제공을 위하여 개인정보를 제휴사에 제공하거나 공유할 필요가 있는 경우, 제공받는 자와 이용 목적, 제공하는 개인정보 항목, 보유 및 이용기간 등을 명시하여 동의를 구하는 절차를 거치며, 동의하지 않은 경우 제3자에게 제공하지 않습니다.
              </p>
            </div>
            <div className="mb-7">
              <h4>제5조. 개인정보의 처리 위탁</h4>
              <p>1. K-택배은 서비스 향상 및 원활한 업무처리를 위해서 아래와 같이 업무를 위탁하고 있으며, 관계 법령 및 안전성 확보 조치에 따라 위탁계약 시 제공하는 개인정보가 안전하게 관리될 수 있도록 위탁 업무의 목적 및 범위, 재위탁 제한에 관한 사항 등 필요한 사항을 규정·관리·감독하고 있습니다.</p>
              <p>2. K-택배의 개인정보 처리위탁 기관 및 위탁업무 내용은 다음과 같습니다. 위탁 업무의 내용이나 수탁자가 변경되는 경우에는 본 『개인정보 처리방침』을 통하여 공개하겠습니다.</p>
            </div>
            <div className="mb-7">
              <div className="h4 mb-3">제6조. 정보주체의 권리와 그 행사방법</div>
              <div className="mb-3">1. 고객은 언제든지 등록되어 있는 자신의 개인정보 관련하여 열람/정정/삭제/처리정지/동의 철회를 요청할 수 있습니다. 온라인 회원의 경우 인터넷 웹사이트를 통해 회원정보의 조회, 수정이 가능하며, 탈퇴 절차를 통해 철회(가입해지) 가능합니다.</div>
              <div className="mb-3">2. 회원정보 외, K-택배의 서비스를 이용하기 위해 처리되는 정보주체의 개인정보에 대하여 열람/정정/삭제/처리정지/동의 철회 요청이 필요할 경우 "열람/정정/삭제/처리정지/동의 철회 방법 안내"를 참고하시어 연락주시면 지체없이 조치하겠습니다.</div>
              <div className="">3. 다만, K-택배은 다음에 해당하는 경우, 개인정보의 전부 또는 일부에 대하여 열람/정정/삭제를 거절할 수 있습니다.</div>
              <div className="ms-3 mb-3">
                <div className="mb-3">가. 본인 또는 제3자의 생명, 신체, 재산 또는 권익을 현저하게 해할 우려가 있는 경우</div>
                <div className="mb-3">나. 서비스 제공 관련 업무에 현저한 지장을 미칠 우려가 있는 경우</div>
                <div className="mb-3">다. 법령을 위반하는 경우</div>
              </div>
            </div>
            <div className="mb-7">
              <div className="h4 mb-3">제7조. 개인정보보호를 위한 기술적·관리적 대책</div>
              <div className="mb-3">K-택배은 고객님의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손 되지 않도록 안전성 확보를 위하여 다음과 같은 기술적, 관리적 대책을 강구하고 있습니다. 단, 다음의 대책에도 불구하고 해커의 침입 및 신종 바이러스 침투와 같은 사고 발생 당시의 기술로 예방, 조치할 수 없는 불가항력적인 사유로 인하여 발생한 개인정보의 분실, 도난, 유출, 변조, 훼손 또는 고객님 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다.</div>
              <div className="">1. 기술적 대책</div>
              <div className="ms-3 mb-3">
                <div className="mb-3">가. 개인정보의 암호화고객님의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송 데이터를 암호화하거나 파일 암호화를 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.</div>
                <div className="mb-3">나. 암호 알고리즘을 이용한 정보 전송K-택배은 암호 알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안 기술(SSL)을 채택하고 있습니다.</div>
                <div className="mb-3">다. 접속기록 위·변조 방지 기술개인정보처리시스템의 접속기록 보관을 위해 안전한 보호 기술을 적용하고 있습니다.</div>
                <div className="">라. 해킹 등에 대비한 대책개인정보처리시스템의 접속기록 보관을 위해 안전한 보호 기술을 적용하고 있습니다.</div>
                <div className="ms-3 mb-3">
                  <div className="">1) K-택배은 백신 프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신 프로그램은 주기적으로 업데이트하며, 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 적용함으로써 개인정보가 침해되는 것을 방지하고 있습니다.</div>
                  <div className="mb-3">2) 해킹 등 외부 침입에 대비하여 침입차단시스템 및 침입탐지시스템을 운영하여 사내 네트워크를 보호하고, 각 서버마다 접근제어시스템을 설치하여 보안을 강화하고 있습니다.</div>
                </div>
              </div>
              <div className="">2. 관리적 대책</div>
              <div className="ms-3 mb-3">

                <div className="mb-3">가. 정보보호 정책 및 관리계획의 수립 시행K-택배은 개인정보의 안전한 처리를 위하여 정보보호 정책 및 관리계획을 수립하고 이를 시행·적용하고 있습니다.</div>
                <div className="">나. 개인정보 처리 직원의 최소화K-택배은 고객의 개인정보에 대한 접근 권한을 최소한의 인원으로 제한하고 있으며 해당 인원은 다음과 같습니다.</div>
                <div className="ms-3 mb-3">
                  <div className="">1) 개인정보 처리 목적 범위 내 서비스 제공을 수행하는 자</div>
                  <div className="">2) 개인정보관리책임자 및 담당자 등 개인정보관리업무를 수행하는 자</div>
                  <div className="">3) 기타 업무상 개인정보의 처리가 불가피한 자</div>
                </div>
                <div className="">다. 개인정보보호 교육 및 보안서약서</div>
                <div className="ms-3 mb-3">
                  <div className="">1) 개인정보를 처리하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호 의무에 관해 정기적인 사내 교육 등 필요한 교육을 실시하고 있습니다.</div>
                  <div className="">2) 입사 시 전 직원의 보안서약서를 통하여 사람에 의한 정보 유출을 사전에 방지하고 개인정보보호 정책에 대한 이행사항 및 직원의 준수 여부를 감사하기 위한 내부 절차를 마련하고 있습니다.</div>
                </div>
              </div>
            </div>


            <div className="mb-7">
              <div className="h4 mb-3">제8조. 쿠키(Cookie)에 의한 개인정보 자동 수집</div>
              <div className="mb-3">1. K-택배은 최적의 서비스를 제공하기 위해 다음과 같이 개인정보 자동 수집 장치를 설치,  운영하고 있습니다.  (주)K-택배은 고객님의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’등을 운용합니다.쿠키란 K-택배의 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로, 고객님의 컴퓨터 하드디스크에 저장됩니다. 쿠키가 저장된 이후 다시 웹사이트를 방문할 경우 방문한 서비스 정보, 서비스 접속 시간 및 빈도, 서비스 이용 과정에서 생성된 정보를 분석하여 활용할 수 있습니다. (주)K-택배은 다음과 같은 목적을 위해 쿠키를 사용합니다.</div>
              <div className="mb-3">2. 쿠키 등 사용 목적고객이 웹사이트에 방문할 경우 웹사이트 서버는 고객의 디바이스에 저장되어 있는 쿠키의 내용을 읽어 고객의 환경설정을 유지하고 맞춤화 된 서비스를 제공하게 됩니다. 쿠키는 고객이 웹사이트를 방문할 때, 웹사이트 사용을 설정한대로 접속하고 편리하게 사용할 수 있도록 돕습니다. 또한, 고객의 웹사이트 방문 기록, 이용 형태 등을 통해서 최적화된 서비스 제공 및 보안 지원을 위해 활용됩니다. 쿠키는 브라우저의 종료 시 또는 로그아웃 시 만료됩니다.</div>
              <div className="">3. 쿠키 설정 거부 방법고객님은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, 고객님께서 쿠키 설치를 거부하였을 경우 웹사이트 이용이 불편해지며, 서비스 제공에 어려움이 있을 수 있습니다.</div>
              <div className="ms-3 mb-3">
                <div className="">가. Internet Explorer의 경우</div>
                <div className="">웹 브라우저 상단의 도구 메뉴&gt;인터넷 옵션&gt;개인정보&gt;설정</div>
                <div className="">나. Chrome인 경우</div>
                <div className="">웹 브라우저 우측의 설정 메뉴&gt;화면 하단의 고급 설정 표시&gt;개인정보의 콘텐츠 설정 버튼&gt;쿠키</div>
              </div>
            </div>
            <div className="mb-7">
              <div className="h4 mb-3">제9조.  링크 사이트</div>
              <p>1. K-택배은 고객님께 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다.</p>
              <p>2. 이 경우 K-택배은 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다.</p>
              <p>3. K-택배이 포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보보호정책은 K-택배과 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.</p>
            </div>
          </div>
        </div >
      </div >
    </main >
  )
}



export default Policy
