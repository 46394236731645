import React, { useEffect, useState } from 'react'
import OrderSummary from './OrderSummary';
import OrderItemSnippet from './OrderItemSnippet';
import { useRecoilState } from 'recoil';
import { createOrderState } from '../../../Store/OrderStore';
import { useForm, FormProvider } from "react-hook-form";
import { useNotification } from "../../../Core/Context/NotificationContext";

const orderItemModel =
{
  OrderId: "0",
  OrderItemId: "0",
  ItemTypeCode: "",
  ItemTypeName: "",
  ItemName: "",
  ItemQuantity: "1",
  ItemUrl: "",
  ItemAmount: "",
  DeliveryCompany: "",
  TrackingNumber: ""
}

const OrderItems = ({ setOrder }) => {

  const reactHookFormMethod = useForm();

  const { setNotifiications } = useNotification();
  const { formState: { isSubmitted } } = reactHookFormMethod;

  //const { register, handleSubmit, formState: { errors, isSubmitted } } = useForm();

  const [orderInfo, setOrderInfo] = useRecoilState(createOrderState);

  const [finishStatus, setfinishStatus] = useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      window.history.pushState(null, null, window.location.pathname);
      console.log('back');
      setfinishStatus(false)
      setBack();
    }
  }

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setBack = () => {
    window.scroll({ top: 0, behavior: 'smooth' })
    setOrderInfo(p => {
      return {
        ...p,
        CurrentStep: 2
      }
    });
  }

  const addItem = () => {
    const newItem = [...orderInfo.OrderItems, orderItemModel];
    setOrderInfo(p => {
      return {
        ...p,
        OrderItems: newItem
      }
    });
  }


  const showError = () => {
    setNotifiications(
      "warning",
      "상품정보를 모두 입력해 주세요.",
      "",
      3000,
      null
    );
  }


  return (
    <>
      <FormProvider {...reactHookFormMethod} >
        <form className={` ${isSubmitted ? "was-validated" : ""} `}
          onSubmit={reactHookFormMethod.handleSubmit(setOrder, showError)}>

          <div className="row">
            <div className="col-lg-8 mb-7 mb-lg-0">
              <h4 className="mb-3">상품 정보 입력</h4>
              <hr className="my-4" />
              {orderInfo?.OrderItems.map((element, index) => (
                <OrderItemSnippet key={index}
                  index={index}
                  item={element}
                />
              ))}


              <button type="button" className="btn btn-soft-primary mt-1 btn-sm" onClick={() => addItem()}><i className="bi-plus-circle  me-1"></i>상품추가</button>
              {/* <div className="js-create-field form-link mb-4 input-link"
                onClick={() => addItem()}
              >
                <i className="bi-plus-circle  text-danger me-1"></i>상품추가
              </div> */}
              <div className="row align-items-center">
                <div className="col-sm-6 order-sm-1 mb-3 mb-sm-0">
                  <div className="d-grid">

                  </div>
                </div>

                <div className="col-sm text-center text-sm-start mt-4" >
                  <button type="button" className="btn btn-ghost-secondary" onClick={() => setBack()}>
                    <i className="bi-chevron-left small ms-1"></i> 이전으로
                  </button>
                </div>
              </div>
            </div>

            <OrderSummary />

          </div>

        </form>
      </FormProvider>
    </>
  )
}


export default OrderItems
