import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { NavLink } from "react-router-dom";
import { useNotification } from "../../../Core/Context/NotificationContext";
import AwaitButton from "../../../Core/UI/AwaitButton/AwaitButton";
import { Dialog } from "primereact/dialog";
import Delayed from "../../../Core/Hooks/Delayed";
import * as BoardApi from "../../../Apis/BoardApi";
import * as OrderApi from "../../../Apis/OrderApi";
import { useAuth } from "../../../Core/Context/AuthContext";
import { useCookies } from "react-cookie";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";

const Home = (props) => {
  const cookieName = "kaircargopopup";

  const [cookies, setCookie] = useCookies([cookieName]);
  const { setNotifiications } = useNotification();
  const { AuthToken } = useAuth();

  const [orderNumber, setOrderNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [popupShow, setPopupShow] = useState(false);
  const [popupContents, setPopupContents] = useState();

  const el = useRef(null);
  // Create reference to store the Typed instance itself
  const typed = useRef(null);

  useEffect(() => {
    const options = {
      strings: window.GlobalConfigs.MainBannerContents,
      startDelay: 1200,
      typeSpeed: 70,
      backSpeed: 70,
      backDelay: 500,
      loop: true,
      loopCount: 3,
    };

    // elRef refers to the <span> rendered below
    typed.current = new Typed(el.current, options);

    return () => {
      // Make sure to destroy Typed instance during cleanup
      // to prevent memory leaks
      typed.current.destroy();
    };
  }, []);

  useEffect(() => {
    const getPopup = async () => {
      try {
        const popupResponse = await BoardApi.getPopupArticle();
        console.log("popupResponse", popupResponse);

        if (popupResponse && popupResponse.length > 0) {
          setPopupContents(popupResponse);
          setPopupShow(true);
        }
      } catch (err) {
        console.log("Err:", err);
      }
    };

    if (cookies[cookieName] !== "1") {
      getPopup();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOrderTracking = async (e) => {
    e.preventDefault();

    if (orderNumber === "") {
      console.log("setOrderTracking.orderNumber", orderNumber);
      setNotifiications("warning", "", "주문번호를 입력해 주세요", 3000, null);
    } else {
      setIsLoading(true);

      if (AuthToken) {
        try {
          const orderResponse = await OrderApi.searchOrder(orderNumber);

          if (orderResponse && orderResponse.UserId === AuthToken.user_id) {
            props.history.push("/order/" + orderResponse.OrderId);
          } else {
            props.history.push("/tracking/" + orderNumber);
          }
        } catch (err) {
          console.log("err:", err);
          props.history.push("/tracking/" + orderNumber);
        }
      } else {
        props.history.push("/tracking/" + orderNumber);
      }
    }
  };

  const setPopupCookie = () => {
    let expires = new Date();
    expires.setTime(expires.getTime() * 86400000);

    setCookie(cookieName, 1, { path: "/", expires });

    setPopupShow(false);
  };

  const renderFooter = (name) => {
    return (
      <div>
        <div className=" ">
          <input
            type="checkbox"
            id="formCheck12"
            className="form-check-input"
            onChange={(e) => setPopupCookie()}
          />
          <label className="form-check-label" htmlFor="formCheck12">
            오늘 하루 보이지 않기
          </label>
        </div>
      </div>
    );
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    autoplay: true,
    pauseOnHover: true,
    arrows: false,
  };

  return (
    <>
      <Delayed waitBeforeShow="500">
        <Dialog
          header="K택배에서 알려드립니다"
          showHeader={true}
          visible={popupShow}
          position="top"
          className="main-popup-dialog"
          footer={renderFooter()}
          modal={false}
          onHide={() => setPopupShow(false)}
        >
          <Slider {...sliderSettings}>
            {popupContents?.map((item, i) => (
              <div
                key={i}
                style={{ minHeight: 100 }}
                className="board-contents ql-editor"
                dangerouslySetInnerHTML={{ __html: item.Contents }}
              ></div>
            ))}
          </Slider>
        </Dialog>
      </Delayed>
      <main id="content" role="main">
        <div
          className="position-relative overflow-hidden content-space-t-md-3 bg-dark"
          style={{
            backgroundImage:
              "url(/assets/svg/components/wave-pattern-light.svg)",
          }}
        >
          <div className="container">
            <div className="position-relative content-space-t-2 content-space-t-md-0 content-space-t-lg-2 content-space-b-0 content-space-b-md-2 content-space-b-xl-0">
              <div className="col-md-6 position-md-absolute end-0 top-0 content-space-b-1">
                <img
                  className="img-fluid-mobile rounded"
                  src="/assets/img/main/main-promo-3.jpg"
                  alt="find"
                />
                {/* <div
                  className="position-absolute bottom-0 end-0 zi-n1 mb-n4 me-n7 desktop-only"
                  style={{ width: "12rem" }}
                >
                  <img
                    className="img-fluid"
                    src="/assets/svg/components/dots-lg.svg"
                    alt="dot-background"
                  />
                </div> */}
              </div>
              <div className="row position-relative zi-2 mt-md-n5">
                <div className="col-md-6 mb-7 mb-md-0">
                  <div className="w-md-75 mb-3">
                    <h1 className="display-6 text-white">
                      <div>K택배와 함께하세요!</div>
                      <div className="text-warning ">
                        <span ref={el} className="text-highlight-warning" />
                      </div>
                    </h1>
                  </div>

                  <div
                    className=" desktop-only"
                    style={{ marginBottom: "0.7rem" }}
                  >
                    <div className="row">
                      <div className="col-6" style={{marginLeft:'auto', marginRight:'auto'}}>
                        <img
                          className="img-fluid "
                          src="/assets/img/logo/logo-400.png"
                          alt="logo"
                        />
                      </div>
                      
                      <div className="col-6" style={{paddingRight:'3em'}}>
                      <div className="col main-desktop-promo">
                        <div className="d-grid " style={{ height: "70px" }}>
                          <a
                            className="btn btn-primary btn-lg btn-transition"
                            href="/createorder"
                            style={{ fontSize: "1.4rem" }}
                          >
                            배송대행 신청하기
                          </a>
                        </div>
                      </div>
                        <div className="col main-desktop-promo">
                          <div className="d-grid " style={{ height: "70px" }}>
                            <a
                              className="btn btn-primary btn-lg btn-transition"
                              href="/createpurchaseorder"
                              style={{ fontSize: "1.4rem" }}
                            >
                              구매대행 신청하기
                            </a>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>

                  <div className="mb-7 mobile-only">
                    <div className="row ">
                      <div className="col-6" style={{display:'flex'}}>
                        <img
                          className="img-fluid "
                          src="/assets/img/logo/logo-400.png"
                          alt="logo"
                        />
                      </div>
                      <div className="col-6">
                        <div className="mb-5  pt-3">
                          <div className="row">
                            <div className="col-auto">
                              <a
                                href="https://pf.kakao.com/_xmIExbb"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0">
                                    <div className="svg-icon svg-icon-sm text-primary">
                                      <img
                                        src="/assets/img/sns/ico_kakao.png"
                                        al="kakao"
                                        style={{ width: "50px" }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex-grow-1 ms-2 text-white-70">
                                    <span className="small me-1">
                                      카카오톡 상담하기
                                    </span>
                                    <div className="link small">ID: K택배</div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <a
                          className="col-12 btn btn-primary btn-lg btn-transition"
                          href="/createorder"
                          style={{marginBottom: '1em'}}
                        >
                          배송대행 신청하기
                        </a>
                        <a
                          className="col-12 btn btn-primary btn-lg btn-transition"
                          href="/createpurchaseorder"
                        >
                          구매대행 신청하기
                        </a>
                      </div>
                    </div>
                  </div>

                  <form onSubmit={(e) => setOrderTracking(e)}>
                    <div className="input-card mb-7">
                      <div className="input-card-form">
                        <label
                          htmlFor="searchLocationForm"
                          className="form-label visually-hidden"
                        >
                          Search location
                        </label>
                        <div className="input-group input-group-merge">
                          <span className="input-group-prepend input-group-text">
                            <i className="bi-search"></i>
                          </span>
                          <input
                            type="number"
                            className="form-control form-control-lg"
                            id="searchLocationForm"
                            placeholder="주문번호로 배송 상태를 확인하세요."
                            defaultValue={orderNumber}
                            onChange={(e) => setOrderNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      <AwaitButton
                        type="submit"
                        className="btn btn-primary btn-lg"
                        loading={isLoading}
                      >
                        Search
                      </AwaitButton>
                      {/* <button type="submit" className="btn btn-primary btn-lg" >Search</button> */}
                    </div>
                  </form>

                  <div className="mb-7 mobile-only">
                  
                  <div id="home-card-carousel" class="carousel slide"  data-bs-ride="carousel">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <div className="card card-bordered">
                          <div className="card-body" style={{ padding: "1rem 1rem" }}>
                            <h4 className="card-title">한국 배송대행지</h4>
                            <p>
                              한국 <i className="bi bi-arrow-right-short"></i>{" "}
                              뉴질랜드로 배송대행
                            </p>
                            <span className="d-block small mb-1 text-dark">
                              서울시 강서구 양천로 400-12 더리브골드타워 1002
                              (07573)
                            </span>
                            <span className="d-block small mb-1 text-dark">
                              고객님 성함(K택배)
                              <i className="bi-telephone-fill me-1 ms-2"></i>{" "}
                              010-9392-8097
                            </span>
                          </div>
                        </div>
                      </div>


                      <div class="carousel-item">
                        <div className="card card-bordered">
                            <div className="card-body" style={{ padding: "1rem 1rem" }}>
                              <h4 className="card-title">뉴질랜드 배송대행지</h4>
                              <p>
                                뉴질랜드 <i className="bi bi-arrow-right-short"></i>{" "}
                                한국으로 배송대행
                              </p>
                              <span className="d-block small mb-1 text-dark">
                              45G Arrenway Drive Rosedale, Auckland, New Zealand
                               
                              </span>
                              <span className="d-block small mb-1 text-dark">
                              고객님 영문명 예) Jaeho Lee
                                <i className="bi-telephone-fill me-1 ms-2"></i>{" "}
                                021 883 671
                              </span>
                            </div>
                          </div>

                      </div>
                    </div>
                  </div>







                    
                  </div>
                  {/* <p className="form-text small">정확한 주문번호를 입력해 주세요</p> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-10 position-absolute top-0 start-0 zi-n1 gradient-y-three-sm-primary h-100"
            style={{ backgroundSize: "calc(1000px + (100vw - 1000px) / 2)" }}
          ></div>
        </div>

        <div className="container content-space-t-0 content-space-b-1 content-space-lg-1">
          <div className="w-md-75 w-lg-70 text-center mx-md-auto mb-5 mb-md-9 mt-5">
            <h2 className="h1">K택배 이용하기</h2>
            <p>
              고객님의 중요한 서류, 패키지 또는 화물 등을 안전하고 신속하게
              배송해 드리겠습니다.
            </p>
          </div>

          <div className="row gx-3 mb-5 mb-md-9">
            <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
              <NavLink
                exact
                to="/notice/14"
                className="card card-transition h-100 "
              >
                <img
                  className="card-img-top"
                  src="/assets/img/main/main-mileage.png"
                  alt="mileage"
                />
                <div className="card-body">
                  <span className="card-subtitle text-primary">
                    K-택배 마일리지
                  </span>
                  <h5 className="card-text lh-base">
                    마일리지 포인트 시스템을 알아보세요
                  </h5>
                </div>
              </NavLink>
            </div>

            <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
              <NavLink
                exact
                to="/deliveryfee"
                className="card card-transition h-100 "
              >
                <img
                  className="card-img-top"
                  src="/assets/img/main/main02.png"
                  alt=" Description"
                />
                <div className="card-body">
                  <span className="card-subtitle text-primary">예상견적</span>
                  <h5 className="card-text lh-base">
                    배송 비용을 미리 확인해 보시고 최적의 조건을 찾아 보세요
                  </h5>
                </div>
              </NavLink>
            </div>

            <div className="col-sm-6 col-lg-3 mb-3 mb-sm-0">
              <NavLink
                exact
                to="/guide"
                className="card card-transition h-100 "
              >
                <img
                  className="card-img-top"
                  src="/assets/img/main/main03.png"
                  alt=" Description"
                />
                <div className="card-body">
                  <span className="card-subtitle text-primary">이용안내</span>
                  <h5 className="card-text lh-base">
                    서비스 이용이 처음이신가요?
                  </h5>
                  <h5 className="card-text lh-base">
                    이용 방법을 확인해 보세요
                  </h5>
                </div>
              </NavLink>
            </div>

            <div className="col-sm-6 col-lg-3">
              <NavLink
                exact
                to="/flight"
                className="card card-transition h-100 "
              >
                <img
                  className="card-img-top"
                  src="/assets/img/main/main04.png"
                  alt=" Description"
                />
                <div className="card-body">
                  <span className="card-subtitle text-primary">
                    항공 스케줄
                  </span>
                  <h5 className="card-text lh-base">
                    항공 정기 스케줄을 확인해 보세요
                  </h5>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="position-relative">
          <div className="container content-space-lg-3">
            <div className="row align-items-center">
              <div className="col-12 col-lg-9 mb-7 mb-md-0">
                <div className="w-md-65 mb-7">
                  <h2 className="h1">한국, 뉴질랜드 항공 운송서비스 </h2>
                  <p>
                    K-Aircargo는 오클랜드를 기반으로 뉴질랜드와 한국 양국간 항공
                    특송 서비스를 제공하고, 더 나아가 호주 및 미주 지역으로도
                    시세를 확장할 예정 입니다.
                  </p>
                </div>

                <div className="row">
                  <div className="col-md-4 mb-3 mb-md-0">
                    <div className="card h-100">
                      <div className="card-body">
                        <span className="svg-icon text-primary mb-3">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.3"
                              d="M5 8.04999L11.8 11.95V19.85L5 15.85V8.04999Z"
                              fill="#035A4B"
                            ></path>
                            <path
                              d="M20.1 6.65L12.3 2.15C12 1.95 11.6 1.95 11.3 2.15L3.5 6.65C3.2 6.85 3 7.15 3 7.45V16.45C3 16.75 3.2 17.15 3.5 17.25L11.3 21.75C11.5 21.85 11.6 21.85 11.8 21.85C12 21.85 12.1 21.85 12.3 21.75L20.1 17.25C20.4 17.05 20.6 16.75 20.6 16.45V7.45C20.6 7.15 20.4 6.75 20.1 6.65ZM5 15.85V7.95L11.8 4.05L18.6 7.95L11.8 11.95V19.85L5 15.85Z"
                              fill="#035A4B"
                            ></path>
                          </svg>
                        </span>
                        <h4 className="card-title">배송/통관 안내</h4>
                        <p className="card-text">
                          발송전 배송 불가 상품을 반드시 확인해 주시기 바랍니다
                        </p>
                        <NavLink exact to="/packageinfo" className="card-link ">
                          배송/통관 안내 보기{" "}
                          <i className="bi-chevron-right small ms-1"></i>
                        </NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3 mb-md-0">
                    <div className="card h-100">
                      <div className="card-body">
                        <span className="svg-icon text-primary mb-3">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z"
                              fill="#035A4B"
                            ></path>
                            <path
                              opacity="0.3"
                              d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z"
                              fill="#035A4B"
                            ></path>
                          </svg>
                        </span>
                        <h4 className="card-title">배대지주소, 연락처</h4>
                        <p className="card-text">
                          고객님의 배송대행지 주소를 확인해 주세요
                        </p>
                        <NavLink exact to="/warehouse" className="card-link ">
                          배대지 주소 확인하기{" "}
                          <i className="bi-chevron-right small ms-1"></i>
                        </NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="card h-100">
                      <div className="card-body">
                        <span className="svg-icon text-primary mb-3">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V2.40002C0 3.00002 0.4 3.40002 1 3.40002H12C12.6 3.40002 13 3.00002 13 2.40002V1.40002C13 0.800024 12.6 0.400024 12 0.400024Z"
                              fill="#035A4B"
                            ></path>
                            <path
                              opacity="0.3"
                              d="M15 8.40002H1C0.4 8.40002 0 8.00002 0 7.40002C0 6.80002 0.4 6.40002 1 6.40002H15C15.6 6.40002 16 6.80002 16 7.40002C16 8.00002 15.6 8.40002 15 8.40002ZM16 12.4C16 11.8 15.6 11.4 15 11.4H1C0.4 11.4 0 11.8 0 12.4C0 13 0.4 13.4 1 13.4H15C15.6 13.4 16 13 16 12.4ZM12 17.4C12 16.8 11.6 16.4 11 16.4H1C0.4 16.4 0 16.8 0 17.4C0 18 0.4 18.4 1 18.4H11C11.6 18.4 12 18 12 17.4Z"
                              fill="#035A4B"
                            ></path>
                          </svg>
                        </span>
                        <h4 className="card-title">공지사항</h4>
                        <p className="card-text">
                          K택배에서 제공하는 최신 정보를 확인해 보세요
                        </p>
                        <NavLink exact to="/notice" className="card-link ">
                          공지사항 보기{" "}
                          <i className="fas bi-chevron-right small ms-1"></i>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="banner-half-middle-x bg-img-start d-none d-md-block"
            style={{
              backgroundImage:
                "url(/assets/img/others/pexels-liza-summer-900.jpg)",
            }}
          ></div>
        </div>

        <div className="text-center">
          <div className="card card-info-link card-sm"></div>
        </div>
      </main>
    </>
  );
};

export default Home;
