import React from 'react'
import { useRecoilState } from 'recoil';
import { createOrderState } from '../../../Store/OrderStore';
import OrderLocationNZ from './OrderLocationNZ';

const OrderLocation = props => {

  const [orderInfo, setOrderInfo] = useRecoilState(createOrderState);

  const setOrderLocation = location => {

    setOrderInfo(p => {
      return {
        ...p,
        CurrentStep: 2,
        DeliveryLocationCode: location,
        AmountUnit: location === "2" ? "$" : "₩",
        ReceiverName: orderInfo.UserInfo.UserName,
        ReceiverEmail: orderInfo.UserInfo.EmailAddress,
        ReceiverPhone: orderInfo.UserInfo.PhoneNumber,
        ReceiverMobile: orderInfo.UserInfo.MobileNumber,
        ReceiverAddress1: (location === "2" ? orderInfo.UserInfo.UserAddressKR1 : orderInfo.UserInfo.UserAddressNZ1),
        ReceiverAddress2: (location === "2" ? orderInfo.UserInfo.UserAddressKR2 : orderInfo.UserInfo.UserAddressNZ2),
        ReceiverZipcode: (location === "2" ? orderInfo.UserInfo.ZipCodeKR : orderInfo.UserInfo.ZipCodeNZ),
        PersonalCustomCode: orderInfo.UserInfo.PersonalCustomCode,
      }
    });
  }

  const setTempLocationNZ = () => {
    setOrderInfo(p => {
      return {
        ...p,
        CurrentStep: 1.5,
        AmountUnit: "₩",
        ReceiverName: orderInfo.UserInfo.UserName,
        ReceiverEmail: orderInfo.UserInfo.EmailAddress,
        ReceiverPhone: orderInfo.UserInfo.PhoneNumber,
        ReceiverMobile: orderInfo.UserInfo.MobileNumber,
        ReceiverAddress1: orderInfo.UserInfo.UserAddressNZ1,
        ReceiverAddress2: orderInfo.UserInfo.UserAddressNZ2,
        ReceiverZipcode: orderInfo.UserInfo.ZipCodeNZ,
        PersonalCustomCode: orderInfo.UserInfo.PersonalCustomCode,
      }
    });

  }




  return (
    <>
      <div className="w-md-75 w-lg-70 text-center mx-md-auto mb-5 mb-md-9">
        <h2>배송 국가를 선택해 주세요</h2>
        <p></p>
      </div>

      <div className="text-center">
        {/* <h4>뉴질랜드</h4>
        <p></p> */}
        <div className="row gx-2 justify-content-md-center ">
          <div className="col-sm-6 col-lg-4 mb-3 input-link"
            onClick={() => setTempLocationNZ()}
          >
            <div className="card card-stretched-vertical card-transition shadow-none bg-img-start gradient-y-overlay-lg-dark" href="../demo-real-estate/property-grid.html"
              style={{ backgroundImage: "url(/assets/img/location/img01.jpg)", minHeight: "15rem" }}>
              <div className="card-body card-body-custom ">
                <div className="card-footer">
                  <h3 className="text-white mb-0">뉴질랜드</h3>
                  <span className="d-block text-white">대한민국 <i className="bi bi-arrow-right-short"></i> 뉴질랜드</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-lg-4 mb-3 input-link"
            onClick={() => setOrderLocation("2")}
          >
            <div className="card card-stretched-vertical card-transition shadow-none bg-img-start gradient-y-overlay-lg-dark" href="../demo-real-estate/property-grid.html"
              style={{ backgroundImage: "url(/assets/img/location/img02.jpg)", minHeight: "15rem" }}>
              <div className="card-body card-body-custom ">
                <div className="card-footer">
                  <h3 className="text-white mb-0">대한민국</h3>
                  <span className="d-block text-white">뉴질랜드 <i className="bi bi-arrow-right-short"></i> 대한민국</span>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-sm-6 col-lg-4 mb-3 input-link"
            onClick={() => setOrderLocation("3")}
          >
            <div className="card card-stretched-vertical card-transition shadow-none bg-img-start gradient-y-overlay-lg-dark" href="../demo-real-estate/property-grid.html"
              style={{ backgroundImage: "url(/assets/img/location/img01.jpg)", minHeight: "15rem" }}>
              <div className="card-body card-body-custom ">
                <div className="card-footer">
                  <h3 className="text-white mb-0">뉴질랜드 - 해상</h3>
                  <span className="d-block text-white">대한민국 <i className="bi bi-arrow-right-short"></i> 뉴질랜드</span>
                </div>
              </div>
            </div>
          </div> */}

        </div>

        {/* <div className="row gx-2 justify-content-md-center "> */}
{/* <p></p>
<h4>대한민국</h4>
<p></p> */}

{/* </div> */}



        
      </div>
    </>
  )
}


export default OrderLocation
