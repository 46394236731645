import { atom, } from 'recoil';


export const createOrderState = atom({
  key: "createOrderState",
  default: {
    CurrentStep: 1,
    UserId: 0,
    DeliveryLocationCode: 0,
    IsReceiverOwn: true,
    ReceiverName: "",
    ReceiverEmail: "",
    ReceiverPhone: "",
    ReceiverMobile: "",
    ReceiverAddress1: "",
    ReceiverAddress2: "",
    ReceiverZipcode: "",
    PickupTypeCode: 0,
    PersonalCustomCode: "",
    OrderItems: [
      {
        OrderId: 0,
        OrderItemId: 0,
        ItemTypeCode: 0,
        ItemTypeName: "",
        ItemName: "",
        ItemQuantity: "1",
        ItemUrl: "",
        ItemAmount: 0,
        DeliveryCompany: "",
        TrackingNumber: ""
      }
    ],
    OrderItemType: [],
    PurchaseOrderItems: [
      {
        OrderId: 0,
        PurchaseOrderItemId: 0,
        ItemTypeCode: 0,
        ItemTypeName: "",
        ItemName: "",
        ItemQuantity: "1",
        ItemUrl: "",
        ItemAmount: 0,
        DeliveryCompany: "",
        TrackingNumber: "",
        OrderNote: ""

      }
    ],
    IsPurchaseOrder: false
  },
});
