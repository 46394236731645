import axiosBase from "./Axios/AxiosCommon";

export const getOrderItemType = () => {
  return axiosBase
    .get("/common/code/orderItemType")
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}


export const createOrder = (orderInfo) => {
  return axiosBase
    .post("/customer/order", orderInfo)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const createPurchaseOrder = (orderInfo) => {
  return axiosBase
    .post("/customer/order/purchase", orderInfo)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const updateOrder = (orderId, orderInfo) => {
  return axiosBase
    .put("/customer/order/" + orderId, orderInfo)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const updatePurchaseOrder = (orderId, orderInfo) => {
  return axiosBase
    .put("/customer/order/purchase/" + orderId, orderInfo)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}


export const getOrder = (orderId) => {
  return axiosBase
    .get("/customer/order/" + orderId)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const getPurchaseOrder = (orderId) => {
  return axiosBase
    .get("/customer/order/purchase/" + orderId)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}


export const searchOrder = (orderNumber) => {
  return axiosBase
    .get("/customer/order/search/" + orderNumber)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const getOrderList = (startDate, endDate, locationCode, pageNo, pageSize) => {
  let params = { startDate: startDate, endDate: endDate, locationCode: locationCode };

  if (pageNo !== "") {
    params = { ...params, pageNo: pageNo };
  }

  if (pageSize !== "") {
    params = { ...params, pageSize: pageSize };
  }
  return axiosBase
    .get("/customer/order", { params: params, })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const getPurchaseOrderList = (startDate, endDate, locationCode, pageNo, pageSize) => {
  let params = { startDate: startDate, endDate: endDate, locationCode: locationCode };

  if (pageNo !== "") {
    params = { ...params, pageNo: pageNo };
  }

  if (pageSize !== "") {
    params = { ...params, pageSize: pageSize };
  }
  return axiosBase
    .get("/customer/order/purchase", { params: params, })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const getQuote = (weightInfo) => {
  return axiosBase
    .post("/customer/order/search/quote", weightInfo)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}