import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as AccountApi from "../../Apis/AccountApi";
import { useAuth } from "../../Core/Context/AuthContext";
import { useNotification } from "../../Core/Context/NotificationContext";
import { useHistory, NavLink } from "react-router-dom";
import AwaitButton from '../../Core/UI/AwaitButton/AwaitButton';

const Login = props => {

  const history = useHistory();
  //const { caller: callApi, data: returnData, loading: apiIsLoading, error: apiError } = AccountApi.Login();
  const { setNotifiications } = useNotification();
  const { register, handleSubmit, formState: { errors, isSubmitted } } = useForm();

  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { setAuthToken } = useAuth();

  useEffect(() => {
    //console.log('setToken(null)');
    setAuthToken(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {

    setIsLoading(true);

    try {
      const tokenResponse = await AccountApi.Login(data.txtEmail, data.txtPassword);

      if (tokenResponse) {
        const newToken = { ...tokenResponse, "expires_in": Date.now() / 1000 + Number(tokenResponse.expires_in) };
        await setAuthToken(newToken);

        const referer = props.location.state?.referer ?? "/";
        // const referer = "https://kaircargo.com/orderlist?searchDate=30&locationTab=0&pageNo=1" ?? "/";
        setIsLoading(false);
        history.push(referer);

      }
    } catch (err) {
      setIsLoading(false);
      console.log("err=====", err);

      setNotifiications(
        "warning",
        "이메일, 비밀번호를 확인해 주세요.",
        "로그인 정보를 찾을수 없습니다.",
        3000,
        null
      );

    }
  };

  return (
    <main id="content" role="main" className="bg-light">
      <div className="container content-space-3 content-space-t-lg-4 content-space-b-lg-3">
        <div className="flex-grow-1 mx-auto" style={{ maxWidth: "28rem" }}>
          <div className="text-center mb-5 mb-md-7">
            <h1 className="h2">Welcome back</h1>
            <p>Login to manage your account.</p>
          </div>

          <form
            className={` ${isSubmitted ? "was-validated" : ""} `}

            onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">

              <label className="form-label" htmlFor="signupSimpleLoginEmail">이메일</label>
              <div className={`input-group input-group-merge ${errors.txtEmail ? "is-invalid" : "is-valid"} `} >
                <input
                  type="email"
                  className="form-control form-control-lg "
                  placeholder="email@yoursite.com"
                  aria-invalid={errors.txtEmail ? "true" : "false"}
                  {...register("txtEmail",
                    {
                      required: true,
                      pattern: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    })
                  }
                />
              </div>
              {errors.txtEmail && <span className="invalid-feedback">이메일 주소를 입력해 주세요</span>}

            </div>

            <div className="mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <label className="form-label" htmlFor="signupSimpleLoginPassword">비밀번호</label>

                <div className="form-label-link input-link-blue">
                  <NavLink exact to="/forgotpassword" className="link input-link-blue">
                    비밀번호찾기
                  </NavLink>
                </div>
              </div>

              <div className={`input-group input-group-merge ${errors.txtPassword ? "is-invalid" : "is-valid"}`} >
                <input
                  type={passwordShown ? "text" : "password"}
                  className="js-toggle-password form-control form-control-lg "
                  placeholder="8+ characters required"
                  autoComplete="off"
                  {...register("txtPassword", { required: true, minLength: 8 })}

                />
                <i id="changePassIcon" className={`input-link input-group-append input-group-text ${passwordShown ? "bi-eye" : "bi-eye-slash"}`}
                  onClick={e => setPasswordShown(!passwordShown)}
                ></i>

              </div>
              {errors.txtPassword && <span className="invalid-feedback">비밀번호를 입력해 주세요</span>}
            </div>

            <div className="d-grid mb-3">
              <AwaitButton type="submit" className="btn btn-primary btn-lg" loading={isLoading} >Log in</AwaitButton>
            </div>

            <div className="text-center">
              <p>아직 회원가입을 안하셨나요?</p>
              <NavLink exact to="/signup" className="link input-link-blue">
                회원가입 하기
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </main>
  )
}


export default Login
