import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../../Core/Context/AuthContext";
import { isMobile } from "react-device-detect";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

const Header = (props) => {
  const { AuthToken } = useAuth();

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    autoplay: true,
    pauseOnHover: true,
    arrows: false,
  };

  const setMenuLink = (className, path, text) => {
    if (isMobile) {
      return (
        <a className={className} href={path}>
          {text}
        </a>
      );
    }

    return (
      <NavLink className={className} to={path} exact>
        {text}
      </NavLink>
    );
  };

  return (
    <header
      id="header"
      className="navbar navbar-expand-lg navbar-absolute-top navbar-end navbar-light bg-white navbar-show-hide navbar-show-hide"
      data-hs-header-options='{
      "fixMoment": 500,
      "fixEffect": "slide"
    }'
    >
      <div
        className="navbar-topbar bg-dark"
        style={{
          backgroundImage: "url(/assets/svg/components/wave-pattern-light.svg)",
        }}
      >
        <div className="pt-2">
          <nav className="js-mega-menu navbar-nav-wrap hs-menu-initialized hs-menu-horizontal">
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <div className="" style={{marginLeft: 'auto', marginRight: 'auto'}}>
                <ul className="navbar-nav">
                  <li className="nav-item" style={{ maxWidth: "1300px", marginRight:'1.5rem' }}>
                    <div className="row">
                      <div className="col-6">
                        <div className="flex-grow-1 ms-3">
                          <h6 className="mb-1">
                            <a className="text-white" href="#">
                              한국 배송대행지
                            </a>
                          </h6>
                          <span className="d-block small mb-1 text-white-70">
                            서울시 강서구 양천로 400-12 더리브골드타워 1002
                            (07573)
                          </span>
                          <span className="d-block small mb-2 text-white-70">
                            고객님 성함(K택배)
                            <i className="bi-telephone-fill me-1 ms-2"></i>{" "}
                            010-9392-8097
                          </span>
                        </div>
                      </div>


                      <div className="col-6">
                        <div className="flex-grow-1 ms-3">
                          <h6 className="mb-1">
                            <a className="text-white" href="#">
                              뉴질랜드 배송대행지
                            </a>
                          </h6>
                          <span className="d-block small mb-1 text-white-70">
                          99 Killybegs Drive, Pinehill, Auckland, New Zealand
                          </span>
                          <span className="d-block small mb-1 text-white-70">
                            고객님 영문명 예) Jaeho Lee
                            <i className="bi-telephone-fill me-1 ms-2"></i> 021 883
                            671
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://pf.kakao.com/_xmIExbb"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <div className="svg-icon svg-icon-sm text-primary">
                            <img
                              src="/assets/img/sns/ico_kakao.png"
                              al="kakao"
                              style={{ width: "50px" }}
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1 ms-2 text-white-70">
                          <span className="small me-1">카카오톡 상담하기</span>
                          <div className="link small">ID: K택배</div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="container">
        <nav className="js-mega-menu navbar-nav-wrap">
          <div className="navbar-brand" aria-label="Front">
            <a href="/">
              <img
                className="navbar-brand-logo"
                src="/assets/img/kaircargo-logo-wide.svg"
                alt="Logo"
              />
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-default">
              <i className="bi-list"></i>
            </span>
            <span className="navbar-toggler-toggled">
              <i className="bi-x"></i>
            </span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <div className="navbar-absolute-top-scroller">
              <ul className="navbar-nav">
                <li className="nav-item">
                  {setMenuLink(
                    "nav-link h6 text-dark",
                    "/createorder",
                    "배송대행신청"
                  )}
                </li>
                <li className="nav-item">
                  {setMenuLink(
                    "nav-link h6 text-dark",
                    "/createpurchaseorder",
                    "구매대행신청"
                  )}
                </li>
                <li className="nav-item">
                  {setMenuLink(
                    "nav-link h6 text-dark",
                    "/deliveryfee",
                    "이용요금"
                  )}
                </li>
                <li className="nav-item">
                  {setMenuLink("nav-link h6 text-dark", "/guide", "이용방법")}
                </li>
                <li className="nav-item">
                  {setMenuLink(
                    "nav-link h6 text-dark",
                    "/packageinfo",
                    "통관안내"
                  )}
                </li>
                <li className="nav-item">
                  {setMenuLink("nav-link h6 text-dark", "/notice", "공지사항")}
                </li>
                <li className="nav-item">
                  {setMenuLink("nav-link h6 text-dark", "/qna", "이용문의")}
                </li>
                <li className="nav-item">
                  {setMenuLink("nav-link h6 text-dark", "/fAQ", "FAQ")}
                </li>
                <li className="nav-item">
                  {setMenuLink("nav-link h6 text-dark", "/orderlist", "My택배")}
                </li>
                {!AuthToken && (
                  <li className="nav-item">
                    <a className="btn btn-primary btn-transition" href="/login">
                      Login
                    </a>
                  </li>
                )}
                {AuthToken && (
                  <li className="nav-item">
                    <a className="btn btn-primary btn-transition" href="/login">
                      Logout
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

// Header.propTypes = {

// }

export default memo(Header);
