import React, { useState, useEffect } from 'react'
import { useSpinner } from "../../Core/Context/SpinnerContext";
import { useNotification } from "../../Core/Context/NotificationContext";
import * as BoardApi from '../../Apis/BoardApi';
import { useHistory } from "react-router-dom";
import { useAuth } from "../../Core/Context/AuthContext";
import { ConfirmDialog } from 'primereact/confirmdialog';
import AwaitButton from '../../Core/UI/AwaitButton/AwaitButton';

const BoardQna = props => {

  const boardId = props.match.params.id;
  const { setSpinner } = useSpinner();
  const { setNotifiications } = useNotification();
  const { AuthToken } = useAuth();
  const history = useHistory();

  const [boardItem, setBoardItem] = useState();
  const [editable, setEditable] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {

    const getBoardInfo = async () => {
      try {
        setSpinner(true);

        const orderResponse = await BoardApi.getBoardQnaArticle(boardId);
        if (orderResponse) {
          console.log('orderResponse', orderResponse);
          if (orderResponse.IsSecret === "True" && AuthToken?.user_id !== orderResponse.UserId) {
            setNotifiications("warning", "비밀글은 작성자만 열람할수 있습니다.", "", 3000, null);
            history.goBack();
          }

          setBoardItem(orderResponse);



          if (orderResponse.Comments.length === 0 && AuthToken?.user_id === orderResponse.UserId) {
            console.log(orderResponse.Comments.length);
            setEditable(true);
          }
        }
        else {
          setNotifiications("warning", "게시물 정보를 찾을수 없습니다.", "", 3000, null);
          history.push('/qna');
        }

      }
      catch (err) {
        console.log('Error:', err);
        if (err.status === 404) {
          setNotifiications("warning", "게시물 정보를 찾을수 없습니다.", "", 3000, null);
          history.push('/qna');
        }
      }

      setSpinner(false);
    }

    const init = setTimeout(() => {
      getBoardInfo();
    }, 500);

    return () => {
      if (init) {
        clearTimeout(init);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setEdit = () => {
    history.push('/qnaedit/' + boardItem.BoardQnaId);

  }

  const setDelete = async () => {
    try {
      setIsDelete(true);

      await BoardApi.deleteBoardQnaArticle(boardItem.BoardQnaId);
      setNotifiications("success", "삭제 되었습니다.", "", 3000, null);
      history.push('/qna');
    }
    catch (err) {
      console.log('Error:', err);
      setIsDelete(false);
    }
  }

  return (
    <>
      <main id="content" role="main" className="bg-light">
        <div className="container  content-space-3 content-space-t-lg-4 content-space-b-lg-3">
          <div className="w-lg-75-disable mx-auto">
            <div className="text-center mb-5 mb-md-7">
              <h1 className="h2">Q&amp;A</h1>
            </div>

            <div className="card card-lg">
              <div className="card-body container-min-400">
                {boardItem &&
                  <div>
                    <h3 className="card-title h3">{boardItem.Title}</h3>

                    <div className="row align-items-sm-center mb-5">
                      <div className="col-sm-7 mb-4 mb-sm-0">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 mt-2">
                            <h5 className="mb-0">
                              <div className="text-dark" >{boardItem.WriterName}</div>
                            </h5>
                            <span className="d-block small mt-1">{boardItem.CreateDate}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="my-3" />
                    <div className="text-dark container-min-200">
                      <div className="board-contents ql-editor"
                        dangerouslySetInnerHTML={{
                          __html: boardItem?.Contents
                        }}>

                      </div>
                    </div>
                    {(boardItem.Comments.length > 0) &&
                      <div>
                        <hr className="my-3" />

                        <h3 className="card-title h3">답변해 드립니다</h3>
                        <div className="row align-items-sm-center mb-5">
                          <div className="col-sm-7 mb-4 mb-sm-0">
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1 mt-2">
                                <h5 className="mb-0">
                                  <div className="text-dark" >{boardItem.Comments[0].WriterName}</div>
                                </h5>
                                <span className="d-block small mt-1">{boardItem.Comments[0].CreateDate}</span>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="text-dark container-min-200">
                          <div className="board-contents ql-editor"
                            dangerouslySetInnerHTML={{
                              __html: boardItem.Comments[0].Comment
                            }}>

                          </div>
                        </div>
                      </div>
                    }
                  </div>
                }
              </div>

            </div>

            <div className="row mt-2">
              <div className="col mb-3 mb-md-0">
                <button type="button" className="btn btn-ghost-secondary" onClick={e => history.goBack()}>
                  <i className="bi-chevron-left small ms-1"></i> 이전으로
                </button>
              </div>
              <div className="col-auto align-self-end mb-2 mt-2">
                {editable &&
                  <div className=" d-flex gap-2">
                    <button className="btn btn-dark btn-sm" onClick={() => setEdit()}>
                      <i className="bi bi-pencil-fill me-1"></i>수정
                    </button>
                    <AwaitButton type="submit" className="btn btn-dark btn-sm" loading={isDelete} onClick={() => setConfirmShow(true)}>
                      <i className="bi bi-trash-fill me-1"></i>삭제
                    </AwaitButton>
                  </div>
                }
              </div>
            </div>


          </div>
        </div>
      </main >
      <ConfirmDialog visible={confirmShow} onHide={() => setConfirmShow(false)} message="게시글을 삭제하시겠습니까?"
        header="Confirmation" icon="pi pi-exclamation-triangle" accept={() => setDelete()} reject={() => setConfirmShow(false)} />
    </>
  )
}



export default BoardQna
