import React from 'react'

const Agreement = props => {
  return (
    <main id="content" role="main">
      <div className="container content-space-3 content-space-lg-4">
        <div className="card card-lg">
          <div className="card-header bg-dark py-sm-10">
            <h1 className="card-title h2 text-white">이용약관</h1>
            <p className="card-text text-white">최종 수정일: October 31, 2021</p>
          </div>

          <div className="card-body">
            <div className="mb-7">

              <div className="h4">1. 운송장 및 송하인의 의무</div>
              <div><p>송하인은 송하인이 아래에 나열된 의무, 법률 등을 준수하지 않아 발생한 모든 손해나 손상에 대하여 전적으로 송하인이 책임지며, K-택배을 면책하고 K-택배에 보상합니다.</p></div>
              <div className="mb-4">
                <div>가.송하인(고객)은 운송장의 각 항을 정확히 기재하여야 합니다.</div>
                <div className="ms-3">
                  <div>① 송하인의 주소, 이름 또는 상호, 전화번호</div>
                  <div>② 수하인의 주소, 이름 또는 상호, 전화번호</div>
                  <div>③ 운송물의 품명, 수량, 물품 가격</div>
                  <div>④ 운송상의 특별한 주의 사항</div>
                </div>
              </div>
              <p>나.송하인이 구두 또는 서면으로 제공한 모든 정보는 정확하고 완전한 것으로 간주합니다.</p>
              <p>다.운송물이 제4조에 따른 취급불가한 물건이 아니어야 하며, 송하인은 운송물을 그 성질, 중량, 용적 등에 따라 운송에 적합하도록 포장하여야 합니다.</p>
              <p>라.송하인은 세관 통관을 위하여 요구하는 문서를 제공하여야 합니다.</p>
              <p>마.송하인은 관련된 모든 세관, 수입, 수출, 정보 보호 법률, 제재, 금수조치 및 기타 관련 법규정을 준수하여야 합니다.</p>
              <p>바.송하인은 수하인 정보를 비롯한 운송, 통관 및 배송을 위하여 필요한 개인 정보(이메일 주소 및 핸드폰 번호 등을 포함함)를 K-택배에 제공함과 관련하여 필요한 모든 동의를 획득하였습니다.</p>
            </div>

            <div className="mb-7">
              <h4>2. 운임의 청구와 유치권</h4>

              <p>가.국제특송 운임은 실제중량과 부피중량 중 큰 것으로 계산 청구되며 정확한 요금 계산을 위해 재계측을 할 수 있습니다.</p>

              <p>나.운송 과정에서 수하인 또는 다른 당사자에게 부과되는 운임, 창고료, 관세, 세금 등을 수하인 또는 다른 당사자가 지불하지 않을 경우, 송하인이 모든 요금을 지불해야 합니다.</p>

              <p>다.수하인의 수취거절, 관부가세 납부거절, 운송화물의 통관불가 품목판정 등 수하인 사유로 인해 초래되는 화물의 반송, 폐기, 보관 등에 추가로 발생되는 제반 요금도 송하인에게 청구할 수 있습니다.</p>

              <p>라.K-택배은 위탁받은 화물에 대한 운임, 창고료, 관세, 세금, 벌과금 또는 기타 제반 요금이 지불될 때까지 화물을 유치하거나 인도를 거절할 수 있습니다.</p>
              <p>마.운송물이 포장 당 50만원을 초과하거나 운송상 특별한 주의를 요하는 것일 때에는 K-택배은 따로 할증 요금을 청구할 수 있습니다.</p>
              <p>바.운임 및 할증 요금은 미리 이 약관의 별표로 제시하고 운송장에 기재합니다.</p>


            </div>

            <div className="mb-7">
              <h4>3. 운송물의 확인</h4>
              <p>K-택배은 화물을 개봉할 의무는 없으나, 필요시 송하인에게 사전 통보 없이 개봉 및 검사를 할 수 있는 권한을 가집니다.</p>
            </div>

            <div className="mb-7">
              <h4>4. 취급 품목 및 서비스의 제한</h4>

              <p>K-택배은 다음의 경우에 운송물의 운송을 거절할 수 있습니다.</p>
              <p>가.운송물 1포장의 가격이 300만원 또는 $2,500을 초과하는 경우</p>
              <p>나.운송물 1포장의 무게가 20Kg을 초과하는 경우(배송 가능여부를 고객센터에 반드시 문의해 주시기 바랍니다.)</p>
              <p>다.3운송물 1포장의 크기가 가로 세로 높이 세변의 합 160cm를 초과하거나, 최장변이 100cm를 초과하는 경우</p>
              <p>라.운송에 적합한 포장이 되지 않은 경우</p>
              <p>마.부패하기 쉬운 운송물, 보온보냉이 필요한 운송물</p>
              <p>바.재생 불가능한 계약서, 원고, 서류 등인 경우</p>
              <p>사.살아있는 동물, 사체, 현금, 카드, 어음, 수표, 유가증권</p>
              <p>아.화약총포류, 폭발물, 인화물질 등 위험한 물건인 경우</p>
              <p>자.마약류, 불법약물, 위조품, 밀수품, 군수품 등 위법한 물건인 경우</p>
              <p>차.국제항공운송협회, 국제민간항공기구, 관련국 정부, 기타 유관기관에서 규정한 위험물, 금지 또는 제한된 품목</p>
              <p>카.송하인이 운송장에 필요한 사항을 기재하지 아니한 경우</p>
              <p>타.운송물의 종류와 수량이 운송장에 기재된 것과 다른 경우</p>
              <p>파.운송이 법령, 사회질서 기타 선량한 풍속에 반하는 경우</p>
              <p>하.운송이 천재지변 기타 불가항력적인 사유로 불가능한 경우</p>
            </div>
            <div className="mb-7">
              <h4>5. 인도할 수 없는 운송물의 처분</h4>
              <p>가.수하인께 배송이 불가능한 경우, 송하인에게 사실을 통지한 후 반송합니다.단, 반송은 통관 규정상 허용되는 경우에 한합니다.</p>
              <p>나.송하인이 반송을 거절하거나 또는 송하인에게 반송할 수 없는 경우, K-택배은 운송물을 처분할 권한을 가집니다.</p>
              <p>다.송하인은 운송물의 반송과 처분 과정에서 발생하는 반송 요금, 관세, 세금, 보관료, 폐기료 등 모든 비용을 지불하여야 합니다.</p>
            </div>
            <div className="mb-7">
              <h4>6. 손해배상</h4>
              <p>가.운송물의 분실이나 파손에 대한 K-택배의 손해배상 책임은 운송물의 배송일 또는 배송예정일로부터 14일 이내에 송하인이 서면으로 통지 하지 않으면 소멸합니다.</p>
              <div>
                <div>나.운송물의 분실 또는 파손에 대한 손해배상은 아래에 따릅니다.</div>
                <div className="ms-3">
                  <div>① K-택배은 자기 또는 운송 위탁을 받은 자, 기타 운송을 위하여 관여된 자가 운송물의 수탁, 인도, 보관 및 운송에 관하여 주의를 태만히 하지 않았음을 증명하지 못하는 한, 운송물의 멸실, 훼손에 인한 손해를 송하인에게 배상합니다.</div>
                  <div>② K-택배의 손해배상은 다음 호에 의합니다.</div>
                  <div className="ms-3">
                    <div>a) 송하인이 운송장에 운송물의 가액을 기재하지 않거나 할증요금을 지급하지 않는 경우 – 손해배상한도액은 50만원</div>
                    <div>b) 송하인이 운송장에 운송물의 가격을 기재한 경우 - 운송물의 가액에 따라 할증요금을 지급하는 경우의 손해배상한도액은 각 운송가액 구간별 운송물의 최고가액.단, 어떠한 경우에도 손해배상금은 US$2,500을 초과하지 않습니다.</div>
                    <div>c) 수선이 가능한 경우 - 실수선 비용(A/S 비용).단, 수선이 가능할 경우에도 수선비는 a) 또는 b)에서 정한 손해배상금을 넘지 않습니다.</div>
                  </div>
                </div>
              </div>

              <p>다.송하인은 실제 손해액을 증명할 책임을 집니다.</p>

              <p>라.K-택배은 국제배송에 관한 여러 변경 가능한 사정 중 K-택배의 귀책사유 없이 발생하는 배송 지연에 대해서는 일체의 책임을 부담하지 않습니다.(항공사 스케줄 변경, 오프로드(off-road)등으로 인한 출발 지연, 세관의 검사/검역절차 지연으로 인한 통관지연 등) K-택배은, K-택배의 귀책사유로 인하여 부당하게 오래 지연된 경우에만 책임을 집니다.</p>
              <p>마.신고 금액을 초과하는 손실의 위험은 송하인이 부담하는 것으로 합니다.송하인은 보험에 가입함으로써 당해 위험을 송하인의 선택으로 보험회사에 이전할 수 있습니다.K-택배은 어떠한 종류의 보험 서비스도 제공하지 않습니다.</p>
              <p>바.K-택배은 운송물의 파손, 지연 등으로 인한 영업손실, 이자, 효용성, 시장성 감소 등 간접 손해에 대해서는 발생가능성의 인지 여부에 관계없이 책임을 지지 않습니다.</p>
              <p>사.본 약관상의 K-택배의 책임 제한 규정은 송하인 또는 수하인이 K-택배에 대하여 채무불이행으로 인한 손해배상 청구하는 경우뿐만 아니라 불법행위로 인한 손해배상 청구의 경우에도 적용됩니다.</p>
            </div>
            <div className="mb-7">
              <h4>7. K-택배의 면책</h4>
              <p>K-택배은 다음과 같이 불가항력적인 사유로 인한 운송물의 분실, 파손 또는 지연 배송에 대해서는 손해배상책임을 지지 않습니다.</p>
              <p>가.천재지변, 기상조건, 항공기의 연착</p>
              <p>나.폭동, 전쟁, 파업, 노동쟁의</p>
              <p>다.화물의 성질, 결함, 특성, 잠재결함</p>
              <p>라.권한을 가진 공공기관(세관, 검역기관 포함)의 행위 또는 이행태만</p>
              <p>마.본 운송약관의 위반, 부적절한 포장, 보안검색, 기재사항의 미흡 등 송, 수하인 또는 동 화물과 관련된 제 3자의 행위 또는 이행태만</p>
            </div>
            <div className="mb-7">
              <h4>8. 준거법</h4>
              <p>본 약관에 따라 또는 본 약관과 관련하여 발생하는 모든 분쟁은 운송물의 발송지 국가(country of origin)의 법원의 비전속적 관할에 따르며 운송물의 발송지 국가의 법률에 의하여 규율 됩니다.</p>
            </div>













          </div>
        </div>
      </div>
    </main>
  )
}


export default Agreement
