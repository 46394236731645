import React, { useState } from 'react'
import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';
import Sidebar from '../Sidebar/Sidebar';
import { useForm } from "react-hook-form";
import * as AccountApi from "../../../Apis/AccountApi";
import { useSpinner } from "../../../Core/Context/SpinnerContext";
import { useNotification } from "../../../Core/Context/NotificationContext";

const UpdatePassword = props => {

  const { setNotifiications } = useNotification();
  const { register, handleSubmit, watch, reset, formState: { errors, isSubmitted } } = useForm();
  const { setSpinner } = useSpinner();

  const [passwordShown, setPasswordShown] = useState(false);

  const passwordWatch = watch("txtNewPassword", "");

  const onSubmit = async (data) => {


    try {
      setSpinner(true);
      const newCustomerPassword = {
        "currentPassword": data.txtCurrentPassword,
        "newPassword": data.txtNewPassword
      };

      await AccountApi.updateCustomerPassword(newCustomerPassword);

      setNotifiications(
        "success",
        "저장 되었습니다.",
        "Success",
        3000,
        null
      );

      reset(null);
    }
    catch (err) {
      console.log('err', err);

      if (err.data?.Contents) {
        const errMessage = err.data?.Contents.map((item) => item.Message);
        setNotifiications(
          "warning",
          errMessage,
          "입력한 정보를 확인해 주세요",
          3000,
          null
        );
      }
    }

    setSpinner(false);
  }

  return (
    <main id="content" role="main" className="bg-light">
      <Breadcrumb
        Current={"비밀번호 변경"}
        Parent={"My Page"}
      />


      <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          <div className="col-lg-9">
            <div className="card">
              <div className="card">
                <div className="card-header border-bottom">
                  <h5 className="card-header-title">비밀번호 변경</h5>
                </div>

                <div className="card-body">
                  <form
                    className={` ${isSubmitted ? "was-validated" : ""} `}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="row mb-4">
                      <label htmlFor="currentPasswordLabel" className="col-sm-3 col-form-label form-label">현재 비밀번호</label>

                      <div className="col-sm-9">
                        <div className={`input-group input-group-merge ${errors.txtCurrentPassword ? "is-invalid" : "is-valid"}`} >
                          <input
                            type={passwordShown ? "text" : "password"}
                            className="js-toggle-password form-control form-control-lg "
                            placeholder="8+ characters required"
                            autoComplete="off"
                            {...register("txtCurrentPassword", { required: true, minLength: 8 })}

                          />
                          <i id="changePassIcon" className={`input-link input-group-append input-group-text ${passwordShown ? "bi-eye" : "bi-eye-slash"}`}
                            onClick={e => setPasswordShown(!passwordShown)}
                          ></i>

                        </div>
                        {errors.txtCurrentPassword && <span className="invalid-feedback">Please enter a valid password.</span>}
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label htmlFor="newPassword" className="col-sm-3 col-form-label form-label">신규 비밀번호</label>

                      <div className="col-sm-9">
                        <div className={`input-group input-group-merge ${errors.txtNewPassword ? "is-invalid" : "is-valid"}`} >
                          <input
                            type={passwordShown ? "text" : "password"}
                            className="js-toggle-password form-control form-control-lg "
                            placeholder="8+ characters required"
                            autoComplete="off"
                            {...register("txtNewPassword", { required: true, minLength: 8 })}

                          />
                          <i id="changePassIcon" className={`input-link input-group-append input-group-text ${passwordShown ? "bi-eye" : "bi-eye-slash"}`}
                            onClick={e => setPasswordShown(!passwordShown)}
                          ></i>

                        </div>
                        {errors.txtNewPassword && <span className="invalid-feedback">Please enter a valid password.</span>}
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label htmlFor="confirmNewPasswordLabel" className="col-sm-3 col-form-label form-label">신규 비밀번호 확인</label>

                      <div className="col-sm-9">
                        <div className="mb-3">
                          <div className={`input-group input-group-merge ${errors.txtNewPasswordCheck ? "is-invalid" : "is-valid"}`} >
                            <input
                              type={passwordShown ? "text" : "password"}
                              className="js-toggle-password form-control form-control-lg "
                              placeholder="8+ characters required"
                              autoComplete="off"
                              {...register("txtNewPasswordCheck", { required: true, minLength: 8, validate: value => value === passwordWatch })}

                            />
                            <i id="changePassIcon" className={`input-link input-group-append input-group-text ${passwordShown ? "bi-eye" : "bi-eye-slash"}`}
                              onClick={e => setPasswordShown(!passwordShown)}
                            ></i>

                          </div>
                          {errors.txtNewPasswordCheck && <span className="invalid-feedback">Please enter a valid password.</span>}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end gap-3">
                      <button type="submit" className="btn btn-primary">비밀번호 변경</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </main>
  )
}


export default UpdatePassword
