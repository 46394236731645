import React from 'react'
import { useRecoilState } from 'recoil';
import { createOrderState } from '../../../Store/OrderStore';

const OrderLocationNZ = props => {

  const [orderInfo, setOrderInfo] = useRecoilState(createOrderState);

  const setOrderLocation = location => {

    setOrderInfo(p => {
      return {
        ...p,
        DeliveryLocationCode: location,
        CurrentStep: 2,
      }
    });
  }
  const setBack = () => {
    window.scroll({ top: 0, behavior: 'smooth' })
    setOrderInfo(p => {
      return {
        ...p,
        CurrentStep: 1
      }
    });
  }
  

  return (
    <>
      <div className="w-md-75 w-lg-70 text-center mx-md-auto mb-5 mb-md-9">
        <h2>운항 방법을 선택해 주세요</h2>
        <p></p>
      </div>

      <div className="text-center">
        <div className="row gx-2 justify-content-md-center ">
          <div className="col-sm-6 col-lg-4 mb-3 input-link"
            onClick={() => setOrderLocation("1")}
          >
            <div className="card card-stretched-vertical card-transition shadow-none bg-img-start gradient-y-overlay-lg-dark" href="../demo-real-estate/property-grid.html"
              style={{ backgroundImage: "url(/assets/img/location/img03.jpg)", minHeight: "15rem" }}>
              <div className="card-body card-body-custom ">
                <div className="card-footer">
                  <h3 className="text-white mb-0">뉴질랜드 - 항공</h3>
                  <span className="d-block text-white">대한민국 <i className="bi bi-arrow-right-short"></i> 뉴질랜드</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-lg-4 mb-3 input-link"
            onClick={() => setOrderLocation("3")}
          >
            <div className="card card-stretched-vertical card-transition shadow-none bg-img-start gradient-y-overlay-lg-dark" href="../demo-real-estate/property-grid.html"
              style={{ backgroundImage: "url(/assets/img/location/img04.jpg)", minHeight: "15rem" }}>
              <div className="card-body card-body-custom ">
                <div className="card-footer">
                  <h3 className="text-white mb-0">뉴질랜드 - 해상</h3>
                  <span className="d-block text-white">대한민국 <i className="bi bi-arrow-right-short"></i> 뉴질랜드</span>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="col-sm text-center text-sm-start mt-4" >
            <button type="button" className="btn btn-ghost-secondary" onClick={() => setBack()}>
            <i className="bi-chevron-left small ms-1"></i> 이전으로
            </button>
        </div>


      </div>
    </>
  )
}


export default OrderLocationNZ
