import React from "react";
import { NavLink } from "react-router-dom";

const Footer = (props) => {
  return (
    <footer className="bg-dark">
      <div className="container pb-1 pb-lg-5">
        <div className="row content-space-t-1">
          <div className="col-lg-3 mb-7 mb-lg-0">
            <div className="mb-5">
              <a className="navbar-brand" href="/" aria-label="Space">
                <img
                  className="navbar-brand-logo"
                  src="/assets/img/kaircargo-logo-white.svg"
                  alt="Logo"
                />
              </a>
            </div>

            <ul className="list-unstyled list-py-1">
              <li>
                <div className="link-sm link-light">
                  <i className="bi-geo-alt-fill me-2"></i> 99 Killybegs Drive, Pinehill, Auckland, New Zealand

                </div>
              </li>
              <li>
                <div className="link-sm link-light">
                  {" "}
                  <i className="bi-telephone-inbound-fill me-2"></i>
                  +64 021 883 671
                </div>
              </li>
              <li>
                <div className="link-sm link-light ">
                  {" "}
                  <i className="bi-clock me-2"></i>
                  9am to 5pm Mon~Fri
                </div>
              </li>
            </ul>
          </div>

          <div className="col-sm mb-7 mb-sm-0">
            <h5 className="text-white mb-3">Company</h5>

            <ul className="list-unstyled list-py-1 mb-0">
              <li>
                <NavLink exact to="/aboutus" className="link-sm link-light">
                  About us
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/contactus" className="link-sm link-light">
                  Contact us
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="col-sm">
            <h5 className="text-white mb-3">Support</h5>

            <ul className="list-unstyled list-py-1 mb-0">
              <li>
                <NavLink exact to="/Faq" className="link-sm link-light">
                  FAQ
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/qna" className="link-sm link-light">
                  Q&amp;A
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="col-sm">
            <h5 className="text-white mb-3">Account</h5>
            <ul className="list-unstyled list-py-1 mb-5">
              <li>
                <NavLink exact to="/orderlist" className="link-sm link-light">
                  <i className="bi-person-circle me-1"></i>My Account
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="border-top border-white-10 my-3"></div>
        <div className="row mb-2">
          <div className="col-sm mb-3 mb-sm-0">
            <ul className="list-inline list-separator list-separator-light mb-0">
              <li className="list-inline-item">
                <NavLink exact to="/agreement" className="link-sm link-light">
                  이용약관
                </NavLink>
              </li>
              <li className="list-inline-item">
                <NavLink exact to="/policy" className="link-sm link-light">
                  개인정보처리방침
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="col-sm-auto">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <a
                  className="btn btn-soft-light btn-xs btn-icon"
                  href="/facebook"
                >
                  <i className="bi-facebook"></i>
                </a>
              </li>

              <li className="list-inline-item">
                <a
                  className="btn btn-xs btn-icon kakao-icon-link "
                  href="https://pf.kakao.com/_xmIExbb"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="kakao-icon"> </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
