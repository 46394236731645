import axios from "axios";


const getApi = () => {

  switch (window.GlobalConfigs.serviceUrl) {

    case "STAGING":
      return process.env.REACT_APP_SERVICE_STAGING;
    case "PROD":
      return process.env.REACT_APP_SERVICE_PROD;
    default:
      return process.env.REACT_APP_SERVICE_LOCAL;
  }

};



const instance = axios.create({
  baseURL: getApi(),
});
//console.log("axios.baseUrl")
//console.log("axios.common-============================================");
//Don't use. getToken will be called once, so token dosen't change even if login again.
//will be called when browser is refresehed
//Use Authorization in the errorHandler.js
//instance.defaults.headers.common["Authorization"] = getToken();
// instance.defaults.headers.post["test-header-post"] = "test-post";

instance.defaults.headers.common["Content-Type"] = "application/json";


export default instance;
