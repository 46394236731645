export const getBinaryGap = (N) => {

  //https://app.codility.com/demo/results/trainingU2FQPQ-7Y4/
  var number = N;
  var binary = '';
  var counter = -1;
  var max = 0;
  while (number > 0) {
    var digit = number % 2;

    if (digit === 1) {
      if (counter > max) {
        max = counter;
      }
      counter = 0;
    } else if (counter >= 0) {
      counter++;
    }

    binary = '' + digit + binary;
    number = parseInt(number / 2);
  }
  return max;
}

export const getOddValue = (A) => {

  //https://codility.com/demo/results/trainingFN5RVT-XQ4/

  const isOdd = number => (number % 2) !== 0;

  const makeIntegerOccurrencesMap = (map, nextInteger) => {
    //console.log('map, nextInteger', map, nextInteger);
    const isMissingKey = key => map[key] === undefined;

    if (isMissingKey(nextInteger)) {
      map[nextInteger] = 0;
    }

    ++map[nextInteger];

    return map;
  }


  const integerOccurrencesMap = A.reduce(makeIntegerOccurrencesMap, {});

  return +Object.keys(integerOccurrencesMap)
    .find(integer => isOdd(integerOccurrencesMap[integer]));
}


export const cyclicRotation = (A, K) => {

  //https://app.codility.com/demo/results/trainingSH2W5R-RP5/

  //https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/Array/slice
  const rotateArray = (arr, shiftCount) => [
    ...arr.slice(-shiftCount),
    ...arr.slice(0, -shiftCount)
  ];

  const shiftCount = K % A.length;

  return shiftCount ? rotateArray(A, shiftCount) : A;

}

export const frogJump = (X, Y, D) => {
  //https://app.codility.com/demo/results/training6KKWUD-BXJ/

  //return (Math.ceil((Y-X)/D))

  if (X === Y) {
    return 0;
  } else if (D >= (Y - X)) {
    return 1;
  } else {
    var jumps = parseInt((Y - X) / D);
    jumps += ((Y - X) % D > 0) ? 1 : 0;

    return jumps;
  }

}



export const permMissingElem = (A) => {

  //https://app.codility.com/demo/results/training58W4YJ-VHA/

  //https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/Array/sort

  const newArr = A.sort((a, b) => a - b);

  console.log('newArr', newArr);

  let nextVal = 1;
  let i = 0;
  while (nextVal === newArr[i]) {
    nextVal++;
    i++;
  }

  return nextVal;
  // return +Object.keys(integerOccurrencesMap)
  //   .find(integer => isOdd(integerOccurrencesMap[integer]));
}

export const tapeEquilibrium = (A) => {
  //https://app.codility.com/demo/results/trainingHMJHCT-9BX/

  const getInterval = (val, total, min) => {
    const interval = Math.abs(val - (total - val))
    console.log('min,interval', min, interval);
    if (interval < min)
      return interval;

    return min;
  };

  let totalSum = 0;
  let newArray = [];
  for (let i = 0; i < A.length; i++) {
    totalSum += A[i];
    newArray.push(totalSum);
  }

  let min = Math.abs(newArray[0] - (totalSum - newArray[0]));

  for (let i = 1; i < A.length; i++) {
    min = getInterval(newArray[i], totalSum, min);
  }

  return min;

}

export const demo = (A) => {
  const newArr = [...A].sort((a, b) => a - b);
  let min = 1;
  let i = 0;

  while (newArr[i] <= min) {
    console.log('while, i, min', i, min);
    if (newArr[i] > 0 && newArr[i] >= min) {
      min = newArr[i] + 1;
    }
    i++;
  }

  return min;
}


export const missingInteger = (A) => {
  const newArr = A.sort((a, b) => a - b);
  let min = 1;

  let i = 0;

  console.log('newArr', newArr);

  while (newArr[i] <= min) {
    console.log('while, i, min', i, min);
    if (newArr[i] > 0 && newArr[i] >= min) {
      min = newArr[i] + 1;
    }
    i++;
  }


  console.log('min', min);
}

export const MaxCounters = (N, A) => {
  let newArr = Array(N).fill(0);

  console.log('A', A);
  console.log('newArr', newArr);


}

export const CountDiv = (A, B, K) => {
  if (A === B) {
    if (A % K === 0) {
      return 1
    }
    else {
      return 0
    }
  }

  const leftLimit = Math.ceil(A / K)
  const rightLimit = Math.floor(B / K)

  return (rightLimit - leftLimit + 1)
}

export const PassingCars = (A) => {
  let sum = 0
  let result = 0
  for (let i = A.length - 1; i >= 0; i--) {
    if (A[i] === 1) {
      sum++
    } else {
      result += sum
    }
    if (result > 1000000000) {
      result = -1
      break
    }

  }
  return (result)

}

export const GenomicRangeQuery = (S, P, Q) => {
  let results = [];
  //const impact = { 'A': 1, 'C': 2, 'G': 3, 'T': 4 };

  for (let i = 0; i < P.length; i++) {
    let analyze = S.substring(P[i], Q[i] + 1)
    if (analyze.indexOf('A') !== -1) {
      results[i] = 1
      continue
    }
    if (analyze.indexOf('C') !== -1) {
      results[i] = 2
      continue
    }
    if (analyze.indexOf('G') !== -1) {
      results[i] = 3
      continue
    }
    results[i] = 4

  }
  return results
}

export const MinAvgTwoSlice = (A) => {
  //https://codility.com/demo/results/trainingU6BWPU-ADW/
  let B = []
  let C = []
  let min = 10000
  let result = 0;
  for (let i = 0; i < A.length - 1; i++) {
    B[i] = (A[i] + A[i + 1]) / 2
    if ((B[i]) < min) {
      min = B[i]
      result = i
    }
  }


  for (let i = 0; i < A.length - 2; i++) {
    C[i] = (A[i] + A[i + 1] + A[i + 2]) / 3
    if ((C[i]) < min) {
      min = C[i]
      result = i
    }
  }


  return (result)

}

export const Triangle = (A) => {
  const validate = (A, B, C) => {
    let result = true;

    result = result && (A + B > C);
    result = result && (B + C > A);
    result = result && (C + A > B);

    return result;
  }

  const newArr = [...A].sort((a, b) => a - b);

  console.log('A', A);
  console.log('newArr', newArr);

  for (var i = 2; i < newArr.length; i++) {
    if (validate(newArr[i - 2], newArr[i - 1], newArr[i])) {
      return 1;
    }
  }

  return 0;
}


export const Distinct = (A) => {
  //https://app.codility.com/demo/results/trainingWPEYF6-TC9/

  if (A.length === 0) {
    return 0;
  } else if (A.length === 1) {
    return 1;
  }



  const makeIntegerOccurrencesMap = (map, nextInteger) => {
    //console.log('map, nextInteger', map, nextInteger);
    const isMissingKey = key => map[key] === undefined;

    if (isMissingKey(nextInteger)) {
      map[nextInteger] = 0;
    }

    ++map[nextInteger];

    return map;
  }
  const integerOccurrencesMap = A.reduce(makeIntegerOccurrencesMap, {});


  return +Object.keys(integerOccurrencesMap).length;
}


export const Brackets = (S) => {

  //https://app.codility.com/demo/results/trainingRQ4V9Q-PWX/
  var i = 0;
  var stack = [];

  if (S.length % 2 === 1) {
    return 0;
  }

  const isOpener = (char) => {
    return char === '{' || char === '[' || char === '(' ? true : false;
  };

  const checkCloser = (opener, closer) => {
    if (opener === '(' && closer === ')') return true;
    if (opener === '[' && closer === ']') return true;
    if (opener === '{' && closer === '}') return true;

    return false;
  }



  for (i = 0; i < S.length; i++) {
    var char = S.charAt(i);
    if (isOpener(char)) {
      stack.push(char);
    } else {
      if (stack.length === 0) {
        return 0;
      } else {
        var lastChar = stack.pop();
        if (!checkCloser(lastChar, char)) {
          return 0;
        }
      }
    }
  }

  if (stack.length === 0) {
    return 1;
  } else {
    return 0;
  }
}

export const StoneWall = (H) => {
  //https://app.codility.com/demo/results/trainingYYWY68-ZVH/

  var counter = 0;
  var height = 0;
  var blocks = [];
  var i = 0;

  while (i < H.length) {
    if (H[i] > height) {
      var newBlock = H[i] - height;
      blocks.push(newBlock);
      height += newBlock;
      counter++;
      i++;
    } else if (H[i] < height) {
      var lastBlock = blocks.pop();
      height -= lastBlock;
    } else {
      i++;
    }
  }

  return counter;

}


export const Nesting = (S) => {
  if (S.length === 0) {
    return 1;
  }

  var str = S.split('');

  if (str.length % 2 !== 0) {
    return 0;
  }

  var control = 0;
  for (var i = 0; i < str.length; i++) {
    if (str[i] === '(') {
      control++;
    } else {
      control--;
    }

    if (control < 0) {
      return 0;
    }
  }

  if (control === 0) {
    return 1;
  } else {
    return 0;
  }
}

export const MaxDoubleSliceSum = (A) => {

  //https://app.codility.com/demo/results/trainingUY3GP9-J3Q/
  const N = A.length;

  if (N === 3) return 0;

  let headSum = A.map(i => 0);
  let tailSum = A.map(i => 0);

  for (let i = 1; i < N - 1; i++) {
    headSum[i] = Math.max(0, headSum[i - 1] + A[i]);
  }

  for (let i = N - 2; i >= 1; i--) {
    tailSum[i] = Math.max(0, tailSum[i + 1] + A[i]);
  }

  let maxSum = 0;

  for (let i = 1; i < N - 1; i++) {
    maxSum = Math.max(maxSum, headSum[i - 1] + tailSum[i + 1]);
  }

  return maxSum;
}