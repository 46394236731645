import React, { useEffect } from 'react'
import OrderReceiver from './Items/OrderReceiver';
import PurchaseOrderItems from './Items/PurchaseOrderItems';
import * as OrderApi from '../../Apis/OrderApi';
import * as AccountApi from '../../Apis/AccountApi';
import { useRecoilState } from 'recoil';
import { createOrderState } from '../../Store/OrderStore';
import { useSpinner } from "../../Core/Context/SpinnerContext";
import Breadcrumb from '../Common/Breadcrumb/Breadcrumb';
import { useHistory } from "react-router-dom";
import { useNotification } from "../../Core/Context/NotificationContext";

const UpdatePurchaseOrder = props => {

  const history = useHistory();
  const orderId = props.match.params.id;
  const [orderInfo, setOrderInfo] = useRecoilState(createOrderState);
  const { setSpinner } = useSpinner();
  const { setNotifiications } = useNotification();

  const setOrder = async () => {
    setSpinner(true);

    try {
      await OrderApi.updatePurchaseOrder(orderId, orderInfo);
      console.log(orderInfo)
      setOrderInfo(null);
      history.push("/purchaseorder/" + orderId);
    }
    catch (err) {
      console.log('err', err);

      if (err.data?.Contents) {
        const errMessage = err.data?.Contents.map((item) => item.Message);
        setNotifiications("error", errMessage, "입력한 정보를 확인해 주세요", 3000, null);
      }
    }

    setSpinner(false);

    window.scroll({ top: 0, behavior: 'smooth' })
    setOrderInfo(p => {
      return { ...p, CurrentStep: 4 }
    });
  }

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })

    const getOrderInfo = async () => {
      setSpinner(true);

      try {

        const orderItemType = await OrderApi.getOrderItemType();
        const customerInfo = await AccountApi.getCustomerInfo();
        const orderDetail = await OrderApi.getOrder(orderId);

  
          setOrderInfo(p => {
            return {
              ...p,
              CurrentStep: 2,
              OrderId: orderDetail.OrderId,
              AmountUnit: orderDetail.DeliveryLocationCode === "1" ? "₩" : "$",
              DeliveryLocationCode: orderDetail.DeliveryLocationCode,
              IsReceiverOwn: orderDetail.IsReceiverOwn === "True" ? true : false,
              ReceiverName: orderDetail.ReceiverName,
              ReceiverEmail: orderDetail.ReceiverEmail,
              ReceiverPhone: orderDetail.ReceiverPhone,
              ReceiverMobile: orderDetail.ReceiverMobile,
              ReceiverAddress1: orderDetail.ReceiverAddress1,
              ReceiverAddress2: orderDetail.ReceiverAddress2,
              ReceiverZipcode: orderDetail.ReceiverZipcode,
              ReceiverMessage: orderDetail.ReceiverMessage,
              PickupTypeCode: orderDetail.PickupTypeCode,
              PersonalCustomCode: orderDetail.PersonalCustomCode,
              IsCombineItem: orderDetail.IsCombineItem === "True" ? true : false,
              IsUseMileage: orderDetail.IsUseMileage === "True" ? true : false,
              IsRepackage: orderDetail.IsRepackage === "True" ? true : false,
              PurchaseOrderItems: orderDetail.PurchaseOrderItemList,
              OrderItems: orderDetail.OrderItemList,
              OrderItemType: orderItemType,
              UserInfo: customerInfo,
              DeletedOrderitems: [],
              DeletedPurchaseOrderitems: [],
              IsPurchaseOrder: true,
            }
          });
        
      }
      catch (err) {
        console.log('err', err);
      }

      setSpinner(false);
    }

    getOrderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <main id="content" role="main" className="bg-light">
      <Breadcrumb
        Current={"Update Purchase Orders"}
        Parent={"My Page"}
        Small
        HideMenu
      />

      <div className="container container-min content-space-1 content-space-lg-2">

        <div>
          {orderInfo?.CurrentStep === 2 && <OrderReceiver init={true} />}
          {orderInfo?.CurrentStep === 3 && <PurchaseOrderItems setOrder={setOrder} />}
          {/* {orderInfo?.CurrentStep === 4 && <OrderCompleted />} */}
        </div>
      </div>
    </main>
  )
}


export default UpdatePurchaseOrder
