import React from 'react'
import { useForm } from "react-hook-form";

const ConfirmPin = ({ responsePin }) => {

  const { register, handleSubmit, setValue, formState: { errors, isSubmitted } } = useForm();
  const onSubmit = async (data) => {
    setValue("txtCode", "");
    responsePin(data);
  };
  return (
    <div className="flex-grow-1 mx-auto" style={{ maxWidth: "28rem" }}>
      <div className="text-center mb-5 mb-md-7">
        <h1 className="h2">Forgot password?</h1>
        <p>입력하신 이메일로 확인코드를 발송 하였습니다.</p>
        <p>발송된 코드를 확인해 주세요.</p>

      </div>

      <form
        className={` ${isSubmitted ? "was-validated" : ""} `}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <label className="form-label" htmlFor="signupSimpleResetPasswordEmail" >코드입력</label>
          </div>
          <div className={`input-group input-group-merge ${errors.txtCode ? "is-invalid" : "is-valid"} `} >
            <input
              type="text"
              className="form-control form-control-lg "
              {...register("txtCode", { required: true })}
            />
          </div>
          {errors.txtCode && <span className="invalid-feedback">코드를 입력해 주세요.</span>}

        </div>

        <div className="d-grid mb-3">
          <button type="submit" className="btn btn-primary btn-lg">Submit</button>
        </div>
      </form>
    </div>
  )
}


export default ConfirmPin
