import React, { useState, useEffect } from 'react'
import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';
import Sidebar from '../Sidebar/Sidebar';
import Order from './Items/Order';
import * as moment from 'moment';
import { useLocation } from "react-router-dom";
import * as OrderApi from '../../../Apis/OrderApi';
import { Paginator } from 'primereact/paginator';
import { useSpinner } from "../../../Core/Context/SpinnerContext";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const OrderList = props => {

  const queryParams = useQuery();
  const { setSpinner } = useSpinner();
  const [searchDate, setSearchDate] = useState(queryParams.get("searchDate") ?? "30");
  const [locationTab, setLocationTab] = useState(queryParams.get("locationTab") ?? "0");
  const [nzTab, setNzTab] = useState(false);
  const [noOrder, setNoOrder] = useState(false);
  const [orderInfo, setOrderInfo] = useState();
  const [pageNo, setpageNo] = useState(queryParams.get("pageNo") ?? "1");
  const pageSize = 5;

  const getList = async (_pageNo, _searchDate, _location) => {
    try {
      setNoOrder(false);
      setSpinner(true);
      //console.log(_searchDate);
      const queryStartDate = moment().subtract(Number(_searchDate), 'days').format('YYYY-MM-DD 00:00:00');
      const queryEndDate = moment().format('YYYY-MM-DD 23:59:59');
      //console.log(queryStartDate, queryEndDate);

      const orderResponse = await OrderApi.getOrderList(queryStartDate, queryEndDate, _location, _pageNo, pageSize);
      //console.log(orderResponse);

      if (orderResponse) {
        if (orderResponse.Data.length === 0) {
          setNoOrder(true);
        }

        setOrderInfo(orderResponse);
      }
    }
    catch (err) {
      console.log('err', err);
    }
    setSpinner(false);
  }

  const setLocation = (location, bool) => {
    setLocationTab(location);
    setNzTab(bool);
  }

  useEffect(() => {
    setpageNo(1);

  }, [searchDate, locationTab])

  useEffect(() => {
    getList(pageNo, searchDate, locationTab);
    props.history.push(`/orderlist?searchDate=${searchDate}&locationTab=${locationTab}&pageNo=${pageNo}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, searchDate, locationTab])

  return (
    <main id="content" role="main" className="bg-light">
      <Breadcrumb
        Current={"배송대행 주문리스트"}
        Parent={"My Page"}
      />


      <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          <div className="col-lg-9">
            <div className="card">
              <div className="card-header border-bottom">
                <h5 className="card-header-title">주문리스트</h5>
              </div>
              <div className="card-body">
                <div className="js-nav-scroller hs-nav-scroller-horizontal">
                  <ul className="nav nav-segment nav-fill mb-7" id="featuresTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <div className={`nav-link input-link ${locationTab === "0" ? "active" : ""}`}
                        data-bs-toggle="tab" role="tab"
                        onClick={() => setLocation("0", false)}
                      >전체</div>
                    </li>
                    <li className="nav-item" role="presentation">
                      <div className={`nav-link input-link ${locationTab === "2" ? "active" : ""}`}
                        data-bs-toggle="tab" role="tab"
                        onClick={() => setLocation("2", false)}
                      >대한민국</div>
                    </li>
                    <li className="nav-item" role="presentation">
                      <div className={`nav-link input-link ${nzTab ? "active" : ""}`}
                        data-bs-toggle="tab" role="tab"
                        onClick={() => setLocation("1", true)}
                      >뉴질랜드</div>
                    </li>
                  </ul>
                </div>
                <div className="tab-content" id="accountOrdersTabContent">
                  <div className="tab-pane fade show active" id="accountOrdersOne" role="tabpanel" aria-labelledby="accountOrdersOne-tab">
                    <div className="d-sm-flex align-items-sm-center mb-5">

                      <div className="tom-select-custom">
                        <select className="js-select form-select form-select-sm"
                          defaultValue={searchDate}
                          onChange={e => setSearchDate(e.target.value)}
                        >
                          <option value="30">최근 1개월</option>
                          <option value="180">최근 6개월</option>
                          <option value="365">최근 1년</option>
                        </select>
                      </div>
                      <div className="ms-2 mb-2 mb-sm-0 me-3">
                        <span> <strong className="text-dark">{orderInfo?.Data?.length} 건의 주문이 있습니다.</strong> </span>
                      </div>
                    </div>

                   {nzTab ? (<div className="js-nav-scroller hs-nav-scroller-horizontal">
                      <ul className="nav nav-segment nav-fill mb-7" id="featuresTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <div className={`nav-link input-link ${locationTab === "1" ? "active" : ""}`}
                            data-bs-toggle="tab" role="tab"
                            onClick={() => setLocationTab("1")}
                          >항공</div>
                        </li>
                        <li className="nav-item" role="presentation">
                          <div className={`nav-link input-link ${locationTab === "3" ? "active" : ""}`}
                            data-bs-toggle="tab" role="tab"
                            onClick={() => setLocationTab("3")}
                          >해상</div>
                        </li>
                       
                      </ul>
                </div>):""}


                    <ul className="list-unstyled mb-5">

                      {orderInfo?.Data?.map((item, i) =>
                        <Order key={item.OrderId} item={item} index={i} />
                      )}

                      {noOrder &&
                        <div >
                          <div className="text-center content-space-1">
                            <img className="avatar avatar-xl mb-3" src="/assets/svg/illustrations/empty-state-no-data.svg" alt=" Description" />
                            <p className="card-text">주문정보가 없습니다.</p>
                          </div>
                        </div>
                      }
                    </ul>
                    {orderInfo?.Data?.length > 0 &&
                      // <Paginator first={1} rows={Number(orderInfo.Paging.PageSize)} onPageChange={(e) => setpageNo(e.first)}></Paginator>
                      <Paginator first={1} rows={pageSize} totalRecords={Number(orderInfo.Paging.TotalNumberOfRecords)}
                        onPageChange={(e) => setpageNo(e.page + 1)} />

                    }
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </main>
  );

}

export default OrderList
