import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { NotificationContainer, NotificationManager as Notifications } from "react-notifications";
import { RecoilRoot } from 'recoil';
import { NotificationContext } from "./Core/Context/NotificationContext";
import { AuthContextProvider } from "./Core/Context/AuthContext";
import { SpinnerContextProvider } from "./Core/Context/SpinnerContext";
import SecureRoute from "./Core/Route/SecureRoute";
import ScrollToTop from './Core/Route/ScrollToTop';
import "react-notifications/lib/notifications.css";
import Header from './Components/Common/Header/Header';
import Footer from './Components/Common/Footer/Footer';
import AboutUs from './Components/Contents/AboutUs/AboutUs';
import Login from './Components/Login/Login';
import Signup from './Components/Signup/Signup';
import Home from './Components/Contents/Home/Home';
import Faq from './Components/Contents/FAQ/Faq';
import BoardNoticeList from './Components/BoardNotice/BoardNoticeList';
import BoardNotice from './Components/BoardNotice/BoardNotice';
import BoardQnaList from './Components/BoardQna/BoardQnaList';
import BoardQna from './Components/BoardQna/BoardQna';
import BoardQnaEdit from './Components/BoardQna/BoardQnaEdit';
import PackageInfo from './Components/Contents/PackageInfo/PackageInfo';
import Policy from './Components/Contents/Policy/Policy';
import Agreement from './Components/Contents/Policy/Agreement';
import Flight from './Components/Contents/Flight/Flight';
import Tracking from './Components/Contents/Tracking/Tracking';
import CustomDuty from './Components/Contents/CustomDuty/CustomDuty';
import Warehouse from './Components/Contents/Warehouse/Warehouse';
import DeliveryFee from './Components/Contents/DeliveryFee/DeliveryFee';
import UserGuide from './Components/Contents/UserGuide/UserGuide';
import Contactus from './Components/Contents/Contactus/Contactus';
import UpdatePassword from './Components/MyPage/UpdatePssword/UpdatePassword';
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import UpdateUser from './Components/MyPage/UpdateUser/UpdateUser';
import OrderList from './Components/MyPage/OrderList/OrderList';
import OrderDetail from './Components/MyPage/OrderDetail/OrderDetail';
import CreateOrder from './Components/Order/CreateOrder';
import UpdateOrder from './Components/Order//UpdateOrder';
import PurchaseOrderList from "./Components/MyPage/OrderList/PurchaseOrderList";
import CreatePurchaseOrder from './Components/Order/CreatePurchaseOrder';
import axiosCommon from "./Apis/Axios/AxiosCommon";
import axiosErrorHandler from "./Apis/Axios/AxiosErrorHandler";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';

import * as utils from './utils';
import PurchaseOrderDetail from "./Components/MyPage/OrderDetail/PurchaseOrderDetail";
import UpdatePurchaseOrder from "./Components/Order/UpdatePurchaseOrder";

function App() {

  console.log(utils.demo([-1, -3]));

  const setNotification = (type, content, title, timeout, event) => {
    if (type.toLowerCase() === "warning") {
      Notifications.warning(content, title, timeout, event ? event : null);
    } else if (type.toLowerCase() === "error") {
      Notifications.error(content, title, timeout, event ? event : null);
    } else if (type.toLowerCase() === "success") {
      Notifications.success(content, title, timeout, event ? event : null);
    }
  };

  return (
    <>
      <RecoilRoot>
        <SpinnerContextProvider>
          <AuthContextProvider>
            <NotificationContext.Provider value={{ setNotifiications: setNotification }}>
              <Router>
                <Header />
                <ScrollToTop>
                  <Switch>
                    <Route exact path="/aboutus" component={AboutUs} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/signup" component={Signup} />
                    <Route exact path="/faq" component={Faq} />
                    <Route exact path="/guide" component={UserGuide} />
                    <Route exact path="/deliveryfee" component={DeliveryFee} />
                    <Route exact path="/flight" component={Flight} />
                    <Route exact path="/policy" component={Policy} />
                    <Route exact path="/agreement" component={Agreement} />
                    <Route exact path="/notice" component={BoardNoticeList} />
                    <Route exact path="/notice/:id" component={BoardNotice} />
                    <Route exact path="/qna" component={BoardQnaList} />
                    <Route exact path="/qna/:id" component={BoardQna} />
                    <SecureRoute exact path="/qnaedit" component={BoardQnaEdit} />
                    <SecureRoute exact path="/qnaedit/:id" component={BoardQnaEdit} />
                    <Route path="/tracking/:id" component={Tracking} />
                    <Route exact path="/packageinfo" component={PackageInfo} />
                    <Route exact path="/warehouse" component={Warehouse} />
                    <Route exact path="/customduty" component={CustomDuty} />
                    <Route exact path="/contactus" component={Contactus} />
                    <Route exact path="/forgotpassword" component={ForgotPassword} />
                    <SecureRoute path="/password" component={UpdatePassword} />
                    <SecureRoute path="/user" component={UpdateUser} />
                    <SecureRoute path="/orderlist" component={OrderList} />
                    <SecureRoute path="/order/:id" component={OrderDetail} />
                    <SecureRoute path="/updateorder/:id" component={UpdateOrder} />
                    <SecureRoute path="/createorder" component={CreateOrder} />
                    <SecureRoute path="/createpurchaseorder" component={CreatePurchaseOrder} />
                    <SecureRoute path="/purchaseorderlist" component={PurchaseOrderList} />
                    <SecureRoute path="/purchaseorder/:id" component={PurchaseOrderDetail} />
                    <SecureRoute path="/updatepurchaseorder/:id" component={UpdatePurchaseOrder} />



                    <Route path="/" component={Home} />
                  </Switch>
                </ScrollToTop>
                <Footer />
              </Router>
              <NotificationContainer />
            </NotificationContext.Provider>
          </AuthContextProvider>
        </SpinnerContextProvider>
      </RecoilRoot>
    </>
  );
}

export default axiosErrorHandler(App, axiosCommon);;
