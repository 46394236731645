import React, { useState } from 'react'
import ConfirmEmail from './Items/ConfirmEmail';
import ConfirmPin from './Items/ConfirmPin';
import ResetPassword from './Items/ResetPassword';
import { useSpinner } from "../../Core/Context/SpinnerContext";
import { useNotification } from "../../Core/Context/NotificationContext";
import * as AccountApi from "../../Apis/AccountApi";
import { useHistory } from "react-router-dom";
const ForgotPassword = props => {

  const history = useHistory();
  const { setNotifiications } = useNotification();
  const { setSpinner } = useSpinner();

  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");

  const requestPin = async (data) => {
    try {

      setSpinner(true);

      await AccountApi.requestPasswordResetCode(data.txtEmail);
      setEmail(data.txtEmail);
      setStep(2);
    }
    catch (err) {
      console.log('err', err);

      if (err.data?.Contents) {
        const errMessage = err.data?.Contents.map((item) => item.Message);
        setNotifiications("warning", errMessage, "입력한 정보를 확인해 주세요", 3000, null);
      }
    }

    setSpinner(false);
  };


  const responsePin = async (data) => {
    try {

      setSpinner(true);

      const pin = {
        "Email": email,
        "ResetCode": data.txtCode
      };

      const response = await AccountApi.responsePasswordResetCode(pin);

      if (response) {
        setStep(3);
      }
      else {
        setNotifiications("error", "입력한 코드가 일치하지 않습니다.", "입력한 정보를 확인해 주세요", 3000, null);
      }
    }
    catch (err) {
      console.log('err', err);

      if (err.data?.Contents) {
        const errMessage = err.data?.Contents.map((item) => item.Message);
        setNotifiications("warning", errMessage, "입력한 정보를 확인해 주세요", 3000, null);
      }
    }

    setSpinner(false);
  };

  const resetPassword = async (data) => {
    try {

      setSpinner(true);

      const newPassword = {
        "Email": email,
        "newPassword": data.txtPassword
      };

      await AccountApi.resetPassword(newPassword);

      setNotifiications("success", "저장 되었습니다.", "Success", 3000, null);
      setSpinner(false);
      history.push("/login");
    }
    catch (err) {
      console.log('err', err);

      if (err.data?.Contents) {
        const errMessage = err.data?.Contents.map((item) => item.Message);
        setNotifiications("warning", errMessage, "입력한 정보를 확인해 주세요", 3000, null);
      }
    }

    setSpinner(false);
  };

  return (
    <main id="content" role="main" className="bg-light">
      <div className="container content-space-3 content-space-t-lg-4 content-space-b-lg-3">
        {step === 1 && <ConfirmEmail requestPin={requestPin} />}
        {step === 2 && <ConfirmPin responsePin={responsePin} />}
        {step === 3 && <ResetPassword resetPassword={resetPassword} />}
      </div>
    </main>
  )
}


export default ForgotPassword
