
export const DeliveryCompanyList = [
  "CJ대한통운",
  "우체국택배",
  "한진택배",
  "롯데택배",
  "로젠택배",
  "홈픽택배",
  "CVSnet 편의점택배",
  "CU 편의점택배",
  "경동택배",
  "대신택배",
  "일양로지스",
  "기타",
  "직접접수"
];

export const pickupType = ["", "직접수령", "택배발송"];

