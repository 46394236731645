import React from 'react'
import Postcode from '@actbase/react-daum-postcode';
import { Dialog } from 'primereact/dialog';


const AddressSearch = props => {
  const { isShow, setAddress, onClose } = props;


  const dialogFooter = (
    <div>
      <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => onClose()} >Close</button>
    </div>
  );

  return (
    <Dialog header="우편번호 찾기" footer={dialogFooter} style={{ width: "550px", height: "660px" }}
      visible={isShow} modal onHide={() => onClose()}>
      <Postcode
        style={{ width: 320, height: 320 }}
        jsOptions={{ animated: true }}
        onSelected={data => setAddress(data)}
      />
    </Dialog>
  )
}

export default AddressSearch
