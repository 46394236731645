import React, { useState, useEffect } from 'react'
import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';
import Sidebar from '../Sidebar/Sidebar';
import { useForm } from "react-hook-form";
import { useAuth } from "../../../Core/Context/AuthContext";
import * as AccountApi from "../../../Apis/AccountApi";
import { useSpinner } from "../../../Core/Context/SpinnerContext";
import { useNotification } from "../../../Core/Context/NotificationContext";
import AddressSearch from '../../Common/AddressSerach/AddressSearch';
import AwaitButton from '../../../Core/UI/AwaitButton/AwaitButton';

const UpdateUser = props => {
  const { AuthToken } = useAuth();

  const { setNotifiications } = useNotification();
  const { register, handleSubmit, setValue, formState: { errors, isSubmitted } } = useForm();
  const { setSpinner } = useSpinner();
  const [isLoading, setIsLoading] = useState(false);

  const [showAddressFind, setShowAddressFind] = useState(false);


  const setAddress = (data) => {
    setValue("txtAddressKR1", data.address + (data.buildingName !== "" ? `, ${data.buildingName}` : ""));
    setValue("txtAddressKR2", "");
    setValue("txtZipcodeKR", data.zonecode);
    setShowAddressFind(false);
  }

  const onSubmit = async (data) => {
    try {

      setIsLoading(true);

      const customerInfo = {
        "IsChangePassword": false,
        "UserPassword": "",
        "UserName": data.txtName ?? "",
        "EmailAddress": AuthToken?.user_login_id,
        "IsActive": true,
        "PhoneNumber": data.txtPhone ?? "",
        "MobileNumber": data.txtMobile ?? "",
        "ZipCodeKR": data.txtZipcodeKR ?? "",
        "UserAddressKR1": data.txtAddressKR1 ?? "",
        "UserAddressKR2": data.txtAddressKR2 ?? "",
        "ZipCodeNZ": data.txtZipcodeNZ ?? "",
        "UserAddressNZ1": data.txtAddressNZ1 ?? "",
        "UserAddressNZ2": data.txtAddressNZ2 ?? "",
        "PersonalCustomCode": data.txtPersonalCustomCode ?? ""
      };

      await AccountApi.updateCustomer(customerInfo);

      setNotifiications("success", "저장 되었습니다.", "Success", 3000, null);

    }
    catch (err) {
      console.log('Error:', err);

      if (err.data?.Contents) {
        const errMessage = err.data?.Contents.map((item) => item.Message);
        setNotifiications(
          "warning",
          errMessage,
          "입력한 정보를 확인해 주세요",
          3000,
          null
        );
      }
    }
    setIsLoading(false);

  };

  useEffect(() => {

    const getUserInfo = async () => {

      try {
        setSpinner(true);

        const userResponse = await AccountApi.getCustomerInfo();

        //console.log(userResponse);
        setValue("txtName", userResponse.UserName);
        setValue("txtPhone", userResponse.PhoneNumber);
        setValue("txtMobile", userResponse.MobileNumber);
        setValue("txtMobile", userResponse.MobileNumber);
        setValue("txtAddressKR1", userResponse.UserAddressKR1);
        setValue("txtAddressKR2", userResponse.UserAddressKR2);
        setValue("txtZipcodeKR", userResponse.ZipCodeKR);
        setValue("txtAddressNZ1", userResponse.UserAddressNZ1);
        setValue("txtAddressNZ2", userResponse.UserAddressNZ2);
        setValue("txtZipcodeNZ", userResponse.ZipCodeNZ);
        setValue("txtPersonalCustomCode", userResponse.PersonalCustomCode);

      }
      catch (err) {
        console.log('Error:', err);

      }
      setSpinner(false);
    };

    getUserInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <main id="content" role="main" className="bg-light">
      <Breadcrumb
        Current={"회원정보"}
        Parent={"My Page"}
      />
      <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          <div className="col-lg-9">
            <div className="card">
              <div className="card">
                <div className="card-header border-bottom">
                  <h5 className="card-header-title">회원정보</h5>
                </div>

                <div className="card-body">
                  <form
                    className={` ${isSubmitted ? "was-validated" : ""} `}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="row mb-4">
                      <label htmlFor="txtName" className="col-sm-3 col-form-label form-label">이메일</label>
                      <div className="col-sm-9">
                        {AuthToken?.user_login_id}
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label htmlFor="txtName" className="col-sm-3 col-form-label form-label">이름 (영문명)
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <div className="col-sm-9">
                        <div className={`input-group input-group-merge ${errors.txtName ? "is-invalid" : "is-valid"} `} >
                          <input
                            type="text"
                            className="form-control form-control-lg "
                            placeholder=""
                            {...register("txtName",
                              {
                                required: true,
                                pattern: /^[a-zA-Z0-9.,-_ ]*$/
                              })}
                          />
                        </div>
                        {errors.txtName && <span className="invalid-feedback">이름(영문명)을 확인해 주세요.</span>}
                      </div>
                    </div>


                    <div className="row mb-4">
                      <label htmlFor="txtPhone" className="col-sm-3 col-form-label form-label">전화번호</label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-merge is-valid" >
                          <input
                            type="text"
                            className="form-control form-control-lg "
                            placeholder=""
                            {...register("txtPhone")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <label htmlFor="txtMobile" className="col-sm-3 col-form-label form-label">휴대폰</label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-merge is-valid" >
                          <input
                            type="text"
                            className="form-control form-control-lg "
                            placeholder=""
                            {...register("txtMobile")}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="my-3" />
                    <div className="row mb-2">
                      <div className="col-form-label form-label">
                        <span className="h5"> 대한민국</span>
                        <span className="ms-2 text-muted small">(한국으로 배송대행 신청시 기본주소로 사용할 수 있습니다.)</span>
                      </div>

                    </div>
                    <div className="row mb-4">
                      <label htmlFor="txtAddressKR1" className="col-sm-3 col-form-label form-label">주소
                        <span onClick={() => setShowAddressFind(true)} className="link-info ms-2 input-link">
                          <i className="bi bi-card-list ms-2"></i>
                          <span className="ms-1">우편번호 검색</span>
                        </span>
                      </label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-merge is-valid" >
                          <input
                            type="text"
                            className="form-control form-control-lg "
                            placeholder=""
                            {...register("txtAddressKR1")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label htmlFor="txtAddressKR2" className="col-sm-3 col-form-label form-label">추가주소</label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-merge is-valid" >
                          <input
                            type="text"
                            className="form-control form-control-lg "
                            placeholder=""
                            {...register("txtAddressKR2")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <label htmlFor="txtZipcodeKR" className="col-sm-3 col-form-label form-label">우편번호</label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-merge is-valid" >
                          <input
                            type="text"
                            className="form-control form-control-lg "
                            placeholder=""
                            {...register("txtZipcodeKR")}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="my-3" />
                    <div className="row mb-2">
                      <div className="col-form-label form-label">
                        <span className="h5"> 뉴질랜드</span>
                        <span className="ms-2 text-muted small">(뉴질랜드로 배송대행 신청시 기본주소로 사용할 수 있습니다.)</span>
                      </div>

                    </div>
                    <div className="row mb-4">
                      <label htmlFor="txtAddressNZ1" className="col-sm-3 col-form-label form-label">Address
                      </label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-merge is-valid" >
                          <input
                            type="text"
                            className="form-control form-control-lg "
                            placeholder=""
                            {...register("txtAddressNZ1")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label htmlFor="txtAddressNZ2" className="col-sm-3 col-form-label form-label">Address 2</label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-merge is-valid" >
                          <input
                            type="text"
                            className="form-control form-control-lg "
                            placeholder=""
                            {...register("txtAddressNZ2")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-6">
                      <label htmlFor="txtZipcodeNZ" className="col-sm-3 col-form-label form-label">Postcode</label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-merge is-valid" >
                          <input
                            type="text"
                            className="form-control form-control-lg "
                            placeholder=""
                            {...register("txtZipcodeNZ")}
                          />
                        </div>
                      </div>
                    </div>
                    <hr className="my-3" />
                    <div className="row mb-4">
                      <label htmlFor="txtPersonalCustomCode" className="col-sm-3 col-form-label form-label">개인통관부호</label>
                      <div className="col-sm-9">
                        <div className="input-group input-group-merge is-valid" >
                          <input
                            type="text"
                            className="form-control form-control-lg "
                            placeholder=""
                            {...register("txtPersonalCustomCode")}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end gap-3">
                      <AwaitButton type="submit" className="btn btn-primary" loading={isLoading} >회원정보 수정</AwaitButton>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddressSearch
        isShow={showAddressFind}
        setAddress={data => setAddress(data)}
        onClose={() => setShowAddressFind(false)}
      />
    </main>
  )
}

export default UpdateUser
