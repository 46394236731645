import React, { memo } from 'react'
import { useAuth } from "../../../Core/Context/AuthContext";
import { NavLink } from "react-router-dom";
const Sidebar = () => {

  const { AuthToken } = useAuth();

  return (
    <div className="navbar-expand-lg navbar-light">
      <div id="sidebarNav" className="collapse navbar-collapse navbar-vertical">
        <div className="card flex-grow-1 mb-5">
          <div className="card-body">
            <div className="d-none d-lg-block text-center mb-5">

              <h4 className="card-title mb-0">{AuthToken?.name}님 </h4>
              <p className="card-text small">{AuthToken?.user_login_id}</p>
            </div>
            <hr className="my-3" />
            <span className="text-cap">Account</span>

            <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
              <li className="nav-item">
                <NavLink
                  exact
                  to="/user"
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className="bi-person-badge nav-icon"></i> 회원정보
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/password"
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className="bi-shield-shaded nav-icon"></i> 비밀번호 변경
                </NavLink>
              </li>

            </ul>
            <hr className="my-3" />
            <span className="text-cap">Shopping</span>

            <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
              <li className="nav-item">
                <NavLink
                  exact
                  to="/orderlist"
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className="bi-basket nav-icon"></i> 배송대행 주문정보
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/purchaseorderlist"
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className="bi-basket nav-icon"></i> 구매대행 주문정보
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Sidebar);
