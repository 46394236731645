import React, { useState, useEffect } from 'react'
import { useSpinner } from "../../Core/Context/SpinnerContext";
import { useLocation, NavLink } from "react-router-dom";
import * as BoardApi from '../../Apis/BoardApi';
import BoardQnaListItem from './BoardQnaListItem/BoardQnaListItem';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BoardQnaList = props => {

  const queryParams = useQuery();
  const { setSpinner } = useSpinner();
  const [pageNo, setpageNo] = useState(queryParams.get("pageNo") ?? "1");
  const [boardData, setBoardData] = useState();
  const pageSize = 6;

  const getList = async (_pageNo) => {
    try {
      setSpinner(true);

      const boardResponse = await BoardApi.getBoardQnaList("", "", "", _pageNo, pageSize);
      console.log(boardResponse);
      setBoardData(boardResponse);

      props.history.push(`/qna?pageNo=${_pageNo}`);
    }
    catch (err) {
      console.log('err', err);
    }
    setSpinner(false);
  };

  useEffect(() => {
    if (pageNo) {
      getList(pageNo);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo])

  return (
    <main id="content" role="main" className="bg-light">
      <div className="container  content-space-3 content-space-t-lg-4 content-space-b-lg-3">
        <div className="w-lg-75-disable mx-auto">
          <div className="text-center mb-5 mb-md-7">
            <h1 className="h2">Q&amp;A</h1>
          </div>

          <div className="row">
            <div className="col mb-3 mb-md-0">
            </div>

            <div className="col-auto align-self-md-end mb-2">
              <div className=" d-sm-flex gap-2">
                <NavLink className="btn btn-dark btn-sm" to="/qnaedit">
                  <i className="bi bi-pencil-fill me-1"></i> 글쓰기
                </NavLink>
              </div>
            </div>
          </div>

          <div className="card card-lg">
            <div className="card-body container-min-400">
              {boardData &&
                <BoardQnaListItem
                  items={boardData}
                  pageNoChanged={(pageNo) => setpageNo(pageNo)}
                />
              }

            </div>
          </div>
        </div>
      </div>
    </main >
  )
}



export default BoardQnaList
