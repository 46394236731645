import React, { memo } from 'react'
import * as KFunctions from '../../../../Core/Constants/KFunctions';


const PurchaseOrderitem = ({ item, deliveryLocationCode }) => {

  const getItemAmount = () => {
    return KFunctions.getCurrency(deliveryLocationCode, item.ItemAmount);
  }


  const purchaseStatus = () => {
    if (item.IsOrdered === "0") return "주문전";
    if (item.IsOrdered === "1") return "주문완료";
    return "배대지로이동중"
  }

  return (
    <li className="list-group-item">
      <div className="d-flex">
        <div className="flex-grow-1 ms-3">
          <div className="row">
            <div className="col-sm-12 mb-3 mb-sm-0">
              <div className="row align-items-center flex-grow-1 mb-5">
                <div className="col">
                  <span className="card-subtitle text-body mt-1">{purchaseStatus()}</span>
                </div>
                <div className="col-auto">
                  <span className="h5 mb-1 text-primary">{getItemAmount()} * {item.ItemQuantity}개</span>
                </div>
              </div>
              <div className="d-grid gap-1">
                <div className="col-md-10">
                  <dl className="row">
                    <dt className="col-1 min-width-120"><span className="card-subtitle text-body">상품명:</span></dt>
                    <dd className="col-7"><h6>{item.ItemName}</h6></dd>
                  </dl>
                </div>
                <div className="col-md-10">
                  <dl className="row">
                    <dt className="col-1 min-width-120"><span className="card-subtitle text-body">상품 옵션:</span></dt>
                    <dd className="col-7"><h6>{item.OrderNote}</h6></dd>
                  </dl>
                </div>
                <div className="col-md-10">
                  <dl className="row">
                    <dt className="col-1 min-width-120"><span className="card-subtitle text-body">택배사:</span></dt>
                    <dd className="col-7"><h6>{item.DeliveryCompany}</h6></dd>
                  </dl>
                </div>
                <div className="col-md-10">
                  <dl className="row">
                    <dt className="col-1 min-width-120"><span className="card-subtitle text-body">송장번호:</span></dt>
                    <dd className="col-7"><h6>{item.TrackingNumber}</h6></dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default memo(PurchaseOrderitem);
