import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as AccountApi from "../../Apis/AccountApi";
import { useAuth } from "../../Core/Context/AuthContext";
import { useNotification } from "../../Core/Context/NotificationContext";
import { useHistory } from "react-router-dom";
import AddressSearch from '../Common/AddressSerach/AddressSearch';
import AwaitButton from '../../Core/UI/AwaitButton/AwaitButton';

const Signup = props => {

  const history = useHistory();

  const { setNotifiications } = useNotification();
  const { register, handleSubmit, watch, setValue, formState: { errors, isSubmitted } } = useForm();
  const [passwordShown, setPasswordShown] = useState(false);
  const [showAddressFind, setShowAddressFind] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setAuthToken } = useAuth();


  const passwordWatch = watch("txtPassword", "");

  useEffect(() => {
    setAuthToken(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAddress = (data) => {
    setValue("txtAddressKR1", data.address + (data.buildingName !== "" ? `, ${data.buildingName}` : ""));
    setValue("txtAddressKR2", "");
    setValue("txtZipcodeKR", data.zonecode);
    setShowAddressFind(false);
  }

  const onSubmit = async (data) => {

    setIsLoading(true);
    const newCustomer =
    {
      "IsChangePassword": false,
      "UserPassword": data.txtPassword,
      "UserName": data.txtName,
      "EmailAddress": data.txtEmail,
      "IsActive": true,
      "PhoneNumber": data.txtPhone ?? "",
      "MobileNumber": data.txtMobile ?? "",
      "ZipCodeKR": data.txtZipcodeKR ?? "",
      "UserAddressKR1": data.txtAddressKR1 ?? "",
      "UserAddressKR2": data.txtAddressKR2 ?? "",
      "ZipCodeNZ": data.txtZipcodeNZ ?? "",
      "UserAddressNZ1": data.txtAddressNZ1 ?? "",
      "UserAddressNZ2": data.txtAddressNZ2 ?? "",
      "PersonalCustomCode": ""
    }

    try {
      const createResponse = await AccountApi.createCustomer(newCustomer);

      if (createResponse) {
        const tokenResponse = await AccountApi.Login(data.txtEmail, data.txtPassword);

        if (tokenResponse) {
          const newToken = { ...tokenResponse, "expires_in": Date.now() / 1000 + Number(tokenResponse.expires_in) };
          await setAuthToken(newToken);

          setIsLoading(false);
          props.history.push("/");

        }
      }

    }
    catch (err) {
      setIsLoading(false);
      console.log("err=====", err);

      if (err.data?.Contents) {
        const errMessage = err.data?.Contents.map((item) => item.Message);
        setNotifiications("warning", errMessage, "입력한 정보를 확인해 주세요", 3000, null);
      }
    }

    setIsLoading(false);

  }

  return (
    <main id="content" role="main" className="bg-light">
      <div className="container content-space-3 content-space-t-lg-4 content-space-b-lg-3">
        <div className="flex-grow-1 mx-auto" style={{ maxWidth: "28rem" }}>
          <div className="text-center mb-5 mb-md-7">
            <h1 className="h2">Welcome to K-AirCargo</h1>
            <p>Fill out the form to get started.</p>
          </div>
          <form
            className={` ${isSubmitted ? "was-validated" : ""} `}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="mb-4">
              <label className="form-label" htmlFor="signupSimpleLoginEmail">이름 (영문명)
                <span className="text-danger ms-1">*</span>
              </label>
              <div className={`input-group input-group-merge ${errors.txtName ? "is-invalid" : "is-valid"} `} >
                <input
                  type="text"
                  className="form-control form-control-lg "
                  placeholder=""
                  {...register("txtName",
                    {
                      required: true,
                      pattern: /^[a-zA-Z0-9.,-_ ]*$/
                    })}
                />
              </div>
              {errors.txtName && <span className="invalid-feedback">이름(영문명)을 확인해 주세요.</span>}

            </div>
            <div className="mb-4">
              <label className="form-label" htmlFor="signupSimpleLoginEmail">이메일
                <span className="text-danger ms-1">*</span>
              </label>
              <div className={`input-group input-group-merge ${errors.txtEmail ? "is-invalid" : "is-valid"} `} >
                <input
                  type="email"
                  className="form-control form-control-lg "
                  placeholder="email@yoursite.com"
                  aria-invalid={errors.txtEmail ? "true" : "false"}
                  {...register("txtEmail",
                    {
                      required: true,
                      //pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      pattern: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    })
                  }
                />
              </div>
              {errors.txtEmail && <span className="invalid-feedback">이메일을 입력해 주세요.</span>}

            </div>

            <div className="mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <label className="form-label" htmlFor="signupSimpleLoginPassword">비밀번호
                  <span className="text-danger ms-1">*</span>
                </label>
              </div>

              <div className={`input-group input-group-merge ${errors.txtPassword ? "is-invalid" : "is-valid"}`} >
                <input
                  type={passwordShown ? "text" : "password"}
                  className="js-toggle-password form-control form-control-lg "
                  placeholder="8+ characters required"
                  autoComplete="off"
                  {...register("txtPassword", { required: true, minLength: 8 })}

                />
                <i id="changePassIcon" className={`input-link input-group-append input-group-text ${passwordShown ? "bi-eye" : "bi-eye-slash"}`}
                  onClick={e => setPasswordShown(!passwordShown)}
                ></i>

              </div>
              {errors.txtPassword && <span className="invalid-feedback">비밀번호를 확인해 주세요.</span>}
            </div>
            <div className="mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <label className="form-label" htmlFor="signupSimpleLoginPassword">비밀번호 확인
                  <span className="text-danger ms-1">*</span>
                </label>
              </div>

              <div className={`input-group input-group-merge ${errors.txtPasswordCheck ? "is-invalid" : "is-valid"}`} >
                <input
                  type={passwordShown ? "text" : "password"}
                  className="js-toggle-password form-control form-control-lg "
                  placeholder="8+ characters required"
                  autoComplete="off"
                  {...register("txtPasswordCheck", { validate: value => value === passwordWatch })}

                />
                <i id="changePassIcon" className={`input-link input-group-append input-group-text ${passwordShown ? "bi-eye" : "bi-eye-slash"}`}
                  onClick={e => setPasswordShown(!passwordShown)}
                ></i>

              </div>
              {errors.txtPasswordCheck && <span className="invalid-feedback">비밀번호를 확인해 주세요.</span>}
            </div>


            <div className="mb-4">
              <label className="form-label" htmlFor="signupSimpleLoginEmail">전화번호</label>
              <div className="input-group input-group-merge is-valid" >
                <input
                  type="text"
                  className="form-control form-control-lg "
                  placeholder=""
                  {...register("txtPhone")}
                />
              </div>
            </div>
            <div className="mb-6">
              <label className="form-label" htmlFor="signupSimpleLoginEmail">휴대폰</label>
              <div className="input-group input-group-merge is-valid" >
                <input
                  type="text"
                  className="form-control form-control-lg "
                  placeholder=""
                  {...register("txtMobile")}
                />
              </div>
            </div>
            <div className="mb-2">
              <label className="form-label" htmlFor="signupSimpleLoginEmail">주소 (한국)
                <span onClick={() => setShowAddressFind(true)} className="link-info ms-2 input-link">
                  <i className="bi bi-card-list ms-2"></i>
                  <span className="ms-1">우편번호 검색</span>
                </span>
              </label>
              <span className="d-block text-muted small">(한국으로 배송대행 신청시 기본주소로 사용할 수 있습니다.)</span>
              <div className="input-group input-group-merge is-valid" >
                <input
                  type="text"
                  className="form-control form-control-lg "
                  placeholder=""
                  {...register("txtAddressKR1")}
                />
              </div>
            </div>

            <div className="mb-6">
              <div className="row">
                <div className="col-sm-7">
                  <label className="form-label" htmlFor="signupSimpleLoginEmail">추가주소 (한국)</label>
                  <div className="input-group input-group-merge is-valid" >
                    <input
                      type="text"
                      className="form-control form-control-lg "
                      placeholder=""
                      {...register("txtAddressKR2")}
                    />
                  </div>
                </div>
                <div className="col-sm-5">
                  <label className="form-label" htmlFor="signupSimpleLoginEmail">우편번호 (한국)</label>
                  <div className="input-group input-group-merge is-valid" >
                    <input
                      type="text"
                      className="form-control form-control-lg "
                      placeholder=""
                      {...register("txtZipcodeKR")}
                    />
                  </div>
                </div>
              </div>
            </div>


            <div className="mb-2">
              <label className="form-label" htmlFor="signupSimpleLoginEmail">Address (뉴질랜드)
              </label>
              <span className="d-block text-muted small">(뉴질랜드로 배송대행 신청시 기본주소로 사용할 수 있습니다.)</span>
              <div className="input-group input-group-merge is-valid" >
                <input
                  type="text"
                  className="form-control form-control-lg "
                  placeholder=""
                  {...register("txtAddressNZ1")}
                />
              </div>
            </div>

            <div className="mb-4">
              <div className="row">
                <div className="col-sm-7">
                  <label className="form-label" htmlFor="signupSimpleLoginEmail">Address 2 (뉴질랜드)</label>
                  <div className="input-group input-group-merge is-valid" >
                    <input
                      type="text"
                      className="form-control form-control-lg "
                      placeholder=""
                      {...register("txtAddressNZ2")}
                    />
                  </div>
                </div>
                <div className="col-sm-5">
                  <label className="form-label" htmlFor="signupSimpleLoginEmail">ZipCode (뉴질랜드)</label>
                  <div className="input-group input-group-merge is-valid" >
                    <input
                      type="text"
                      className="form-control form-control-lg "
                      placeholder=""
                      {...register("txtZipcodeNZ")}
                    />
                  </div>
                </div>
              </div>
            </div>







            <div className="form-check mb-3">
              <input type="checkbox" className="form-check-input " id="signupHeroFormPrivacyCheck" name="signupFormPrivacyCheck"
                {...register("chkAgree", { required: true })}
              />
              <label className={`form-check-label small ${errors.chkAgree ? "is-invalid" : ""} `} htmlFor="signupHeroFormPrivacyCheck"> 이용약관에 동의합니다.
                <a href="/agreement" target="_blank">이용약관 확인</a></label>
              {errors.chkAgree && <span className="invalid-feedback">이용약관에 동의해 주시기 바랍니다.</span>}
            </div>



            <div className="d-grid mb-3">
              <AwaitButton type="submit" className="btn btn-primary btn-lg" loading={isLoading}>Sign up</AwaitButton>
            </div>

            <div className="text-center">
              이미 회원가입이 되어 있으신가요?
              <div className="link input-link-blue"
                onClick={() => history.push("/login")}
              >로그인 하기</div>

            </div>
          </form>
        </div>
      </div>
      <AddressSearch
        isShow={showAddressFind}
        setAddress={data => setAddress(data)}
        onClose={() => setShowAddressFind(false)}
      />
    </main>
  )
}



export default Signup
