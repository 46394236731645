import React, { useState } from 'react'
import { useForm } from "react-hook-form";


const ResetPassword = ({ resetPassword }) => {

  const { register, handleSubmit, setValue, watch, formState: { errors, isSubmitted } } = useForm();
  const passwordWatch = watch("txtPassword", "");
  const [passwordShown, setPasswordShown] = useState(false);

  const onSubmit = async (data) => {
    setValue("txtPassword", "");
    setValue("txtPasswordCheck", "");
    resetPassword(data);
  };


  return (
    <div className="flex-grow-1 mx-auto" style={{ maxWidth: "28rem" }}>
      <div className="text-center mb-5 mb-md-7">
        <h1 className="h2">Forgot password?</h1>
        <p>새로 변경할 비밀번호를 입력해 주세요</p>
      </div>

      <form
        className={` ${isSubmitted ? "was-validated" : ""} `}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mb-4">
          <div className="d-flex justify-content-between align-items-center">
            <label className="form-label" htmlFor="signupSimpleLoginPassword">비밀번호
              <span className="text-danger ms-1">*</span>
            </label>
          </div>

          <div className={`input-group input-group-merge ${errors.txtPassword ? "is-invalid" : "is-valid"}`} >
            <input
              type={passwordShown ? "text" : "password"}
              className="js-toggle-password form-control form-control-lg "
              placeholder="8+ characters required"
              autoComplete="off"
              {...register("txtPassword", { required: true, minLength: 8 })}

            />
            <i id="changePassIcon" className={`input-link input-group-append input-group-text ${passwordShown ? "bi-eye" : "bi-eye-slash"}`}
              onClick={e => setPasswordShown(!passwordShown)}
            ></i>

          </div>
          {errors.txtPassword && <span className="invalid-feedback">비밀번호를 확인해 주세요.</span>}
        </div>
        <div className="mb-4">
          <div className="d-flex justify-content-between align-items-center">
            <label className="form-label" htmlFor="signupSimpleLoginPassword">비밀번호 확인
              <span className="text-danger ms-1">*</span>
            </label>
          </div>

          <div className={`input-group input-group-merge ${errors.txtPasswordCheck ? "is-invalid" : "is-valid"}`} >
            <input
              type={passwordShown ? "text" : "password"}
              className="js-toggle-password form-control form-control-lg "
              placeholder="8+ characters required"
              autoComplete="off"
              {...register("txtPasswordCheck", { required: true, minLength: 8, validate: value => value === passwordWatch })}

            />
            <i id="changePassIcon" className={`input-link input-group-append input-group-text ${passwordShown ? "bi-eye" : "bi-eye-slash"}`}
              onClick={e => setPasswordShown(!passwordShown)}
            ></i>

          </div>
          {errors.txtPasswordCheck && <span className="invalid-feedback">비밀번호를 확인해 주세요.</span>}
        </div>

        <div className="d-grid mb-3">
          <button type="submit" className="btn btn-primary btn-lg">Submit</button>
        </div>
      </form>
    </div>
  )
}


export default ResetPassword
