import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog';

const PackageInfo = props => {
  const [isShowDialog, setIsShowDialog] = useState(false);


  const dialogFooter = (
    <div>
      <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => setIsShowDialog(false)} >Close</button>
    </div>
  );

  return (
    <>
      <main id="content" role="main" className="bg-light">
        <div className="container content-space-3 content-space-t-lg-4 content-space-b-lg-t-3">
          <div className="w-lg-50 text-center mx-lg-auto mb-5 mb-md-7">
            <h2>통관 안내</h2>
          </div>

          <h4>K택배는 국제항공운송협회(IATA), 뉴질랜드 세관, 뉴질랜드 일차산업부(MPI)등 관련 정부단체들의 법규와 규칙을 준수합니다.
          </h4>
          <p>뉴질랜드로의 수입이 금지 되어 있거나 선적이 불가능한 상품은 착불 반송처리 되오니 발송금지 상품들을 꼭 확인해 주시기 바랍니다.</p>
          <p> 별도의 확인이나 문의사항이 있는 경우 발송전 반드시 고객센터로 문의해 주시기 바랍니다.</p>
        </div>
        <div className="container ">
          <div className="w-md-75 w-lg-70 text-center mx-md-auto mb-5 mb-md-9">

            <h2>식품류나 기타 검사대상 물품 통관 안내</h2>
            <p>식품이 포함된 물품인 경우 아래와 같은 통관절차에 따라 처리 됩니다 </p>
          </div>
          <div className="row justify-content-lg-between align-items-lg-center">
            <div className="col-lg-6">
              <div className="position-relative">
                <div className="card">
                  <div className="card-body">
                    <img className="img-fluid" src="/assets/img/customsduty/process.jpg" alt="process" />
                  </div>
                </div>

              </div>
            </div>
            <div className="col-lg-5 mb-9 mb-lg-7">
              <div className="mb-4">
                <h2>택배에 식품류와 검사 대상 품목이 들어 있을 경우</h2>
                <p>식품류나 식품류가 아니더라도 캠핑용품, 골프 용품, 야외용 천막이나 야외용 가구, 실내용이라도 목재로 만들어진 가구, 영양제, 유산균제품 등도 검사 대상에 포함 됩니다. </p>
                <div className="mt-3">
                  <span onClick={() => setIsShowDialog(true)} className="link-danger ms-2 input-link">
                    <i className="bi bi-card-list ms-2"></i>
                    <span className="ms-1 link">통관 검사 대상 품목</span>
                  </span>
                </div>
              </div>

              <ul className="list-checked list-checked-soft-bg-dark list-checked-lg">
                <li className="list-checked-item">
                  통관 진행시 식품류나 기타 검사 품목이 포함 되어 있다면 서류를 통해 품목에 대한 스크리닝이 이루어집니다. 이때 스크리닝을 하는 기관은 MPI이며 비용이 발생하게 됩니다.
                </li>
                <li className="list-checked-item">
                  스크리닝만을 통해 통관이 되는 경우는 스크리닝 비용만 발생하지만 만약 MPI에서 실사를 하게 되는 경우 실 검사 비용이 추가되는데 이 비용은 검사비 + 출장비가 되어서 상당히 높게 나옵니다.
                  <br /><span className="small">(대략 $150 이상 – 검사 품목 숫자에 따라 달라집니다)</span>
                </li>
                <li className="list-checked-item">
                  실 검사에서 이상이 없는 것으로 나오면 실사 비용 인보이스가 나오고 이 비용을 납부하게 되면 통관이 완료됩니다.
                </li>
                <li className="list-checked-item">
                  실 검사에서 문제가 생길 경우 해당 품목을 폐기 또는 반송하는 절차를 거치게 되는데 그에 따른 비용이 실 검사비 + 폐기 또는 반송 비용으로  청구됩니다. 이 경우 폐기 또는 반송의 절차를 마치고 비용 납부를 완료하면 통관이 완료됩니다.
                </li>
                <li className="list-checked-item">
                  통관이 완료된 경우에만 배송이 가능합니다.
                </li>
              </ul>
            </div>
            <div>
              <i className="bi bi-exclamation-triangle me-1"></i>위 내용은 <a className="link input-link-blue" href="https://www.mpi.govt.nz/bring-send-to-nz/bringing-and-posting-items-to-nz/check-if-you-can-bring-or-send-an-item-to-nz/" target="_blank" rel="noreferrer" >뉴질랜드 일차산업부(MPI) 홈페이지</a>에서도 확인하실 수 있습니다.
            </div>
          </div>
        </div>
        <div className="container content-space-2 content-space-lg-3">
          <div className="w-md-75 w-lg-70 text-center mx-md-auto mb-5 mb-md-9">

            <h2>배송 금지 상품</h2>
            <p>아래 배송 금지 상품에 포함된 물품들은 항공 배송이 불가합니다. 아래 제품들이 아니더라도 배송가능여부가 불분명한 상품은 배송대행 신청 전 반드시 고객센터로 문의해 주시기 바랍니다.</p>
          </div>

          <div className="row justify-content-lg-center">
            <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-7">
              <div className="d-flex pe-md-5">
                <div className="flex-shrink-0">
                  <div className="svg-icon text-primary">
                    <img width="50" src="/assets/img/items/exp.png" alt="exp" />
                  </div>
                </div>
                <div className="flex-grow-1 ms-3">
                  <h4>폭발물</h4>
                  <p>탄약류, 폭죽, 화기, 장난감 총, 모형 총기류</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-7">
              <div className="d-flex ps-md-5">
                <div className="flex-shrink-0">
                  <div className="svg-icon text-primary">
                    <img width="50" src="/assets/img/items/gas.png" alt="gas" />

                  </div>
                </div>

                <div className="flex-grow-1 ms-3">
                  <h4>가스류</h4>
                  <p>가스라이터, 부탄가스, 소화기 LPG, 스프레이류</p>
                </div>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-7">
              <div className="d-flex pe-md-5">
                <div className="flex-shrink-0">
                  <div className="svg-icon text-primary">
                    <img width="50" src="/assets/img/items/solid.png" alt="solid" />
                  </div>
                </div>
                <div className="flex-grow-1 ms-3">
                  <h4>인화성 물질</h4>
                  <p>성냥, 고체연료, 숯, 페인트, 알콜, 방향제, 신나등 인화성 액체류 일체</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-7">
              <div className="d-flex ps-md-5">
                <div className="flex-shrink-0">
                  <div className="svg-icon text-primary">
                    <img width="50" src="/assets/img/items/oxi.png" alt="oxi" />

                  </div>
                </div>

                <div className="flex-grow-1 ms-3">
                  <h4>산화성 물질</h4>
                  <p>표백제, 락스류, 염색약, 파마약</p>
                </div>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-7">
              <div className="d-flex pe-md-5">
                <div className="flex-shrink-0">
                  <div className="svg-icon text-primary">
                    <img width="50" src="/assets/img/items/poison.png" alt="poison" />

                  </div>
                </div>

                <div className="flex-grow-1 ms-3">
                  <h4>독성 물질</h4>
                  <p>제초제, 살충제, 전염성물질</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-5">
              <div className="d-flex ps-md-5">
                <div className="flex-shrink-0">
                  <div className="svg-icon text-primary">
                    <img width="50" src="/assets/img/items/radio.png" alt="radio" />

                  </div>
                </div>

                <div className="flex-grow-1 ms-3">
                  <h4>방사선 물질</h4>
                  <p>방사선 검사장비등 방사능 물질 일체</p>
                </div>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-7">
              <div className="d-flex pe-md-5">
                <div className="flex-shrink-0">
                  <div className="svg-icon text-primary">
                    <img width="50" src="/assets/img/items/cor.png" alt="poison" />

                  </div>
                </div>

                <div className="flex-grow-1 ms-3">
                  <h4>부식성 물질</h4>
                  <p>수은 체온계, 수은 혈압측정계, 빙초산, 습식 배터리</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-5">
              <div className="d-flex ps-md-5">
                <div className="flex-shrink-0">
                  <div className="svg-icon text-primary">
                    <img width="50" src="/assets/img/items/misc.png" alt="radio" />

                  </div>
                </div>

                <div className="flex-grow-1 ms-3">
                  <h4>배터리 류</h4>
                  <p>리튬이온 배터리, 전자담배, 전동스쿠터등 내장배터리가 포함된 제품</p>
                </div>
              </div>
            </div>

            <div className="w-100"></div>

            <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-0">
              <div className="d-flex pe-md-5">
                <div className="flex-shrink-0">
                  <div className="svg-icon text-primary">
                    <img width="50" src="/assets/img/items/etc.png" alt="poison" />

                  </div>
                </div>

                <div className="flex-grow-1 ms-3">
                  <h4>기타 물품</h4>
                  <p>의약품, 자석, 자석이 포함된 제품, 물감, 크레파스, 색연필</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-5">

            </div>
          </div>
        </div>
      </main >
      <Dialog header="검사 대상 품목 안내" footer={dialogFooter}
        visible={isShowDialog} modal onHide={() => setIsShowDialog(false)}>
        <div className="h5">모든 식품류</div>
        <ul>
          <li>
            조리 된 것, 조리되지 않은 것, 날 음식, 포장 된 것 또는 말린 것
          </li>
          <li>
            사탕, 과자같은 종류를 포함
          </li>
        </ul>

        <div className="h5">모든 육류, 어류, 동물성 제품</div>
        <ul>
          <li>
            육류, 유제품, 생선, 꿀, 양봉관련 제품, 계란, 깃털, 조개류, 가죽, 양털, 뼈, 곤충류
          </li>
        </ul>

        <div className="h5">모든 식물, 식물성 제품</div>
        <ul>
          <li>
            과일, 꼴, 씨앗, 뿌리, 목재, 나무 껍질, 잎, 견과류, 채소, 식물의 일부, 버섯, 곰팡이
          </li>
          <li>
            대나무, 짚 (종교 의식 또는 약용 식물 포함)
          </li>
        </ul>
        <div className="h5">기타 생물 위생 안전 위험 물품</div>
        <ul>
          <li>
            한약재, 동물 식물성 의약품, 생물학적으로 배양된 제품, 미생물, 토양, 물
          </li>
          <li>
            원예, 양봉, 낚시, 수상 스포츠, 다이빙 등 활동으로 동물 식물과 접촉되었거나 수상에서 사용된 장비
          </li>
          <li>
            신발, 텐트, 캠핑, 사냥, 하이킹, 골프 등의 옥외 또는 농사 활동에 사용 된 장비
          </li>
        </ul>



      </Dialog>
    </>
  )
}


export default PackageInfo
