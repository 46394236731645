import React, { useState, useEffect } from 'react'
import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';
import Sidebar from '../Sidebar/Sidebar';
import { useSpinner } from "../../../Core/Context/SpinnerContext";
import { useNotification } from "../../../Core/Context/NotificationContext";
import * as OrderApi from '../../../Apis/OrderApi';
import Orderitem from './Items/Orderitem';
import OrderSummary from './Items/OrderSummary';
import OrderReceiver from './Items/OrderReceiver';
import { useHistory } from "react-router-dom";
import * as KFunctions from '../../../Core/Constants/KFunctions';
import { Dialog } from 'primereact/dialog';

const OrderDetail = props => {

  const orderId = props.match.params.id;
  const { setSpinner } = useSpinner();
  const { setNotifiications } = useNotification();
  const history = useHistory();
  const [orderInfo, setOrderInfo] = useState();
  const [isShowUpdateInfo, setIsShowUpdateInfo] = useState(false);


  const dialogFooter = (
    <div>
      <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => setIsShowUpdateInfo(false)} >Close</button>
    </div>
  );


  useEffect(() => {

    const getOrderInfo = async () => {
      try {
        setSpinner(true);

        const orderResponse = await OrderApi.getOrder(orderId);
        if (orderResponse) {
          setOrderInfo(orderResponse);
        }
        else {
          setNotifiications("warning", "주문정보를 찾을수 없습니다.", "", 3000, null);
        }


      }
      catch (err) {
        console.log('Error:', err);
        if (err.status === 404) {
          setNotifiications("warning", "주문정보를 찾을수 없습니다.", "", 3000, null);
        }
      }

      setSpinner(false);
    }

    getOrderInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUpdateButton = () => {

    if (orderInfo.ConsignmentNumber) {
      return;
    }
    return (<div className="btn btn-primary btn-sm btn-transition" onClick={() => history.push("/updateorder/" + orderId)} >주문 수정</div>);
  }

  const getTotalItemAmount = () => {
    return KFunctions.getCurrency(orderInfo.DeliveryLocationCode, orderInfo.TotalItemAmount);
  }

  const getLocationInfo = () => {
    if (orderInfo.DeliveryLocationCode === "1") {
      return (<span className="font-blue">대한민국 <i className="bi bi-arrow-right-short"></i> 뉴질랜드 (항공)</span>);
    }
    if (orderInfo.DeliveryLocationCode === "1") {
      return (<span className="font-blue">뉴질랜드 <i className="bi bi-arrow-right-short"></i> 대한민국</span>);
    }
    else {
      return (<span className="font-blue">대한민국 <i className="bi bi-arrow-right-short"></i> 뉴질랜드 (해상)</span>);
    }
  }

  return (
    <main id="content" role="main" className="bg-light">
      {/* <Skeleton shape="card" size="2rem" className="p-mr-2"></Skeleton> */}
      <Breadcrumb
        Current={"주문상세"}
        Parent={"My Page"}
      />

      <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          <div className="col-lg-9">
            {orderInfo &&
              <div className="d-grid gap-3 gap-lg-5">
                {/* =====card===== */}
                <div className="card">

                  <div className="card-header border-bottom card-header-custom">


                    <div className="d-flex justify-content-between align-items-center ">
                      <div>
                        <h5 className="card-header-title">주문상세</h5>
                        {getLocationInfo()}
                      </div>
                      {getUpdateButton()}
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                        {orderInfo?.IsCombineItem === "True" && <span className="badge bg-soft-dark text-dark me-1">합배송</span>}
                        {orderInfo?.IsRepackage === "True" && <span className="badge bg-soft-dark text-dark me-1">재포장</span>}
                        {orderInfo?.IsUseMileage === "True" && <span className="badge bg-soft-dark text-dark me-1">마일리지 결제</span>}
                      </div>
                      <div className="col-auto small" >
                        <button type="button" className="btn btn-link"
                          onClick={() => setIsShowUpdateInfo(true)}
                        ><i className="bi bi-exclamation-circle me-1 "></i>상품수정안내</button>
                      </div>


                    </div>
                  </div>
                  <OrderSummary item={orderInfo} />

                  <hr className="my-3" />

                  <OrderReceiver item={orderInfo} />
                </div>
                {/* =====card===== */}

                {/* =====card===== */}
                <div className="card">

                  <div className="card-body">

                    {/* =====상품정보리스트===== */}
                    <div className="col-12 mb-7 mb-lg-0">
                      <div className="d-flex justify-content-between align-items-end border-bottom pb-3 mb-2">
                        <div className="flex-grow-1 ms-3">
                          <h1 className="h5 mb-0">상품 정보</h1>
                          <span className="h4 text-primary">{getTotalItemAmount()}</span>
                        </div>
                        <span>{orderInfo.TotalItemCount} items</span>
                      </div>

                      <ul className="list-group list-group-flush list-group-no-gutters mb-5">
                        {orderInfo.OrderItemList?.map((item) =>
                          <Orderitem item={item} key={item.OrderItemId} deliveryLocationCode={orderInfo.DeliveryLocationCode} />
                        )}
                      </ul>
                    </div>
                    {/* =====상품정보리스트===== */}
                  </div>
                </div>
                {/* =====card===== */}
              </div>
            }
          </div>
        </div>
      </div>
      <Dialog header="주문 수정 안내" footer={dialogFooter}

        visible={isShowUpdateInfo} modal onHide={() => setIsShowUpdateInfo(false)}>
        <p>주문수정은 상품 입고전까지만 가능합니다. </p>
        <p>주문 삭제, 변경, 기타 문의사항은 고객센터로 문의해 주시기 바랍니다.</p>
      </Dialog>
    </main>
  );

}

export default OrderDetail;