import React, { memo } from "react";
import { useRecoilState } from "recoil";
import { createOrderState } from "../../../Store/OrderStore";
import ControlledInput from "../../../Core/UI/ReactHookForm/ControlledInput";
import { useFormContext } from "react-hook-form";
import { trim } from "lodash";

const PurchaseOrderItemSnippet = (props) => {
  const { setValue } = useFormContext();
  const { item, index } = props;
  const [orderInfo, setOrderInfo] = useRecoilState(createOrderState);

  const handleChange = (field, value) => {

    let newPurchaseOrderItems = [...orderInfo.PurchaseOrderItems];
    newPurchaseOrderItems[index] = { ...newPurchaseOrderItems[index], [field]: trim(value) };

    setOrderInfo((p) => {
      return { ...p, PurchaseOrderItems: newPurchaseOrderItems };
    });
  };

  const handleAmountChange = (field, value, min, max) => {
    //react hook form등 관련 변수들 state를 모두 맞춰야 한다...
    // if (Number(value) < min && Number(value) >= max) {
    //   return;
    // }

    let newPurchaseOrderItem = [...orderInfo.PurchaseOrderItems];
    newPurchaseOrderItem[index] = { ...newPurchaseOrderItem[index], [field]: value };

    setOrderInfo((p) => {
      return { ...p, PurchaseOrderItems: newPurchaseOrderItem };
    });
  };

 

  const removeItem = () => {
    if (item.PurchaseOrderItemId) {
      const newDeletedItems = [
        ...orderInfo.DeletedPurchaseOrderitems,
        item.PurchaseOrderItemId,
      ];
      setOrderInfo((p) => {
        return { ...p, DeletedPurchaseOrderitems: newDeletedItems };
      });
    }

    const newPurchaseOrderItems = [...orderInfo.PurchaseOrderItems];

    newPurchaseOrderItems.splice(index, 1);

    setOrderInfo((p) => {
      return { ...p, PurchaseOrderItems: newPurchaseOrderItems };
    });
  };

 

  return (
    <div className="card card-sm shadow-sm mt-4">
      <div className="card-body">
        {
          index > 0 && (item.IsOrdered !== "1" || item.IsOrdered !== "2") && (
            <button
              type="button"
              className="btn btn-ghost-danger mt-1 btn-sm"
              onClick={() => removeItem()}
            >
              <i className="bi-dash-circle me-1"></i> 상품삭제
            </button>
          )

        }
        {(item.IsOrdered === "1" || item.IsOrdered === "2") && (
          <div className="js-create-field form-link mb-4 input-link-red text-info">
            <i className="bi bi-exclamation-circle"></i> 주문완료
            <span className="form-label-secondary ms-2 text-info">
              (주문 완료된 상품은 변경이 불가능 합니다.)
            </span>
          </div>
        )}
        <div className="row g-3">
          
          <div className="col-md-12">
            <div className="d-flex justify-content-between">
              <label
                htmlFor={`txtPurchaseOrderItemName${index}`}
                className="form-label"
              >
                상품명 <span className="text-danger ms-1">*</span>
              </label>
              <span className="small">
                <i className="bi bi-exclamation-circle me-1 text-danger"></i>
                주문 옵션 (사이즈, 색상, 옵션 등)은 상품 Comment에 남겨주세요.
              </span>
            </div>
            <ControlledInput
              name={`txtPurchaseOrderItemName${index}`}
              defaultValue={item.ItemName}
              handleChange={(e) => handleChange("ItemName", e)}
              disabled={(item.IsOrdered === "1" || item.IsOrdered === "2") ? true : false}
              placeholder="모든 상품명을 기입해 주세요"
            />
          </div>
          <div className="col-md-12">
            <div className="d-flex justify-content-between">
              <label
                htmlFor={`txtPurchaseOrderItemUrl${index}`}
                className="form-label"
              >
                상품URL <span className="text-danger ms-1">*</span>
              </label>
            </div>
            <ControlledInput
              name={`txtPurchaseOrderItemUrl${index}`}
              defaultValue={item.ItemUrl}
              handleChange={(e) => handleChange("ItemUrl", e)}
              disabled={(item.IsOrdered === "1" || item.IsOrdered === "2") ? true : false}
              placeholder="정확한 URL을 입력해주세요"
            />
          </div>

          <div className="col-md-6">
            <label
              htmlFor={`txtPurchaseOrderItemAmount${index}`}
              className="form-label"
            >
              상품가격
              <span className="text-danger ms-1">*</span> (
              {orderInfo?.AmountUnit})
            </label>
            <ControlledInput
              name={`txtPurchaseOrderItemAmount${index}`}
              defaultValue={item.ItemAmount}
              type="number"
              placeholder={orderInfo?.AmountUnit}
              handleChange={(e) =>
                handleAmountChange("ItemAmount", e, 0, 100000000)
              }
              disabled={(item.IsOrdered === "1" || item.IsOrdered === "2") ? true : false}
            />
          </div>

          <div className="col-md-6">
            <label htmlFor={`txtPurchaseItemQuantity${index}`} className="form-label">
              상품수량{" "}
            </label>
            <ControlledInput
              name={`txtPurchaseItemQuantity${index}`}
              defaultValue={item.ItemQuantity}
              type="number"
              handleChange={(e) => handleChange("ItemQuantity", e)}
              rules={{
                required: true
                , validate: {
                  positiveNumber: (value) => parseFloat(value) > 0
                }
              }}
              disabled={(item.IsOrdered === "1" || item.IsOrdered === "2") ? true : false}
            />
          </div>

          <div className="col-md-12">
            <div className="d-flex justify-content-between">
              <label
                htmlFor={`txtPurchaseOrderOrderNote${index}`}
                className="form-label"
              >
                상품 Comment <span className="text-danger ms-1">*</span>
              </label>
            </div>
            <ControlledInput
              name={`txtPurchaseOrderOrderNote${index}`}
              defaultValue={item.OrderNote}
              handleChange={(e) => handleChange("OrderNote", e)}
              disabled={(item.IsOrdered === "1" || item.IsOrdered === "2") ? true : false}
              placeholder="정확한 옵션을 입력해주세요"
            />
          </div>
         

        </div>
      </div>
    </div>
  );
};

export default memo(PurchaseOrderItemSnippet);
