import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useHistory } from "react-router-dom";
import * as moment from "moment";
import { useNotification } from "../../../Core/Context/NotificationContext";
import { useAuth } from "../../../Core/Context/AuthContext";

const BoardQnaListItem = props => {

  const { pageSizeChanged, pageNoChanged } = props;
  const items = props.items.Data;
  const paging = props.items.Paging;

  const { setNotifiications } = useNotification();
  const { AuthToken } = useAuth();
  const history = useHistory();


  const onPage = (event) => {

    const { page, rows } = event;
    if (Number(paging.PageSize) !== rows) {
      pageSizeChanged(rows);
    }
    else if (Number(paging.PageSize) !== page + 1) {
      pageNoChanged(page + 1);
    }
  };

  const setResponsiveContents = (title, value) => {
    return (
      <React.Fragment>
        <span className="p-column-title">{title}</span>
        {value}
      </React.Fragment>
    );
  }

  const setTimeStamp = (time) => {

    return moment(time, 'DD/MM/YYYY HH:mm:ss').format("DD/MM/YYYY");
  };

  const setTitleContents = (item) => {
    return (
      <div className="input-link-dark" onClick={() => goBoardDetail(item)}>
        {item.Title}
        {item.IsSecret === "True" && <i className="bi bi-lock-fill ms-1"></i>}
      </div>
    );
  }

  const setReplyStatus = (item) => {
    return Number(item.CommentCount) > 0 ? "답변완료" : "답변대기중";
  }

  const goBoardDetail = (item) => {
    if (item.IsSecret === "True" && AuthToken?.user_id !== item.UserId) {
      setNotifiications("warning", "비밀글은 작성자만 열람할수 있습니다.", "", 3000, null);
    }
    else {
      history.push('/qna/' + item.BoardQnaId);
    }
  }

  return (
    <div className="datatable-responsive-k p-datatable-sm">
      <DataTable
        value={items}
        className="p-datatable-responsive-k"
        paginator
        paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        rows={Number(paging.PageSize)}
        totalRecords={Number(paging.TotalNumberOfRecords)}
        lazy onPage={onPage} first={Number(paging.PageSize) * (Number(paging.PageNumber) - 1)}
      >
        <Column className="p-datatable-title" field="Title" header="제목" body={r => setResponsiveContents('제목', setTitleContents(r))}  ></Column>
        <Column field="WriterName" header="작성자" body={r => setResponsiveContents('작성자', r.WriterName)}  ></Column>
        <Column field="CreateDate" header="작성일" body={r => setResponsiveContents('작성일', setTimeStamp(r.CreateDate))}  ></Column>
        <Column field="CreateDate" header="답변여부" body={r => setResponsiveContents('답변여부', setReplyStatus(r))}  ></Column>
      </DataTable>
    </div>
  )
}


export default BoardQnaListItem
