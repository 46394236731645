import React, { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil';
import { useLocation } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { useForm, FormProvider } from "react-hook-form";
import { trim } from "lodash";

import * as GlobalConstant from '../../../Core/Constants/GlobalConstant';
import { createOrderState } from '../../../Store/OrderStore';
import ControlledInput from '../../../Core/UI/ReactHookForm/ControlledInput';
import AddressSearch from '../../Common/AddressSerach/AddressSearch';
import OrderSummary from './OrderSummary';
import PurchaseOrderSummary from './PurchaseOrderSummary';

const OrderReceiver = ({ init }) => {

  const location = useLocation();
  const reactHookFormMethod = useForm();
  const { handleSubmit, setValue, formState: { isSubmitted } } = reactHookFormMethod;
  const [orderInfo, setOrderInfo] = useRecoilState(createOrderState);
  const [showAddressFind, setShowAddressFind] = useState(false);

  const [finishStatus, setfinishStatus] = useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      window.history.pushState(null, null, window.location.pathname);
      console.log('back');
      setfinishStatus(false)
      setBack();
    }
  }

  useEffect(() => {
    console.log('location', location.pathname);
    if (location.pathname.indexOf("createorder") >= 0) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', onBackButtonEvent);
    }

    return () => {
      if (location.pathname.indexOf("createorder") >= 0) {
        window.removeEventListener('popstate', onBackButtonEvent);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setReceiver = (data) => {
    //history.push('/createorder/orderitem');
    window.scroll({ top: 0, behavior: 'smooth' })
    setOrderInfo(p => {
      return { ...p, CurrentStep: 3 }
    });

  }
  const setAddress = (data) => {

    setOrderInfo(p => {
      return {
        ...p,
        ReceiverAddress1: data.address + (data.buildingName !== "" ? `, ${data.buildingName}` : ""),
        ReceiverAddress2: "",
        ReceiverZipcode: data.zonecode
      }
    });

    setValue("txtAddress1", data.address + (data.buildingName !== "" ? `, ${data.buildingName}` : ""));
    setValue("txtAddress2", "");
    setValue("txtZipcode", data.zonecode);

    setShowAddressFind(false);

  }

  const setBack = () => {

    window.scroll({ top: 0, behavior: 'smooth' })
    setOrderInfo(p => {
      return { ...p, CurrentStep: orderInfo.DeliveryLocationCode === "2" ? 1 : 1.5 }
    });
  }

  const handleChange = (field, value) => {

    setOrderInfo(p => {
      return { ...p, [field]: trim(value) }
    });
  }

  const onIsReceiverValueChange = (e) => {
    if (e.target.value === "true") {

      const receiverAddress1 = orderInfo.DeliveryLocationCode === "1" ? orderInfo.UserInfo.UserAddressNZ1 : orderInfo.UserInfo.UserAddressKR1;
      const receiverAddress2 = orderInfo.DeliveryLocationCode === "1" ? orderInfo.UserInfo.UserAddressNZ2 : orderInfo.UserInfo.UserAddressKR2;
      const receiverZipcode = orderInfo.DeliveryLocationCode === "1" ? orderInfo.UserInfo.ZipCodeNZ : orderInfo.UserInfo.ZipCodeKR;

      setOrderInfo(p => {
        return {
          ...p,
          IsReceiverOwn: true,
          ReceiverName: orderInfo.UserInfo.UserName,
          ReceiverEmail: orderInfo.UserInfo.EmailAddress,
          ReceiverPhone: orderInfo.UserInfo.PhoneNumber,
          ReceiverMobile: orderInfo.UserInfo.MobileNumber,
          ReceiverAddress1: receiverAddress1,
          ReceiverAddress2: receiverAddress2,
          ReceiverZipcode: receiverZipcode,
          PersonalCustomCode: orderInfo.UserInfo.PersonalCustomCode
        }
      });

      setValue("txtName", orderInfo.UserInfo.UserName);
      setValue("txtEmail", orderInfo.UserInfo.EmailAddress);
      setValue("txtPhone", orderInfo.UserInfo.PhoneNumber);
      setValue("txtMobile", orderInfo.UserInfo.MobileNumber);
      setValue("txtAddress1", receiverAddress1);
      setValue("txtAddress2", receiverAddress2);
      setValue("txtZipcode", receiverZipcode);
      setValue("txtPersonalCustomCode", orderInfo.UserInfo.PersonalCustomCode);
    }
    else {
      setOrderInfo(p => {
        return {
          ...p,
          IsReceiverOwn: false,
          ReceiverName: "",
          ReceiverEmail: "",
          ReceiverPhone: "",
          ReceiverMobile: "",
          ReceiverAddress1: "",
          ReceiverAddress2: "",
          ReceiverZipcode: "",
          PersonalCustomCode: ""
        }
      });

      setValue("txtName", "");
      setValue("txtEmail", "");
      setValue("txtPhone", "");
      setValue("txtMobile", "");
      setValue("txtAddress1", "");
      setValue("txtAddress2", "");
      setValue("txtZipcode", "");
      setValue("txtPersonalCustomCode", "");
    }
  }

  return (
    <>

      <div className="row">
        <div className="col-lg-8 mb-7 mb-lg-0">
          <FormProvider {...reactHookFormMethod} >
            <form className={` ${isSubmitted ? "was-validated" : ""} `} onSubmit={handleSubmit(setReceiver)}>
              <h4 className="mb-3">수령인 정보 입력</h4>
              <hr className="my-4" />
              <div className="my-3">
                <div className="form-check form-check-inline">
                  <input id="creditShopCheckout" name="IsReceiverOwn" type="radio" className="form-check-input"
                    value="true"
                    checked={orderInfo?.IsReceiverOwn}
                    onChange={onIsReceiverValueChange}
                  />
                  <label className="form-check-label" htmlFor="creditShopCheckout">본인 수령</label>
                </div>

                <div className="form-check form-check-inline">
                  <input id="debitShopCheckout" name="IsReceiverOwn" type="radio" className="form-check-input"
                    value="false"
                    checked={orderInfo?.IsReceiverOwn === false}
                    onChange={onIsReceiverValueChange}
                  />
                  <label className="form-check-label" htmlFor="debitShopCheckout">직접 입력</label>
                </div>
              </div>

              <div className="row g-3">

                <div className="col-md-6">
                  <label htmlFor="txtName" className="form-label">이름 <span className="text-danger ms-1">*</span>
                    <span className="form-label-secondary ms-2">(영문으로 입력해 주세요)</span> </label>
                  <ControlledInput
                    name="txtName"
                    defaultValue={orderInfo.ReceiverName}
                    handleChange={e => handleChange("ReceiverName", e)}
                    placeholder="영문으로 입력해 주세요"
                    rules={{
                      required: true
                      , pattern: /^[a-zA-Z0-9.,-_ ]*$/
                    }}
                  />

                </div>

                <div className="col-md-6">
                  <label htmlFor="txtEmail" className="form-label">Email <span className="text-danger ms-1">*</span></label>
                  <ControlledInput
                    name="txtEmail"
                    defaultValue={orderInfo.ReceiverEmail}

                    rules={{
                      required: true
                      , pattern: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    }}
                    handleChange={e => handleChange("ReceiverEmail", e)}
                  />

                </div>

                <div className="col-md-6">
                  <label htmlFor="txtMobile" className="form-label">휴대폰 <span className="text-danger ms-1">*</span></label>
                  <ControlledInput
                    name="txtMobile"
                    defaultValue={orderInfo.ReceiverMobile}
                    handleChange={e => handleChange("ReceiverMobile", e)}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="txtPhone" className="form-label">전화번호 </label>
                  <ControlledInput
                    name="txtPhone"
                    defaultValue={orderInfo.ReceiverPhone}
                    rules={{ required: false }}
                    handleChange={e => handleChange("ReceiverPhone", e)}
                  />
                </div>

                <div className="col-12">
                  <label htmlFor="txtAddress1" className="form-label">주소 <span className="text-danger ms-1">*</span>
                    {orderInfo?.DeliveryLocationCode === "2"
                      && <span onClick={() => setShowAddressFind(true)} className="link-info ms-2 input-link">
                        <i className="bi bi-card-list ms-2"></i>
                        <span className="ms-1">우편번호 검색</span>
                      </span>}
                  </label>
                  <ControlledInput
                    name="txtAddress1"
                    defaultValue={orderInfo.ReceiverAddress1}
                    handleChange={e => handleChange("ReceiverAddress1", e)}
                  />
                </div>



                <div className="col-md-8">
                  <label htmlFor="txtAddress2" className="form-label">추가주소</label>
                  <ControlledInput
                    name="txtAddress2"
                    defaultValue={orderInfo.ReceiverAddress2}
                    rules={{ required: false }}
                    handleChange={e => handleChange("ReceiverAddress2", e)}
                  />

                </div>
                <div className="col-md-4">
                  <label htmlFor="txtZipcode" className="form-label">우편번호 <span className="text-danger ms-1">*</span></label>
                  <ControlledInput
                    name="txtZipcode"
                    defaultValue={orderInfo.ReceiverZipcode}
                    handleChange={e => handleChange("ReceiverZipcode", e)}
                  />
                </div>

                {orderInfo?.DeliveryLocationCode === "2" &&
                  <div className="col-12">
                    <div className="d-flex justify-content-between">
                      <label htmlFor="txtMobile" className="form-label">
                        개인통관부호 <span className="text-danger ">*</span>
                        (식품 또는 건강식품만 보내실 경우 주민번호 입력해 주세요)
                      </label>
                      <span className="small">
                        <Link to="/guide#kor" target="_blank">
                          <i className="bi bi-exclamation-circle me-1 text-danger"></i>자세히 알아보기
                        </Link>
                      </span>
                    </div>
                    <ControlledInput
                      name="txtPersonalCustomCode"
                      defaultValue={orderInfo.PersonalCustomCode}
                      handleChange={e => handleChange("PersonalCustomCode", e)}
                    />
                  </div>
                }
                <div className="col-12">
                  <label htmlFor="txtReceiverMessage" className="form-label">배송요청사항</label>
                  <textarea id="txtReceiverMessage" className="form-control" rows="2"
                    onChange={e => handleChange("ReceiverMessage", e.target.value)}
                    defaultValue={orderInfo.ReceiverMessage}
                  >

                  </textarea>


                </div>
                <div className="col-12">
                  <label htmlFor="addressShopCheckout" className="form-label">수령방법 <span className="text-danger ms-1">*</span></label>
                  <div className="d-grid gap-3">
                    {!(orderInfo?.DeliveryLocationCode === "2") &&
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="PickupTypeCode" id="deliveryRadio1"
                          value={1}
                          checked={orderInfo?.PickupTypeCode === "1"}
                          onChange={e => handleChange("PickupTypeCode", e.target.value)}
                        />
                        <label className="form-check-label text-dark" htmlFor="deliveryRadio1">
                          {GlobalConstant.pickupType[1]} - Free
                          <span className="d-block text-muted small">
                            {!(orderInfo?.DeliveryLocationCode === "2") ? "오클랜드 " : "서울 "}
                            사무실 방문 수령
                          </span>
                        </label>
                      </div>
                    }
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="PickupTypeCode" id="deliveryRadio2"
                        value={2}
                        checked={orderInfo?.PickupTypeCode === "2"}
                        onChange={e => handleChange("PickupTypeCode", e.target.value)}
                      />
                      <label className="form-check-label text-dark" htmlFor="deliveryRadio2">
                        {GlobalConstant.pickupType[2]}
                        <span className="d-block text-muted small">
                          {!(orderInfo?.DeliveryLocationCode === "2") ? "기본 $6부터" : "기본배송비 무료(도서/산간지방 추가비용 발생)"}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-4">

                </div>
              </div>
              <hr className="my-4" />
              <div className="row align-items-center">
                <div className="col-sm-6 order-sm-1 mb-3 mb-sm-0">
                  <div className="d-grid">
                    <button type="submit" className="btn btn-primary btn-lg"
                    >상품정보 입력</button>
                  </div>
                </div>

                <div className="col-sm text-center text-sm-start">
                  {!init &&
                    <button type="button" className="btn btn-ghost-secondary" onClick={() => setBack()}>
                      <i className="bi-chevron-left small ms-1"></i> 이전으로
                    </button>
                  }
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
        {orderInfo?.IsPurchaseOrder?<PurchaseOrderSummary/>:<OrderSummary />}

      </div>
      <AddressSearch
        isShow={showAddressFind}
        setAddress={data => setAddress(data)}
        onClose={() => setShowAddressFind(false)}
      />

    </>
  )
}

export default OrderReceiver
