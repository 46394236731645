import React, { useMemo } from "react";
import { useRecoilState } from "recoil";
import { createOrderState } from "../../../Store/OrderStore";
import OrderOptions from "./Components/OrderOptions";
const OrderSummary = (props) => {
  const [orderInfo, setOrderInfo] = useRecoilState(createOrderState);

  const handleChange = (field, value) => {
    setOrderInfo((p) => {
      return { ...p, [field]: value };
    });
  };

  const currencyOpts = useMemo(() => {
    return {
      minimumFractionDigits: orderInfo.DeliveryLocationCode === "2" ? 2 : 0,
    };
  }, [orderInfo.DeliveryLocationCode]);

  const getTotalItemAmount = useMemo(() => {
    let sum = orderInfo?.OrderItems.reduce(function (prev, current) {
      let currentVal = 0;

      if (current.ItemName === "" || current.ItemTypeCode === "0" || current.ItemTypeCode === "" || current.ItemAmount === "") { }
      else {
        currentVal = + (current.ItemAmount * current.ItemQuantity);

      }
      return prev + currentVal;
    }, 0);

    return sum.toLocaleString("en-US", currencyOpts);
  }, [orderInfo, currencyOpts]);

  const getOrderItemSummary = useMemo(() => {
    return orderInfo?.OrderItems.map((item, index) => {
      if (
        item.ItemName === "" ||
        item.ItemTypeCode === "" ||
        item.ItemTypeCode === "0" ||
        item.ItemAmount === ""
      ) {
        return "";
      }

      return (
        <div className="border-bottom pb-4 mb-4" key={index}>
          <div className="d-flex">
            <div className="flex-shrink-0">
              <div className="avatar-custom me-3">
                {/* <img className="avatar-img" src="../assets/img/320x320/img2.jpg" alt="Image Description" /> */}
                <sup className="avatar-status avatar-status-primary">
                  {index + 1}
                </sup>
              </div>
            </div>

            <div className="flex-grow-1">
              <h6>{item.ItemTypeName}</h6>
              <div className="d-grid">
                <div className="text-body row ">
                  <span className="small col">상품가격: </span>
                  <span className="small col ms-10">
                    {orderInfo?.AmountUnit}
                    {Number(item.ItemAmount).toLocaleString(
                      "en-US",
                      currencyOpts
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }, [orderInfo, currencyOpts]);

  return (
    <div className="col-lg-4">
      <div className="ps-lg-4">
        <div className="card card-sm shadow-sm mb-4">
          <div className="card-body">
            <div className="border-bottom pb-4 mb-4">
              <h3 className="card-header-title">Order summary</h3>
            </div>
            <div className="border-bottom pb-4 mb-3">
              <div className=" ">
                <div
                  className="card card-stretched-vertical shadow-none bg-img-start gradient-y-overlay-lg-dark bg-img-end"
                  style={{
                    backgroundImage: orderInfo?.DeliveryLocationCode === "3" ? 'url(/assets/img/location/img01.jpg)': `url(/assets/img/location/img0${orderInfo?.DeliveryLocationCode}.jpg)`,
                    minHeight: "8rem",
                  }}
                >
                  <div className="card-body card-body-custom">
                    <div className="card-footer">
                      <h3 className="text-white mb-0">
                        {orderInfo?.DeliveryLocationCode === "2"
                          ? "대한민국으"
                          : "뉴질랜드"}
                        로 배송 {orderInfo?.DeliveryLocationCode != "2" && (orderInfo?.DeliveryLocationCode === "1"
                          ? "(항공)"
                          : "(해상)")}
                      </h3>
                      {/* <span className="d-block text-white">필요시 정보..</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {getOrderItemSummary}

            <div className="border-bottom pb-4 mb-4">
              <div className="d-grid gap-3">
                <dl className="row">
                  <dt className="col-sm-6">Delivery</dt>
                  <dd className="col-sm-6 text-sm-end mb-0">
                    {orderInfo?.PickupTypeCode === "1"
                      ? "직접수령"
                      : "택배발송"}
                  </dd>
                </dl>
              </div>
            </div>
            <div className="border-bottom pb-4 mb-4">
              <dl className="row">
                <dt className="col-sm-6">상품가격</dt>
                <dd className="col-sm-6 text-sm-end mb-0">
                  {orderInfo?.AmountUnit} {getTotalItemAmount}
                </dd>
              </dl>
            </div>
            {!(orderInfo?.DeliveryLocationCode === "2") && (
              <OrderOptions orderInfo={orderInfo} handleChange={handleChange} />
            )}
            {/* <div className="d-grid">
              <button type="button" className="btn btn-soft-primary btn-lg mb-3">
                임시저장
              </button>
            </div> */}
            {orderInfo?.CurrentStep === 3 && (
              <div className="d-grid">
                <button type="submit" className="btn btn-primary btn-lg">
                  배송대행 신청
                </button>
              </div>
            )}
          </div>
        </div>

        <a
          href="https://pf.kakao.com/_xmIExbb"
          target="_blank"
          rel="noreferrer"
        >
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <div className="svg-icon svg-icon-sm text-primary">
                <img
                  src="/assets/img/sns/ico_kakao.png"
                  alt="kakao"
                  style={{ width: "50px" }}
                />
              </div>
            </div>
            <div className="flex-grow-1 ms-2">
              <span className="small me-1">카카오톡 상담하기</span>
              <div className="link small">ID: K택배</div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default OrderSummary;
